import React, { createContext, useContext, useState } from "react"

import { UserInInstanceRoleCode, UserInInstanceRoleName } from "@/protocols/userInInstance"
import { IChannel } from "@/protocols/channel"
import { SetState } from "@/protocols/react"
import { InstanceData, MerlinData, SubscriptionData } from "@/protocols/instance"
import { Team } from "@/protocols/team"

import { UserDataProps } from "@/services/User"

export type InstanceDataProps = {
	instance_id: number
	nickname: InstanceData["nickname"]
	responsible_phone_number?: InstanceData["responsible_phone_number"]
	instance_created_at: Date
	teams: Team[]
	user_in_instance_id: number
	user_in_instance_role: {
		code:	UserInInstanceRoleCode
		name: UserInInstanceRoleName
	}
	subscriptionData: SubscriptionData
	merlinData: MerlinData
	current_channel_type?: InstanceData["current_channel_type"]
}

type ChannelDataProps = IChannel
export interface GlobalStateContextData {
	user: UserDataProps
	setUserData: (data: Partial<UserDataProps>) => void

	instance: InstanceDataProps
	setInstanceData: (data: Partial<InstanceDataProps>) => void

	channels: ChannelDataProps[]
	setChannelsData: (setState: SetState<ChannelDataProps[]>) => void
	isChannelConnected: (channel?: ChannelDataProps) => boolean

	currentChannel?: ChannelDataProps
	whatsappChannel?: ChannelDataProps
	wabaChannel?: ChannelDataProps
	isCurrentChannelConnected: boolean
	isBaileysWhatsappChannel: boolean
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [user, setUser] = useState<UserDataProps>({} as UserDataProps)
	const [instance, setInstance] = useState<InstanceDataProps>({} as InstanceDataProps)
	const [channels, setChannels] = useState<ChannelDataProps[]>([])

	const setUserData = (
		data: Partial<UserDataProps>
	) => {
		setUser(lastState => ({
			...lastState,
			...data
		}))
	}

	const setInstanceData = (
		data: Partial<InstanceDataProps>
	) => {
		setInstance(lastState => ({
			...lastState,
			...data
		}))
	}

	const setChannelsData = (setState: SetState<ChannelDataProps[]>) => {
		setChannels(setState)
	}

	const isChannelConnected = (channel?: ChannelDataProps) => {
		const isChannelConnected = Boolean(channel?.status === "connected")

		return isChannelConnected
	}

	const isBaileysChannel = (channel?: ChannelDataProps) => {
		const isBaileysWhatsappChannel = channel?.extraData?.libraryClientType === "baileys"

		return isBaileysWhatsappChannel
	}

	const currentChannel = channels.find(channel => channel.channelType === instance.current_channel_type)
	const whatsappChannel = channels.find(channel => channel.channelType === "whatsapp")
	const wabaChannel = channels.find(channel => channel.channelType === "waba")

	const isCurrentChannelConnected = isChannelConnected(currentChannel)
	const isBaileysWhatsappChannel = isBaileysChannel(currentChannel)

	GlobalStateStore.displayName = "GlobalStateStore"

	return (
		<GlobalStateStore.Provider
			value={{
				user,
				setUserData,
				instance,
				setInstanceData,
				channels,
				isChannelConnected,
				isBaileysWhatsappChannel,
				currentChannel,
				isCurrentChannelConnected,
				setChannelsData,
				whatsappChannel,
				wabaChannel
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
