import React, { useImperativeHandle, forwardRef, useRef, useState, useEffect } from "react"
import { Input, InputBaseComponentProps, InputProps } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"

import MediaService, { Media } from "@/services/Media"

import useChatStyles from "@/pages/Attendance/Chat/styles"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput/styles"
import useCustomStyles from "@/styles/custom"
import { getChatMessageSketch } from "@/utils/chat"
import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import clsx from "clsx"

export type TextInputHandler = {
	focus: () => void
	getCurrentValue: () => string
	setCurrentValue: (value: string) => void
	clear: () => void
	addText: (text: string) => void
	replaceText: (text: string) => void
	addTextByCursor: (text: string) => void
	moveCursorToEnd: () => void
}

type TextInputProps = {
	onSubmit: () => void
	onMedia: (medias: Media[]) => void
	minRows?: number
	inputProps?: InputBaseComponentProps
	onChange?: (text: string) => void
	disabled?: boolean
	chatId?: number
	errorProps?: {
		error?: boolean
	}
	getInputProps?: (textInputHandler: Pick<TextInputHandler, "getCurrentValue" | "replaceText">) => InputProps
	isOutlined?: boolean
}

const TextInput: React.ForwardRefRenderFunction<
TextInputHandler,
TextInputProps
> = (props, ref) => {
	const { onSubmit, onMedia, onChange, disabled, chatId, getInputProps, minRows, inputProps, errorProps, isOutlined = false } = props

	const [value, setValue] = useState("")

	const chatClasses = useChatStyles()
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const inputRef = useRef<HTMLTextAreaElement>()
	const inputCursorRef = useRef({ end: 0, start: 0 })
	const chatGlobalStateStore = useChatGlobalStateStore()

	const onPaste = (event: React.ClipboardEvent) => {
		const file = event.clipboardData.files[0]

		if (!file) {
			return
		}

		const media = MediaService.adaptFile(file)

		onMedia([media])
	}

	const handleSetCurrentValue = (value: string) => {
		setValue(value)
	}

	const handleChange = (value: string) => {
		setValue(value)

		onChange?.(value)
	}

	const handleClear = () => {
		setValue("")
	}

	const handleFocus = () => {
		inputRef.current?.focus()
	}

	const handleGetCurrentValue = (): string => {
		return value
	}

	const handleAddText = (text: string) => {
		setValue((lastState) => {
			const updatedState = lastState + text

			onChange?.(updatedState)

			return updatedState
		})
	}

	const handleReplaceText = (text: string) => {
		setValue(text)
	}

	const onKeyDown = (event: React.KeyboardEvent) => {
		const { key, keyCode, shiftKey } = event

		const isEnterKey = key === "Enter" || keyCode === 13

		if (isEnterKey && !shiftKey && !isSmallScreen) {
			event.preventDefault()

			onSubmit()
		}
	}

	const handleAddTextByCursor = (newText: string) => {
		setValue(lastState => {
			const beforeNewText = lastState?.substring(0, inputCursorRef.current.start)
			const afterNewText = lastState?.substring(inputCursorRef.current.end)

			const newValue = beforeNewText + newText + afterNewText

			const newCursorSize = (beforeNewText + newText).length

			inputCursorRef.current = {
				start: newCursorSize,
				end: newCursorSize
			}

			onChange?.(newValue)

			return newValue
		})
	}

	const refreshInputCursor = (target: HTMLTextAreaElement) => {
		const input = target

		if (input) {
			inputCursorRef.current = {
				end: input.selectionEnd,
				start: input.selectionStart
			}
		}
	}

	const handleMoveCursorToEnd = () => {
		if (inputRef.current) {
			inputRef.current.selectionStart = value.length
			inputRef.current.selectionEnd = value.length
		}
	}

	useImperativeHandle(ref, () => ({
		focus: handleFocus,
		getCurrentValue: handleGetCurrentValue,
		setCurrentValue: handleSetCurrentValue,
		clear: handleClear,
		addText: handleAddText,
		replaceText: handleReplaceText,
		addTextByCursor: handleAddTextByCursor,
		moveCursorToEnd: handleMoveCursorToEnd
	}))

	useEffect(() => {
		if (chatId) {
			chatGlobalStateStore.conversationPanel.textInput.clear()

			const text = getChatMessageSketch(chatId)
			if (text) {
				setValue(text)
			}
		}
	}, [chatId])

	return (
		<Input
			disabled={disabled}
			fullWidth
			multiline
			minRows={minRows || 1}
			inputProps={inputProps}
			className={clsx({
				[chatClasses.input]: true,
				[classes.outline]: isOutlined
			})}
			onChange={({ target }) => {
				handleChange(target.value)
				refreshInputCursor(target as HTMLTextAreaElement)
			}}
			onClick={({ target }) => {
				refreshInputCursor(target as HTMLTextAreaElement)
			}}
			value={value}
			onKeyDown={onKeyDown}
			inputRef={inputRef}
			onPaste={onPaste}
			classes={{
				inputMultiline: `${classes.input} ${customClasses.scrollBar}`,
				multiline: classes.multiline,
				disabled: classes.inputDisabled
			}}
			{...getInputProps?.({ getCurrentValue: handleGetCurrentValue, replaceText: handleReplaceText })}
			{...errorProps}
		/>
	)
}

export default forwardRef(TextInput)
