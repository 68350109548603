import { ChatBotFlowError } from "@/protocols/chatBotFlow"

type FlowValidationContent = {
	errorMessage: string
}

type FlowValidationConfig = {
	[key in ChatBotFlowError]: FlowValidationContent
}

export const flowValidationConfig: FlowValidationConfig = {
	finish_attendance_block_not_find: {
		errorMessage: "Seu bot necessita do bloco de \"Finalizar atendimento\" para funcionar"
	},
	block_without_mandatory_connection: {
		errorMessage: "Conexões pendentes no bloco"
	},
	finish_flow_block_not_find: {
		errorMessage: "Seu bot necessita do bloco de \"Finalizar bot\" para funcionar"
	},
	block_missing_content: {
		errorMessage: "Bloco sem conteúdo"
	},
	multiple_condition_block_without_answer: {
		errorMessage: "Bloco sem resposta"
	},
	transfer_block_missing_selection: {
		errorMessage: "Bloco sem nenhuma transferência selecionada"
	},
	attendace_schedule_missing_time: {
		errorMessage: "Horário de atendimento não selecionado"
	},
	save_answer_block_missing_location: {
		errorMessage: "Local para salvar não selecionado"
	},
	manage_client_tags_block_missing_tag_selection: {
		errorMessage: "Tag não selecionada"
	},
	go_to_block_missing_bot: {
		errorMessage: "Bloco sem nenhum bot selecionado"
	},
	random_path_flow_missing_path: {
		errorMessage: "Bloco sem caminho selecionado"
	},
	optout_optin_missing_selection: {
		errorMessage: "Bloco sem opção selecionada"
	},
	webhook_block_missing_url: {
		errorMessage: "Bloco sem link inserido"
	},
	condition_block_missing_option: {
		errorMessage: "Bloco sem opção selecionada"
	},
	active_campaign_missing_tag: {
		errorMessage: "Bloco sem tag inserida"
	},
	active_campaign_block_missing_deal: {
		errorMessage: "Bloco sem funil de vendas e etapa adicionada"
	},
	active_campaign_not_connected: {
		errorMessage: "Active Campaign não está conectado"
	}
}
