import React, { ComponentType, useMemo } from "react"
import { NodeProps, useReactFlow } from "reactflow"

import { InboxOutlined as NoAccountManagerIcon } from "@material-ui/icons"

import {
	Grid,
	Tooltip
} from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import AttendanceSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AttendanceTransferBlock/AttendanceSectionEditor"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import {
	getCurrentAttendanceTransferOption,
	getWillShowAttendanceTransferDescription
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import { generateUUID } from "@/utils/id"
import { getElseRule } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"

const AttendanceTransferBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const {
		changeContent,
		content,
		nextChatBotFlowBlockRules,
		changeRule
	} = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const reactFlow = useReactFlow()

	const customClasses = useCustomStyles()

	const willShowOptionDescription = getWillShowAttendanceTransferDescription(content)

	const isCurrentAccountManagerTransferType = content.attendanceTransferData?.type === "account-manager"
	const attendanceTransferDescription = content?.attendanceAssignmentData?.assignmentObservation

	const attendanceTransferTypeContent = useMemo(() => {
		const transferType = content?.attendanceTransferData?.type

		const currentAttendanceTransferOption = getCurrentAttendanceTransferOption(chatBotFlowConstructorStore.constructionResources, content)
		const optionName = currentAttendanceTransferOption.attendance?.name || "??"

		const transferTypeContentMap: Record<ChatBotFlowBlockContent["attendanceTransferData"]["type"], string | React.ReactNode> = {
			"account-manager": "Atendente responsável pelo contato",
			"random-attendant": "Atendentes",
			"general-queue": "Fila geral",
			"specific-attendant": <span>Atendente <b><u>{optionName}</u></b></span>,
			"specific-team": <span>Equipe <b><u>{optionName}</u></b></span>,
			"random-team-attendant": <span>Atendentes da equipe <b><u>{optionName}</u></b></span>
		}

		return transferTypeContentMap[transferType] || ""
	}, [content])

	const changeRuleByTransferType = (updatedContent: ChatBotFlowBlockContent) => {
		const isUpdatedAttendanceTransferTypeAccountManager = updatedContent.attendanceTransferData.type === "account-manager"
		const isOldTransferTypeAccountManager = isCurrentAccountManagerTransferType

		if (!isUpdatedAttendanceTransferTypeAccountManager) {
			const elseRule = getElseRule(nextChatBotFlowBlockRules)

			if (elseRule) {
				const edges = reactFlow.getEdges()
				const matchingRuleEdge = edges.find(edge => edge.sourceHandle === elseRule.id)

				if (matchingRuleEdge) {
					reactFlow.deleteElements({ edges: [matchingRuleEdge] })
				}

				changeRule("DELETE", elseRule)
			}

			return
		}

		if (isOldTransferTypeAccountManager) {
			return
		}

		changeRule("CREATE", {
			id: generateUUID(),
			next_chat_bot_flow_block_id: null,
			logic: "else",
			validations: [],
			category: "exit-condition"
		})
	}

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<AttendanceSectionEditor
					onSave={content => {
						changeContent(content)
						changeRuleByTransferType(content)
					}}
					chatBotFlowBlockContent={content}
				>
					<Grid
						container
						direction="column"
					>
						<BlockBodySectionContainer
							text={attendanceTransferTypeContent ? (
								<ContrastChip
									title={attendanceTransferTypeContent}
									backgroundColor={colors.unrelated.B0E3FF}
								/>
							) : ""}
							title="Transferir para:"
							placeholder="Nenhuma transferência selecionada"
							chatBotFlowBlockId={node.data.chatBotFlowBlockId}
							errorType="transfer_block_missing_selection"
						/>

						{willShowOptionDescription && (
							<BlockBodySectionContainer
								text={attendanceTransferDescription ? (
									<Tooltip
										title={attendanceTransferDescription}
										placement="right-end"
									>
										<Grid
											style={{
												width: "100%"
											}}
										>
											<ContrastChip
												title={attendanceTransferDescription}
												backgroundColor={colors.unrelated.B0E3FF}
												className={customClasses.inlineText}
												style={{
													display: "inline-flex"
												}}
											/>
										</Grid>
									</Tooltip>
								) : ""}
								title="Observação:"
								placeholder="Nenhuma observação adicionada"
							/>
						)}
					</Grid>
				</AttendanceSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={nextChatBotFlowBlockRules}
				text="Próximo passo após transferência"
			/>

			{isCurrentAccountManagerTransferType && (
				<ElseConditionSection
					nextChatBotFlowBlockRules={nextChatBotFlowBlockRules}
					text="Caso o contato não tenha responsável"
					customIcon={<NoAccountManagerIcon />}
				/>
			)}
		</BaseBlock>
	)
}

export default AttendanceTransferBlock
