import ApiService, { makeRawApiRequest } from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { EnhanceType, EnhanceData } from "@/protocols/skynet"

import { ErrorType } from "@/hooks/useValidation"

import { SECOND_IN_MS } from "@/utils/time"

type EnhanceUsageActionType = "discard" | "replace" | "retry"

type LogEnhanceUsageInput<Type extends EnhanceType> = {
	enhanceType: Type
	enhanceData: EnhanceData<Type>
	action: EnhanceUsageActionType
	rawTestingEnhanceData: RawTestingEnhanceData
}

export type RawTestingEnhanceData = Record<string, unknown>

class SkynetService {
	async enhanceText<Type extends EnhanceType> (type: Type, data: EnhanceData<Type>, onTextChunk: (textChunk: string) => void): Promise<Response> {
		const response = await makeRawApiRequest(`/skynet/text-enhancement/${type}/enhance`, {
			method: "PUT",
			body: data,
			signal: AbortSignal.timeout(30 * SECOND_IN_MS)
		})

		if (response.body) {
			const reader = response.body.getReader()
			const decoder = new TextDecoder("utf-8")

			while (true) {
				const { done, value } = await reader.read()

				if (done) {
					break
				}

				const textChunk = decoder.decode(value, { stream: true })
				onTextChunk(textChunk)
			}
		}

		return response
	}

	async logEnhanceUsage<Type extends EnhanceType> (input: LogEnhanceUsageInput<Type>): Promise<void> {
		try {
			const {
				enhanceType,
				enhanceData,
				action,
				rawTestingEnhanceData
			} = input

			await ApiService.post(`/skynet/text-enhancement/${enhanceType}/log-usage`, {
				enhanceData,
				action,
				rawTestingEnhanceData
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	extractRawTestingEnhanceDataFromResponseHeaders (responseHeaders: Response["headers"]): RawTestingEnhanceData {
		const testingEnhanceDataHeaderKeyPrefix = "testing-enhance-data-"

		const rawTestingEnhanceData: RawTestingEnhanceData = {}

		responseHeaders.forEach((headerValue, headerKey) => {
			const isTestingEnhanceDataHeader = headerKey.startsWith(testingEnhanceDataHeaderKeyPrefix)

			if (isTestingEnhanceDataHeader) {
				const rawTestingEnhanceDataKey = headerKey.replace(testingEnhanceDataHeaderKeyPrefix, "")
				rawTestingEnhanceData[rawTestingEnhanceDataKey] = headerValue
			}
		})

		return rawTestingEnhanceData
	}
}

export default new SkynetService()
