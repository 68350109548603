export const DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [20, 50, 100, 200]

export type DefaultWhereDataType = {
	page: number,
	rowsPerPage: number,
	search?: string
}
