import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	boxContainer: {
		minHeight: "550px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	title: {
		fontSize: 24,
		fontWeight: 700,
		color: newColors.grey[700]
	},
	subTitle: {
		fontSize: 18,
		fontWeight: 500,
		color: newColors.grey[600],
		marginTop: 8,
		marginBottom: 16,
		paddingRight: 24
	},
	description: {
		fontSize: 16,
		fontWeight: 400,
		color: newColors.grey[600],
		marginRight: 96
	},
	button: {
		marginTop: 24,
		backgroundColor: newColors.purple[600],
		boxShadow: "none",
		width: 500,
		textTransform: "uppercase",
		"&:hover": {
			backgroundColor: newColors.purple[700]
		}
	},
	confettiExplosion: {
		position: "absolute",
		right: "70%",
		width: "-1px",
		height: "-1px",
		padding: 0,
		margin: "-1px",
		overflow: "hidden",
		clip: "rect(0, 0, 0, 0)",
		whiteSpace: "nowrap",
		border: 0
	}
})

export default useStyles
