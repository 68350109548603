import React, { useState } from "react"
import clsx from "clsx"
import Fade from "@material-ui/core/Fade"
import {
	Popper,
	ThemeProvider,
	Grid,
	Typography,
	Paper,
	Button
} from "@material-ui/core"
import {
	WarningTwoTone as WarningIcon,
	WrapTextOutlined as ReplaceIcon,
	ReplayOutlined as RetryIcon
} from "@material-ui/icons"

import { TextEnhancementIcon } from "@/assets/icons"

import { Loading, Divider, SvgIcon } from "@/components"

import { EnhanceData, EnhanceType } from "@/protocols/skynet"

import { renderComponent } from "@/utils/node"
import { getSkynetErrorMessage } from "@/utils/skynet"

import SkynetService, { RawTestingEnhanceData } from "@/services/Skynet"
import ErrorHandlerService from "@/services/ErrorHandler"

import useDidMount from "@/hooks/useDidMount"
import { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/components/Skynet/TextEnhancementDialog/styles"
import useCustomStyles from "@/styles/custom"

import theme from "@/styles/theme"
import newColors from "@/styles/newColors"

import TextEnhancementDialogSkeleton from "@/skeletons/TextEnhancementDialog"

export type TextEnhancementDialogProps<Type extends EnhanceType> = {
	anchorElement: HTMLElement | null
	enhanceType: Type
	enhanceData: EnhanceData<Type>
	onReplace: (text: string) => void
	onTextEnhanced?: (text: string) => void
	secondaryTitleText?: React.ReactNode
	customSecondaryTitleTextStyle?: string
}

type TextEnhancementDialogType<Type extends EnhanceType> = {
	open: (props: TextEnhancementDialogProps<Type>) => void
}

const TextEnhancementDialog: TextEnhancementDialogType<EnhanceType> & React.FC<TextEnhancementDialogProps<EnhanceType>> = (props) => {
	const {
		anchorElement,
		enhanceData,
		enhanceType,
		onReplace,
		onTextEnhanced,
		secondaryTitleText,
		customSecondaryTitleTextStyle
	} = props

	const [loading, setLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState("")
	const [enhancedText, setEnhancedText] = useState("")
	const [dialogOpened, setDialogOpened] = useState(false)
	const [rawTestingEnhanceData, setRawTestingEnhanceData] = useState<RawTestingEnhanceData>({})

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleEnhanceText = async () => {
		setEnhancedText("")
		setLoading(true)

		try {
			const response = await SkynetService.enhanceText(enhanceType, enhanceData, (textChunk) => {
				setEnhancedText(enhancedText => enhancedText + textChunk)
			})

			const extractedRawTestingEnhanceData = SkynetService.extractRawTestingEnhanceDataFromResponseHeaders(response.headers)
			setRawTestingEnhanceData(extractedRawTestingEnhanceData)

			onTextEnhanced?.(enhancedText)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			const errorMessage = getSkynetErrorMessage(error as ErrorType)
			setErrorMessage(errorMessage)
		}

		setLoading(false)
	}

	const handleClose = () => {
		setDialogOpened(false)
	}

	const handleDiscard = () => {
		handleClose()

		SkynetService.logEnhanceUsage({
			enhanceType,
			enhanceData,
			action: "discard",
			rawTestingEnhanceData
		})
	}

	const handleReplace = () => {
		onReplace(enhancedText.trim())

		handleClose()

		SkynetService.logEnhanceUsage({
			enhanceType,
			enhanceData,
			action: "replace",
			rawTestingEnhanceData
		})
	}

	const handleRetry = () => {
		handleEnhanceText()

		SkynetService.logEnhanceUsage({
			enhanceType,
			enhanceData,
			action: "retry",
			rawTestingEnhanceData
		})
	}

	const handleOpen = () => {
		setDialogOpened(true)

		handleEnhanceText()
	}

	useDidMount(() => {
		handleOpen()
	})

	return (
		<ThemeProvider theme={theme}>
			<Popper
				transition
				open={dialogOpened}
				anchorEl={anchorElement}
				placement="top-start"
				className={classes.popper}
				modifiers={{
					offset: {
						enabled: true,
						offset: "0, 16"
					}
				}}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper
							style={{ width: anchorElement?.clientWidth }}
						>
							<Grid container>
								<Grid item xs={12}>
									<Grid
										container
										className={classes.bodyContainer}
										spacing={2}
									>
										<Grid item xs={12}>
											<Grid
												container
												spacing={1}
												alignItems="center"
												justifyContent="flex-start"
											>
												{errorMessage ? (
													<Grid item>
														<Grid
															container
															alignItems="center"
															alignContent="flex-start"
														>
															<WarningIcon htmlColor={newColors.yellow[500]} />

															<Divider orientation="vertical" size={1} />

															<Typography variant="h2">
																NÃO FOI POSSÍVEL GERAR UMA SUGESTÃO
															</Typography>
														</Grid>
													</Grid>
												) : (
													<>
														<Grid item>
															<Grid
																container
																alignItems="center"
																alignContent="flex-start"
															>
																<SvgIcon icon={TextEnhancementIcon} htmlColor={newColors.purple[500]} />

																<Divider orientation="vertical" size={1} />

																<Typography variant="h2">
																	SUGESTÃO DA IA:
																</Typography>
															</Grid>
														</Grid>

														{secondaryTitleText && (
															<Grid item>
																<Typography className={classes.secondaryTitleText}>
																	{secondaryTitleText}
																</Typography>
															</Grid>
														)}
													</>
												)}
											</Grid>
										</Grid>

										<Loading
											loading={loading && !enhancedText}
											customLoadingElement={<TextEnhancementDialogSkeleton />}
										>
											<Grid item xs={12} className={customSecondaryTitleTextStyle}>
												<Grid
													container
													className={classes.textContainer}
												>
													<Typography variant="body1">
														{errorMessage || enhancedText}
													</Typography>
												</Grid>
											</Grid>
										</Loading>

										{!loading && (
											<Grid item xs={12}>
												<Grid
													container
													spacing={1}
													justifyContent="flex-end"
												>
													{errorMessage ? (
														<Grid item>
															<Button
																variant="text"
																onClick={handleClose}
																className={clsx({
																	[customClasses.cancelButton]: true,
																	[classes.actionButton]: true
																})}
															>
																FECHAR
															</Button>
														</Grid>
													) : (
														<>
															<Grid item>
																<Button
																	variant="text"
																	onClick={handleDiscard}
																	className={clsx({
																		[customClasses.cancelButton]: true,
																		[classes.actionButton]: true
																	})}
																>
																	DESCARTAR
																</Button>
															</Grid>

															<Grid item>
																<Button
																	variant="outlined"
																	color="primary"
																	onClick={handleRetry}
																	className={classes.actionButton}
																	startIcon={<RetryIcon />}
																>
																	GERAR NOVA SUGESTÃO
																</Button>
															</Grid>

															<Grid item>
																<Button
																	variant="outlined"
																	color="primary"
																	onClick={handleReplace}
																	className={classes.actionButton}
																	startIcon={<ReplaceIcon />}
																>
																	SUBSTITUIR
																</Button>
															</Grid>
														</>
													)}
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Grid
										container
										className={classes.footerContainer}
									>
										<Typography
											variant="caption"
											className={classes.footerText}
										>
											⚠ As respostas da IA podem, em alguns casos, não ser completamente precisas ou podem gerar algum equívoco.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Fade>
				)}
			</Popper>
		</ThemeProvider>
	)
}

TextEnhancementDialog.open = <Type extends EnhanceType> (props: TextEnhancementDialogProps<Type>) => {
	renderComponent(
		"text-enhancement-dialog",
		<TextEnhancementDialog
			{...props}
		/>
	)
}

export default TextEnhancementDialog
