import React from "react"
import {
	Grid,
	MenuItem,
	Select, Typography
} from "@material-ui/core"
import useCustomStyles from "@/styles/custom"
import { IProductMessageMetrics } from "@/protocols/metrics"
import { Divider, Loading } from "@/components"
import LastDaysMetricsTableCard, { FilterOptions, LastDaysMetricsFilter } from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/LastDaysMetrics/LastDaysMetricsTableCard"
import ProductMessageMetricsSkeleton from "@/skeletons/Admin/ProductMessageMetricsSkeleton"
import { MessageFeature } from "@/protocols/channel"
import useContainerStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/styles"
import { useGlobalStateStore } from "@/store/GlobalState"
/**
 * Since we are using an array value inside select option, we need to use this workaround
 * to make sure the value will be correct selected in this component. Some bugs can
 * appear when using array values in select since the comparison is made by
 * reference, not the real array value.
 */

export const FILTER_OPTIONS: FilterOptions = {
	features: [
		{
			title: "Integração, Envio em Massa, Bot e Atendentes",
			value: ["message-blast", "integration", "chat-bot", "attendance"]
		},
		{
			title: "Integração, Envio em Massa, Bot, Automações de plugins Active Campaign, Plugin de chat Active Campaign e Atendentes",
			value: ["message-blast", "integration", "chat-bot", "attendance", "active-campaign-chat", "active-campaign-plugin-automation"]
		},
		{
			title: "Integração",
			value: ["integration"]
		},
		{
			title: "Envio em massa",
			value: ["message-blast"]
		},
		{
			title: "Bot",
			value: ["chat-bot"]
		},
		{
			title: "Atendentes",
			value: ["attendance"]
		},
		{
			title: "Plugin de chat Active Campaign",
			value: ["active-campaign-chat"]
		},
		{
			title: "Automações de plugins Active Campaign",
			value: ["active-campaign-plugin-automation"]
		}
	],
	lastDays: [
		{
			title: "Últimos 7 dias",
			value: 7
		},
		{
			title: "Últimos 30 dias",
			value: 30
		},
		{
			title: "Últimos 60 dias",
			value: 60
		}
	]
}

export const FILTER_OPTIONS_WABA: FilterOptions = {
	features: [
		{
			title: "Bot e Atendentes",
			value: ["chat-bot", "attendance"]
		},
		{
			title: "Bot, Automações de plugins Active Campaign, Plugin de chat Active Campaign e Atendentes",
			value: ["chat-bot", "attendance", "active-campaign-chat", "active-campaign-plugin-automation"]
		},
		{
			title: "Bot",
			value: ["chat-bot"]
		},
		{
			title: "Atendentes",
			value: ["attendance"]
		},
		{
			title: "Plugin de chat Active Campaign",
			value: ["active-campaign-chat"]
		},
		{
			title: "Automações de plugins Active Campaign",
			value: ["active-campaign-plugin-automation"]
		}
	],
	lastDays: [
		{
			title: "Últimos 7 dias",
			value: 7
		},
		{
			title: "Últimos 30 dias",
			value: 30
		},
		{
			title: "Últimos 60 dias",
			value: 60
		}
	]
}

const FeaturesSerializer = {
	serialize: (features: MessageFeature[]): string => {
		return features.join(",")
	},
	deserialize: (serializedFeatures: string): MessageFeature[] => {
		return serializedFeatures.split(",") as MessageFeature[]
	}
}

type LastDaysMetricsProps = {
	lastDaysMetricsFilter: LastDaysMetricsFilter,
	handleChangeLastDaysMetricsFilter: (filter: Partial<LastDaysMetricsFilter>) => void
	metrics: IProductMessageMetrics
	loading: boolean
}

const LastDaysMetrics: React.FC<LastDaysMetricsProps> = (props) => {
	const customClasses = useCustomStyles()
	const containerStyles = useContainerStyles()
	const {
		lastDaysMetricsFilter,
		handleChangeLastDaysMetricsFilter,
		metrics,
		loading
	} = props

	const globalStateStore = useGlobalStateStore()
	const isWabaChannel = globalStateStore.instance.current_channel_type === "waba"

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ProductMessageMetricsSkeleton />}
		>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={containerStyles.constainerDivision}>
						<Grid
							item>
							<Typography
								variant="h2"
								color="textPrimary"
								style={{ fontSize: 18 }}
								className={customClasses.uppercase}
							>HISTÓRICO DE MENSAGENS
							</Typography>
						</Grid>
						<Grid item style={{ flex: 1 }}>
							<Grid container justifyContent="flex-end" spacing={1}>
								<Grid
									item
								>
									<Select
										fullWidth
										variant="outlined"
										value={FeaturesSerializer.serialize(lastDaysMetricsFilter.features)}
										onChange={({ target }) => handleChangeLastDaysMetricsFilter({ features: FeaturesSerializer.deserialize(target.value as string) })}
									>
										{
											isWabaChannel ? (
												FILTER_OPTIONS_WABA.features.map((feature, index) => (
													<MenuItem
														value={FeaturesSerializer.serialize(feature.value)}
														key={index}
													>
														{feature.title}
													</MenuItem>
												)
												)
											) : (
												FILTER_OPTIONS.features.map((feature, index) => (
													<MenuItem
														value={FeaturesSerializer.serialize(feature.value)}
														key={index}
													>
														{feature.title}
													</MenuItem>
												)
												)
											)
										}
									</Select>
								</Grid>

								<Grid
									item
								>
									<Select
										fullWidth
										variant="outlined"
										value={lastDaysMetricsFilter.lastDaysCount}
										onChange={({ target }) => handleChangeLastDaysMetricsFilter({ lastDaysCount: target.value as number })}
									>
										{FILTER_OPTIONS.lastDays.map((lastDays, index) => (
											<MenuItem
												value={lastDays.value}
												key={index}
											>
												{lastDays.title}
											</MenuItem>
										))}
									</Select>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid
					item
					style={{ flex: 1 }}
				>
					<LastDaysMetricsTableCard
						messageMetricsByDate={metrics?.messageMetricsByDate || []}
						lastDaysMetricsFilter={lastDaysMetricsFilter}
					/>
				</Grid>
			</Grid>
		</Loading>
	)
}

export default LastDaysMetrics
