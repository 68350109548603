import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"
import GenericConditionSectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import ScheduleSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AttendanceScheduleBlock/ScheduleSectionEditor"
import BetweenWeekAndTimeContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/BetweenWeekAndTimeContainer"

import { getConditionRuleByCategory } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"

const AttendanceScheduleBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySectionGroup>
				{conditionRules?.map(rule => {
					return rule.validations.length > 0 && <BlockBodySection
						key={rule.id}
						fullWidth
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<ScheduleSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<GenericConditionSectionContainer
									chatBotFlowBlockRule={rule}
									customValidationRender={{ "between-week-time": BetweenWeekAndTimeContainer }}
								/>
							</ScheduleSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				})}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<ScheduleSectionEditor
					hideChildSelection
					onSave={(chatBotFlowBlockRule) => (
						chatBotFlowBlockDetails.changeRule("CREATE", {
							...chatBotFlowBlockRule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null,
							validations: chatBotFlowBlockRule?.validations || []
						})
					)}
					chatBotFlowBlockRule={
						getConditionRuleByCategory(
							"continue-condition",
							chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules
						) as ChatBotFlowBlockRule
					}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
						chatBotFlowBlockId = {node?.data?.chatBotFlowBlockId}
						errorType="attendace_schedule_missing_time"
					>
						Adicionar horário
					</CreateConditionButton>
				</ScheduleSectionEditor>
			</BlockBodySection>

			<ElseConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
				text="Se estiver fora dos horários definidos"
			/>
		</BaseBlock>
	)
}

export default AttendanceScheduleBlock
