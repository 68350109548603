import { ChannelType, ISendableMessage, MessageStatus, MessageType } from "@/protocols/channel"
import { MessageErrorDetails } from "@/protocols/messages"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

import { generateUUID } from "@/utils/id"
import { getWABAMessageErrorDetails } from "@/@integrations/WABA/utils/message"
import { Message } from "@/protocols/chatGlobalStateProtocol"

type BuildMessageInput = {
	inboxChannelId: number
	inboxChannelChatId: number
	type: MessageType
	replyMessage?: Message
}

export const isTextMessage = (messageType?: MessageType): boolean => {
	return messageType === "text"
}

export const isMediaMessage = (messageType?: MessageType): boolean => {
	if (!messageType) {
		return false
	}

	const mediaTypes: MessageType[] = [
		"audio",
		"file",
		"picture",
		"video",
		"sticker"
	]

	const isMediaMessage = mediaTypes.includes(messageType)

	return isMediaMessage
}

export const hasVariable = (message?: string): boolean => {
	if (!message) {
		return false
	}
	const regex = /\{\{\s*\w+\s*\}\}/
	return regex.test(message)
}

export const getMessageColor = (sentByCustomer: boolean): string => {
	return sentByCustomer ? colors.palette.whatsappSentMessage : colors.grayScale[11]
}

export const getInboxMessageColor = (sentByCustomer: boolean, status?: MessageStatus): string => {
	const messageColor = {
		errorMessage: newColors.red[100],
		successMessage: sentByCustomer ? colors.palette.whatsappSentMessage : colors.grayScale[11]
	}

	return status === "not-sent" ? messageColor.errorMessage : messageColor.successMessage
}

export const generateMessageId = () => generateUUID()

export const buildMessage = (messageId: string, input: BuildMessageInput): ISendableMessage => {
	const {
		inboxChannelChatId,
		inboxChannelId,
		type,
		replyMessage
	} = input

	const builtMessage: ISendableMessage = {
		id: messageId,
		content: "",
		sequentialId: Date.now(),
		type,
		senderName: "",
		createdAt: String(new Date()),
		inboxChannelChatId,
		inboxChannelId,
		sentByCustomer: true,
		sentByExternalPlatform: false,
		sentBySystem: false,
		read: true,
		deletedAt: null,
		caption: "",
		status: "created",
		feature: "attendance",
		fileName: ""
	}

	if (replyMessage) {
		builtMessage.replyMessage = {
			content: replyMessage.content,
			createdAt: replyMessage.createdAt,
			id: replyMessage.id,
			senderName: replyMessage.senderName,
			sentByCustomer: replyMessage.sentByCustomer,
			type: replyMessage.type,
			deletedAt: replyMessage.deletedAt,
			caption: replyMessage.caption
		}
	}

	return builtMessage
}

export const getMessageErrorDetails = (input: { error?: string, channelType: ChannelType }): MessageErrorDetails => {
	const { error, channelType } = input

	const DEFAULT_ERROR_DETAILS: MessageErrorDetails = {
		title: "Falha ao enviar a mensagem",
		description: "Houve um erro ao enviar a mensagem, tente novamente mais tarde"
	}

	if (!error) {
		return DEFAULT_ERROR_DETAILS
	}

	const channelTypeToMessageErrorDetails: Record<ChannelType, () => (Partial<MessageErrorDetails> | undefined)> = {
		waba: () => getWABAMessageErrorDetails(error),
		whatsapp: () => DEFAULT_ERROR_DETAILS
	}

	const messageErrorDetails = channelTypeToMessageErrorDetails[channelType]()

	return {
		title: messageErrorDetails?.title || DEFAULT_ERROR_DETAILS.title,
		description: messageErrorDetails?.description || DEFAULT_ERROR_DETAILS.description
	}
}
