import React from "react"
import { Grid, Typography, Divider as MuiDivider, ListItemIcon, Tooltip } from "@material-ui/core"
import clsx from "clsx"

import { Divider } from "@/components"

import BlockBodyItemText from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionText"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { ChatBotFlowError } from "@/protocols/chatBotFlow"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer/styles"

type BlockBodySectionContainerProps = {
	backgroundColor?: string
	styles?: React.CSSProperties
	className?: string
	text: string | React.ReactNode | React.ReactNodeArray
	title?: string
	placeholder?: string,
	footerComponent?: React.ReactElement,
	informationText?: string
	icon?: React.ReactNode
	chatBotFlowBlockId?: number
	errorType?: ChatBotFlowError
	tooltipText?: string
}

const BlockBodyItemContainer: React.FC<BlockBodySectionContainerProps> = (props) => {
	const {
		backgroundColor,
		styles,
		text,
		title,
		footerComponent,
		placeholder,
		className,
		informationText,
		icon,
		chatBotFlowBlockId,
		errorType,
		tooltipText = ""
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const classes = useStyles()

	const isThereAnyValidText = () => {
		const isString = typeof text === "string"

		if (isString) {
			return Boolean(text)
		} else {
			return React.Children.count(text) > 0
		}
	}

	const hasError = chatBotFlowConstructorStore.hasBlockError(String(chatBotFlowBlockId), errorType)

	return (
		<Tooltip title={tooltipText}>
			<Grid
				container
				direction="row"
				alignItems="flex-start"
				className={clsx({
					[classes.container]: true,
					[className as string]: className,
					[classes.animation]: hasError
				})}
				style={{
					...styles,
					backgroundColor
				}}
			>
				{title && (
					<>
						<Typography
							variant="caption"
							className={classes.title}
						>
							{title}
						</Typography>

						<Divider orientation="horizontal" size={2} />
					</>
				)}

				{isThereAnyValidText() ? (
					<BlockBodyItemText
						text={text}
					/>
				) : (
					<ContrastChip
						title={placeholder}
						backgroundColor={colors.unrelated.E3E6E8}
					/>
				)}

				{informationText && (
					<Grid container justifyContent="flex-start" alignItems="center" >
						<Divider orientation="horizontal" size={2} />

						{icon && (
							<Grid item xs={2}>
								<ListItemIcon>
									{icon}
								</ListItemIcon>
							</Grid>
						)}

						<Grid item xs={10} style={{ textAlign: "justify" }}>
							<Typography
								variant="caption"
								className={classes.information}
							>
								{informationText}
							</Typography>
						</Grid>
					</Grid>
				)}

				{footerComponent && <Grid item xs={12}>
					<MuiDivider orientation="horizontal" style={{ margin: "10px 0px 5px 0px" }} />
				</Grid>}

				{footerComponent && <Grid item xs>
					<Grid direction="row" container justifyContent="center" alignItems="center">{footerComponent}</Grid>
				</Grid>}
			</Grid>
		</Tooltip>
	)
}

export default BlockBodyItemContainer
