import React from "react"
import {
	Chip
} from "@material-ui/core"

import {
	WABAChannelMessageTemplate,
	WABAChannelMessageTemplateStatus
} from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"

import colors from "@/styles/colors"

export const getTemplateStatusChip = (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
	const templateStatusToChipConfig: Record<WABAChannelMessageTemplateStatus, { title: string, color: string }> = {
		approved: {
			title: "Aprovado",
			color: colors.unrelated.D4F8D3
		},
		deactivated: {
			title: "Desativado",
			color: colors.unrelated.F8DAD3
		},
		failed: {
			title: "Falhou",
			color: colors.unrelated.F8DAD3
		},
		paused: {
			title: "Pausado",
			color: colors.unrelated.F8DAD3
		},
		pending: {
			title: "Pendente",
			color: colors.unrelated.F8DAD3
		},
		rejected: {
			title: "Rejeitado",
			color: colors.unrelated.F8DAD3
		},
		submitted: {
			title: "Submetido",
			color: colors.unrelated.F8DAD3
		},
		unknown: {
			title: "Desconhecido",
			color: colors.unrelated.F8DAD3
		}
	}

	const chipConfig = templateStatusToChipConfig[wabaChannelMessageTemplate.status] || templateStatusToChipConfig.unknown

	return (
		<Chip
			label={chipConfig.title}
			style={{
				backgroundColor: chipConfig.color,
				cursor: "pointer",
				height: 20
			}}
		/>
	)
}
