import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

type StylesProps = {
	isEditMode?: boolean
}

const useStyles = makeStyles((theme) => ({
	container: {
		border: "1px solid",
		borderColor: newColors.grey[300],
		borderRadius: 8,
		padding: 16,
		display: "flex",
		flexDirection: "column",
		gap: 8,
		margin: "auto"
	},
	radioIcon: (props: StylesProps) => ({
		padding: "10px 10px 10px 10px",
		color: props.isEditMode ? newColors.grey[400] : theme.palette.primary.main,
		"&.Mui-checked": {
			color: props.isEditMode ? newColors.grey[400] : theme.palette.primary.main
		}
	}),
	categoryOption: {
		display: "flex",
		width: "48%",
		alignItems: "flex-start",
		padding: "10px 10px 10px 0px",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: newColors.grey[100],
		cursor: "pointer"
	},
	input: {
		height: 100
	},
	previewMessageContent: {
		width: "100%",
		maxHeight: 500,
		padding: 10,
		overflowY: "auto",
		borderRadius: 8,
		margin: "5px 5px 5px 0px",
		"&::-webkit-scrollbar": {
			width: "0.25em"
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: newColors.grey[300],
			borderRadius: "0px 8px 8px 8px"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: newColors.grey[400],
			borderRadius: 8,
			border: "2px solid transparent"
		}
	},
	statusChip: {
		backgroundColor: newColors.grey[200],
		color: newColors.grey[600],
		borderRadius: 5,
		height: 23
	},
	form: {
		paddingRight: 10
	},
	messagePreview: {
		padding: 10,
		flexDirection: "column",
		justifyContent: "start",
		border: "solid 1px",
		borderColor: newColors.grey[300],
		borderRadius: 8,
		position: "sticky",
		top: 0
	},
	disabledOptionOnEditMode: (props: StylesProps) => ({
		color: props.isEditMode ? newColors.grey[400] : ""
	})
}))

export default useStyles
