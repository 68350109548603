import React from "react"

import {
	Box,
	Button,
	Grid,
	IconButton,
	Typography
} from "@material-ui/core"

import {
	deviceIsMobile,
	isSmallScreen
} from "@/utils/checkDevice"
import { Link as LinkRouter } from "react-router-dom"

import {
	// Forum as ChatIcon,
	Person as AttendantsIcon,
	Contacts as ClientCatalogIcon,
	Assistant as QuickReplyIcon,
	Group as TeamIcon,
	LocalOffer as TagIcon,
	// AttachMoneyOutlined as SubscriptionsIcon,
	Close as CloseIcon,
	AccountCircle as MyDataIcon,
	Dashboard as DashboardIcon,
	DeviceHub as IntegrationIcon,
	// Settings as SettingsIcon,
	Notifications as NotificationsIcon,
	Send as MessageBlastIcon,
	GroupWork as GroupIcon,
	QuestionAnswer as GoToInboxIcon
} from "@material-ui/icons"

import {
	ReactComponent as MegaphoneIcon
} from "@/assets/icons/megaphone.svg"

import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"

import {
	ReactComponent as RDStationSVGIcon
} from "@/assets/images/logos/rd_station.svg"

import {
	// PopConfirm,
	ListItemMenu,
	Divider,
	AccessibleDrawer,
	SvgIcon
} from "@/components"

import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"
import logoImg from "@/assets/images/logos/letalk-logo.svg"
import {
	ReactComponent as GroupMessageBlastIcon
} from "@/assets/icons/groups.svg"

// import { openUrlOnlyOnce } from "@/utils/url"

// import {
// 	inboxAppUrl,
// 	accountAppUrl
// } from "@/config/url"

// import { useGlobalStateStore } from "@/store/GlobalState"

import ListItemCategory from "@/components/ListItemCategory"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Admin/Menu/styles"
import useCustomStyles from "@/styles/custom"
import HardCoded from "@/services/HardCoded"

type MenuProps = {
	openDrawer: boolean
	onClose: () => void
}

const SideBar = (props: MenuProps) => {
	const {
		openDrawer,
		onClose
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const globalStateStore = useGlobalStateStore()

	const isShowAllChatBotVersions = globalStateStore.user.botVersions.willShow
	const groupPermission = globalStateStore.user.planPermissions.group

	// const whatsappChannelStatus = globalStateStore.currentChannel?.status

	// const userStore = globalStateStore.user

	const handleDrawerClose = () => {
		onClose()
	}

	// const goToBlankPage = (
	// 	e: React.MouseEvent<HTMLElement>,
	// 	to: string,
	// 	targetName?: string
	// ) => {
	// 	if (!deviceIsMobile()) {
	// 		openUrlOnlyOnce(e, to, targetName)
	// 	}
	// }

	// const goToAttendance = (event: React.MouseEvent<HTMLElement>) => {
	// 	const isMobile = deviceIsMobile()

	// 	if (isMobile) {
	// 		window.open("/attendance", "_SELF")
	// 	} else {
	// 		goToBlankPage(
	// 			event,
	// 			`${inboxAppUrl.cpURL}/attendance`,
	// 			"inbox_attendance_page"
	// 		)
	// 	}
	// }

	// const onGoToAttendanceButtonClick = async (event: React.MouseEvent<HTMLElement>) => {
	// 	event.preventDefault()

	// 	const whatsappHasNoSession = whatsappChannelStatus === "no-session"

	// 	if (whatsappHasNoSession) {
	// 		PopConfirm.open({
	// 			description: "Verificamos que seu número do whatsapp não está conectado com o Inbox, deseja ir para o atendimento mesmo assim?",
	// 			onConfirm: async () => {
	// 				goToAttendance(event)
	// 			},
	// 			confirmButtonText: "IR PARA ATENDIMENTO"
	// 		})
	// 	} else {
	// 		goToAttendance(event)
	// 	}
	// }

	return (
		<AccessibleDrawer
			variant={isSmallScreen ? "temporary" : "permanent"}
			open={!isSmallScreen || openDrawer}
			anchor="left"
			onClose={handleDrawerClose}
			onMobileBackButtonPress={handleDrawerClose}
			withoutTriggerHistoryBackEvent
			className={!isSmallScreen ? classes.drawer : ""}
			classes={{
				paper: !isSmallScreen ? `${classes.paper} ${customClasses.scrollBar}` : customClasses.scrollBar
			}}
		>
			<Grid
				container
				justify="center"
				alignItems="center"
				className={classes.container}
			>
				<Grid
					item
					xs={12}
					className={classes.topInfoContainer}
				>
					{
						isSmallScreen &&
						<>
							<Divider size={2} orientation="horizontal" />
							<Grid container justify="space-between" alignItems="center">
								<Grid item xs>
									<img
										alt="Letalk"
										src={logoImg}
										className={classes.logo}
									/>
								</Grid>

								<Grid item>
									<IconButton
										color="inherit"
										onClick={handleDrawerClose}
									>
										<CloseIcon className={classes.closeIcon} />
									</IconButton>
								</Grid>
							</Grid>
						</>
					}

					{/* <Divider size={4} orientation="horizontal" />

					<Button
						startIcon={<ChatIcon />}
						color="primary"
						variant="contained"
						className={classes.goToAttendanceButton}
						onClick={onGoToAttendanceButtonClick}
						fullWidth
					>
						IR PARA ATENDIMENTO
					</Button>

					<Divider size={2} orientation="horizontal" /> */}
				</Grid>

				<Grid item xs={12}>
					<Box>
						<Button
							className={classes.goToInboxButton}
							color="primary"
							variant="outlined"
							size="large"
							startIcon={<GoToInboxIcon />}
							component={LinkRouter}
							to={"/attendance"}
							target={deviceIsMobile() ? "_SELF" : "_BLANK"}
						>
							<Box sx={{ ml: 2.25, mr: 1.8 }}>
								<Typography
									color="primary"
								>
									Ir para o Inbox
								</Typography>
							</Box>
						</Button>
					</Box>
				</Grid>

				<ListItemCategory
					title="INÍCIO"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<DashboardIcon />}
						text="Dashboard"
						to={"/admin/dashboard"}
					/>
				</Grid>

				<ListItemCategory
					title={ groupPermission ? "CONTATOS E GRUPOS" : "CONTATOS"}
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<ClientCatalogIcon />}
						text="Contatos"
						to={"/admin/client-catalog"}
					/>
				</Grid>

				{groupPermission && (
					<Grid item xs={12}>
						<ListItemMenu
							icon={<GroupIcon />}
							text="Grupos"
							to={"/admin/groups"}
						/>
					</Grid>
				)}

				<Grid item xs={12}>
					<ListItemMenu
						icon={<TagIcon />}
						text="Tags"
						to={"/admin/tags"}
					/>
				</Grid>

				<ListItemCategory
					title="ENVIO EM MASSA"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<MessageBlastIcon />}
						text="Contatos"
						to={"/admin/message-blast"}
					/>
				</Grid>

				{groupPermission && (
					<Grid item xs={12}>
						<ListItemMenu
							icon={(
								<SvgIcon
									icon={GroupMessageBlastIcon}
								/>
							)}
							text="Grupos"
							to={"/admin/group-message-blast"}
						/>
					</Grid>
				)}

				<ListItemCategory
					title="AUTOMAÇÕES"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<IntegrationIcon />}
						text="Integrações"
						to={"/admin/integration"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={(
							<SvgIcon
								fontSize="small"
								icon={ChatBotIcon}
							/>
						)}
						text="Bots"
						to={isShowAllChatBotVersions ? "/admin/bots" : "/admin/flow"}
					/>
				</Grid>

				<ListItemCategory
					title="ATENDIMENTO"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<AttendantsIcon />}
						text="Atendentes"
						to={"/admin/attendant"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<TeamIcon />}
						text="Equipes"
						to={"/admin/teams"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<QuickReplyIcon />}
						text="Respostas Rápidas"
						to={"/admin/quick-reply"}
					/>
				</Grid>

				<ListItemCategory
					title="PLUGINS"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={true}
						icon={<SvgIcon
							icon={ActiveCampaignSVGIcon}
						/>}
						text="Active Campaign"
						to={"/admin/plugins/active-campaign"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={false}
						icon={<SvgIcon
							icon={RDStationSVGIcon}
						/>}
						text="RD Station"
						to={"/admin/plugins/rd-station-marketing"}
					/>
				</Grid>

				<ListItemCategory
					title="MINHA CONTA"
				/>

				{/* {
					userStore.extra_data?.is_account_user &&
					<Grid item xs={12}>
						<ListItemMenu
							icon={<SubscriptionsIcon />}
							text="Assinatura"
							to={`${accountAppUrl.cpURL}/profile`}
							isExternalLink
							onClick={(e: React.MouseEvent<HTMLElement>) => goToBlankPage(
								e,
								`${accountAppUrl.cpURL}/profile`,
								"account_page"
							)}
						/>
					</Grid>
				} */}

				<Grid item xs={12}>
					<ListItemMenu
						icon={<MyDataIcon />}
						text="Meus Dados"
						to={"/admin/profile"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<NotificationsIcon />}
						text="Notificações"
						to={"/admin/notifications"}
					/>
				</Grid>

				{
					HardCoded.checkFeatureFlag("cannyFeedback") &&
					<Grid item xs={12}>
						<ListItemMenu
							icon={<MegaphoneIcon
								style={{
									padding: 4
								}}
							/>}
							text="Feedback"
							to={"/admin/feedback"}
						/>
					</Grid>
				}
			</Grid>
		</AccessibleDrawer>
	)
}

export default SideBar
