import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	eventTypeFilterSelect: {
		width: "8rem",
		height: "40px"
	},
	contactDataSearchInput: {
		width: "17rem"
	},
	dateInput: {
		width: "6rem"
	},
	accordionSummary: {
		gap: "0.8rem"
	},
	expandIcon: {
		order: -1,
		"&$expanded": {
			transform: "rotate(-90deg)"
		},
		"&:not($expanded)": {
			transform: "rotate(90deg)"
		}
	},
	expanded: {}
})

export default useStyles
