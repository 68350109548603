import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	input: {
		maxHeight: 100,
		overflow: "auto !important"
	},
	inputDisabled: {
		opacity: 0.5,
		color: "transparent !important",
		cursor: ""
	},
	multiline: {
		padding: theme.spacing(0.75, 0, 0.75, 0.75)
	},
	outline: {
		minHeight: 50,
		border: `1px solid ${newColors.grey[300]}`,
		padding: "9px 12px"
	}
}))

export default useStyles
