import React, { useRef, useState } from "react"

import { WABATemplateCategory, WABATemplateHeaderType } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import WABAChannelMessageTemplateService from "@/@integrations/WABA/services/WABAChannelMessageTemplate"

import TextInput, { TextInputHandler } from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"
import EmojiPicker from "@/pages/Attendance/Chat/ConversationPanel/Input/EmojiPicker"

import { Box, Typography, TextField, Button, InputLabel, Grid, RadioGroup, Paper, Radio, CardContent, MenuItem, Select, Tooltip, Chip, FormHelperText } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import WhatsappBackground from "@/assets/images/waba/whatsapp_background.png"

import ApiService from "@/services/Api"

import VariableInput, { Variable } from "@/components/ChatMessageBuilder/VariableInput"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import { Breadcrumb, Divider } from "@/components"

import useStyles from "@/pages/Admin/Templates/ManageTemplateForm/styles"
import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { hasVariable } from "@/utils/message"
import { useGlobalStateStore } from "@/store/GlobalState"
import { useHistory, useLocation } from "react-router-dom"
import ErrorHandlerService from "@/services/ErrorHandler"

export type ManageTemplate = {
	id?: string
	category: AvailableWABATemplateCategory
	content: string
	headerType: WABATemplateHeaderType
	header?: string
	name: string
	formattedTemplateName: string
	languageCode: "pt_BR" | "en" | "es"
	footer?: string
}

type CategoryOption = {
	label: string
	category: AvailableWABATemplateCategory
	description: string
}

type AvailableWABATemplateCategory = Exclude<WABATemplateCategory, "authentication" | "otp">

const ManageTemplateForm = () => {
	const location = useLocation<{ isEditMode: number, id: string }>()
	const isEditMode = Boolean(location.state?.isEditMode)

	const [manageTemplateData, setManageTemplateData] = useState<ManageTemplate>({
		languageCode: "pt_BR"
	} as ManageTemplate)
	const [variables, setVariables] = useState<Variable[]>([] as Variable[])

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const customClasses = useCustomStyles()
	const classes = useStyles({ isEditMode })
	const history = useHistory()
	const globalStateStore = useGlobalStateStore()
	const contentInputRef = useRef<TextInputHandler>(null)
	const headerInputRef = useRef<TextInputHandler>(null)
	const isUsingMaxVariablesOnHeader = manageTemplateData.headerType === "text" && hasVariable(headerInputRef.current?.getCurrentValue())

	const CATEGOTY_TO_READABLE_STATUS: Record<AvailableWABATemplateCategory, string> = {
		marketing: "Marketing",
		utility: "Utilidade"
	}
	const CATEGORY_OPTION: CategoryOption[] = [
		{
			label: CATEGOTY_TO_READABLE_STATUS.marketing,
			category: "marketing",
			description: "Promoções, ofertas de produtos e outras novidades para aumentar o reconhecimento da marca e o engajamento do público."
		},
		{
			label: CATEGOTY_TO_READABLE_STATUS.utility,
			category: "utility",
			description:
				"Envie atualizações de conta, status de pedidos, alertas e outras informações importantes para manter seus clientes informados."
		}
	]

	const LANGUAGE_CODE_TO_READABLE_LANGUAGE: Record<ManageTemplate["languageCode"], string> = {
		pt_BR: "Portugues (BR)",
		en: "Ingles",
		es: "Espanhol"
	}

	const templateHeaderOptionsToReadableOption: Partial<Record<WABATemplateHeaderType | "", string>> = {
		text: "Texto",
		"": "Nenhum"
	}

	const handleFormDataChance = (key: keyof ManageTemplate, value: string) => {
		clearValidation(key)
		setManageTemplateData(lastState => ({
			...lastState,
			/* We reset the header content when update the headerType to prevent state-related issues. */
			...(key === "headerType" && { header: "" }),
			[key]: value
		}))
	}

	const getAllAvaiableVariables = async (): Promise<void> => {
		const response = await ApiService.get(`/channels/${globalStateStore.currentChannel?.integrationId}/all/variables`)
		setVariables(response.data)
	}

	const loadTemplateOnEditMode = async (): Promise<void> => {
		const templateId = location?.state?.id

		if (isEditMode) {
			try {
				const { data } = await ApiService.get(`/waba/channel/${globalStateStore.currentChannel?.id}/message/single-template/${templateId}`)

				const templateData = {
					...data,
					...(data.header && { headerType: "text" })
				}
				setManageTemplateData(templateData)
				contentInputRef.current?.addText(data.message.content)
				headerInputRef.current?.addText(data.extraData.header.content)
			} catch (error) {
				ErrorHandlerService.handle(error as ErrorType)
			}
		}
	}

	const handleRedirectToTemplateList = () => {
		history.push("/admin/templates")
	}

	const handleManageTemplate = async (): Promise<void> => {
		try {
			if (isEditMode) {
				const templateId = location?.state?.id
				await WABAChannelMessageTemplateService.editMessageTemplate({ ...manageTemplateData, id: templateId }, globalStateStore.currentChannel?.id)
			} else {
				await WABAChannelMessageTemplateService.createMessageTemplate(manageTemplateData, globalStateStore.currentChannel?.id)
			}
			handleRedirectToTemplateList()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	useDidMount(() => {
		getAllAvaiableVariables()
		loadTemplateOnEditMode()
	})

	return (
		<>
			<Grid item xs={12} style={{ paddingTop: 10 }}>
				<Grid item xs={12}>
					<Breadcrumb data={[
						{ name: "Templates", pathname: "/admin/templates" },
						{ name: isEditMode ? "Editar" : "Criar", pathname: "/admin/template/manage" }
					]} />
				</Grid>
				{isEditMode ? <Grid item>
					<Alert severity="warning" icon={false}>
					O processo de edição pode levar algum tempo, pois o template precisa passar por uma análise antes de ser atualizado.
					</Alert>
					<Divider orientation="horizontal" size={2} />
				</Grid> : <Divider orientation="horizontal" size={4} />}
				<Grid container xs={12} direction="column">
					<Grid container xs={12} direction="row">
						<Grid item xs={8}>
							<Grid container direction="column">
								<Grid item className={classes.form}>
									<Grid container direction="row" xs={12}>
										<Grid item xs={12} className={classes.container}>
											<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
												Nome do Template
											</InputLabel>

											<Typography variant="caption">Os nomes dos modelos só podem conter letras minúsculas, números e sublinhados.</Typography>
											<TextField
												disabled={isEditMode}
												name="name"
												value={manageTemplateData.name}
												onChange={({ target }) => {
													const formattedValue = target.value.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "").toLocaleLowerCase()
													handleFormDataChance("name", formattedValue)
												}}
												variant="outlined"
												placeholder="Nome do Template"
												fullWidth
												error={!!validation.name}
												helperText={validation.name}
											/>

										</Grid>
										<Divider orientation="horizontal" size={4} />
										<Grid item xs={12} className={classes.container}>
											<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
												Categoria
											</InputLabel>
											<Typography variant="caption">Selecione o tipo de mensagem do seu template. A Meta pode ajustar a categoria automaticamente.</Typography>
											<RadioGroup
												value={manageTemplateData.category}
												onChange={({ target }) => handleFormDataChance("category", target.value)}
												aria-label="Categoria"
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between"
												}}
											>
												{CATEGORY_OPTION.map((option) => (
													<Paper
														key={option.label}
														className={classes.categoryOption}
													>
														<Grid container direction="row">
															<Grid container direction="row" alignItems="center">
																<Grid item>
																	<Radio
																		className={classes.radioIcon}
																		disabled={isEditMode}
																		checked={manageTemplateData.category === option.category}
																		value={option.category}
																	/>
																</Grid>
																<Grid item>
																	<Typography variant="button" className={classes.disabledOptionOnEditMode}>
																		{option.label}
																	</Typography>
																</Grid>
															</Grid>
															<Divider orientation="horizontal" size={1} />
															<Box sx={{ ml: 2 }}>
																<Typography variant="body2" className={classes.disabledOptionOnEditMode}>{option.description}</Typography>
															</Box>

														</Grid>
													</Paper>
												))}
											</RadioGroup>
											{!!validation.category && <FormHelperText>{validation.category}</FormHelperText>}
											<Divider orientation="horizontal" size={1} />
										</Grid>
										<Divider orientation="horizontal" size={4} />
										<Grid item xs={12} className={classes.container} >
											<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
												Idioma
											</InputLabel>
											<Typography variant="caption">Selecione o idioma que seu template será enviado.</Typography>

											<Select
												value={manageTemplateData.languageCode}
												displayEmpty
												disabled={isEditMode}
												variant="outlined"
												onChange={({ target }) => handleFormDataChance("languageCode", target.value as string)}
												label="Idioma"
												error={!!validation.languageCode}
											>
												{Object.keys(LANGUAGE_CODE_TO_READABLE_LANGUAGE).map((key) => (
													<MenuItem key={key} value={key}>
														{LANGUAGE_CODE_TO_READABLE_LANGUAGE[key as ManageTemplate["languageCode"]]}
													</MenuItem>
												))}
											</Select>

										</Grid>
										<Divider orientation="horizontal" size={4} />
										<Grid item xs={12} className={classes.container} >
											<InputLabel className={customClasses.inputLabel}>
												Cabeçalho {<Chip label={"Opcional"} className={classes.statusChip} />}
											</InputLabel>

											<Divider orientation="horizontal" size={1} />

											<Select
												displayEmpty
												variant="outlined"
												value={templateHeaderOptionsToReadableOption[manageTemplateData.headerType]}
												renderValue={() => manageTemplateData.headerType ? templateHeaderOptionsToReadableOption[manageTemplateData.headerType] : "Nenhum"}
												onChange={({ target }) => handleFormDataChance("headerType", target.value as WABATemplateHeaderType)}
											>
												{Object.keys(templateHeaderOptionsToReadableOption).map((key) => (
													<MenuItem key={key} value={key}>
														{templateHeaderOptionsToReadableOption[key as WABATemplateHeaderType]}
													</MenuItem>
												))}
												<Tooltip title="Esta opção ainda não está disponível. Para criar um template com mídia, por favor utilize a plataforma Gupshup.">
													<Grid item>
														<MenuItem value="" disabled>Mídia</MenuItem>
													</Grid>
												</Tooltip>
											</Select>

											{manageTemplateData.headerType === "text" && <>
												<TextInput
													isOutlined
													onMedia={() => { return "" }}
													onSubmit={() => { return "" }}
													onChange={(target) => handleFormDataChance("header", target)}
													minRows={1}
													ref={headerInputRef}
													inputProps={{ maxLength: 60, disableUnderline: true }}
												/>
												<Grid container direction="row" justifyContent="space-between" alignItems="center">
													<Grid item>
														<Grid container direction="row">
															<Grid item>
																<EmojiPicker
																	onEmoji={(emoji) => headerInputRef.current?.addTextByCursor?.(emoji)}
																/>
															</Grid>
															<Tooltip
																title="Não é possivel adicionar mais de uma variavel a um cabeçalho"
																disableHoverListener={!isUsingMaxVariablesOnHeader}
															>
																<Grid item>
																	<VariableInput
																		disabled={isUsingMaxVariablesOnHeader}
																		variables={variables}
																		onVariable={(variable) => headerInputRef.current?.addTextByCursor?.(`{{ ${variable} }}`)}
																	/>
																</Grid>
															</Tooltip>
														</Grid>
													</Grid>
													<Grid item>
														<Typography variant="body2" color="textSecondary" align="right">
															{`${manageTemplateData.header ? manageTemplateData?.header.length : 0} / ${60}`}
														</Typography>
													</Grid>
												</Grid>
											</>}
										</Grid>
										<Divider orientation="horizontal" size={4} />
										<Grid item xs={12} className={classes.container}>
											<InputLabel className={customClasses.inputLabel}>
												Conteúdo
											</InputLabel>
											<Grid>
												<Grid item>
													<TextInput
														isOutlined
														minRows={3}
														onMedia={() => { return "" }}
														onChange={(value) => handleFormDataChance("content", value)}
														onSubmit={() => { return "" }}
														inputProps={{ maxLength: 1024 }}
														ref={contentInputRef}
														errorProps={{
															error: !!validation.content
														}}
													/>
												</Grid>
												<Grid container direction="row" justifyContent="space-between" alignItems="center">
													<Grid item>
														<Grid container direction="row">
															<Grid item>
																<EmojiPicker
																	onEmoji={(emoji) => contentInputRef.current?.addTextByCursor?.(emoji)}
																/>
															</Grid>
															<Grid item>
																<VariableInput
																	variables={variables}
																	onVariable={(variable) => contentInputRef.current?.addTextByCursor?.(`{{ ${variable} }}`)}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Typography variant="body2" color="textSecondary" align="right">
															{`${manageTemplateData.content ? manageTemplateData?.content.length : 0} / ${1024}`}
														</Typography>
													</Grid>
												</Grid>

											</Grid>

											<Divider orientation="horizontal" size={1} />
										</Grid>
										<Divider orientation="horizontal" size={4} />
										<Grid item xs={12} className={classes.container}>
											<InputLabel className={customClasses.inputLabel}>
												Rodapé {<Chip label={"Opcional"} className={classes.statusChip} />}
											</InputLabel>
											<Typography variant="caption">Adicione um rodapé de até 60 caracteres à sua mensagem.</Typography>
											<TextInput
												isOutlined
												onMedia={() => { return "" }}
												onSubmit={() => { return "" }}
												onChange={(value) => handleFormDataChance("footer", value)}
												inputProps={{ maxLength: 60 }}
											/>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{`${manageTemplateData.footer ? manageTemplateData?.footer.length : 0} / ${60}`}
												</Typography>
											</Grid>
										</Grid>
										<Divider orientation="horizontal" size={2} />
										<Grid item xs={12}>
											<Grid container justifyContent="flex-end">
												<Grid item>
													<Button variant="text" color="primary" onClick={handleRedirectToTemplateList} style={{ height: 40, fontSize: 14 }}>
														Cancelar
													</Button>
												</Grid>
												<Divider orientation="vertical" size={1} />
												<Grid item>
													<Button type="submit" variant="contained" color="primary" onClick={handleManageTemplate} style={{ height: 40, fontSize: 14 }}>
														{isEditMode ? "Editar" : "Criar"} e enviar
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Divider orientation="horizontal" size={3} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Grid container className={classes.messagePreview}>
								<Grid item>
									<Typography className={customClasses.inputLabel}>
							Mensagem
									</Typography>
								</Grid>
								<Grid item>
									<CardContent
										className={classes.previewMessageContent}
										style={{
											backgroundImage: `url(${WhatsappBackground})`
										}}>
										<MessageItem
											type={"text"}
											content={manageTemplateData.content}
											extraData={
												{
													...(manageTemplateData.headerType && {
														header: {
															type: manageTemplateData.headerType,
															content: { text: manageTemplateData?.header }
														}
													}),
													footer: { text: manageTemplateData?.footer }
												}}
											isDefaultSize={false}
											copySpecial={false}
										/>
									</CardContent>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default ManageTemplateForm
