import React from "react"
import { Grid, TextField } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type AIAgentSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const TEXT_CONTEXT_MAX_CHARACTER_COUNT = 5000

const AIAgentSectionEditor: React.FC<AIAgentSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const currentTextContextCharacterCount = contentEditor.content?.aiAgentConversation?.context?.text?.length || 0

	return (
		<BlockBodySectionEditor
			title="DEFINIR CONHECIMENTO DO AGENTE"
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<TextField
								value={contentEditor.content?.aiAgentConversation?.context?.text}
								defaultValue={chatBotFlowBlockContent?.aiAgentConversation?.context?.text}
								onChange={({ target }) => (
									contentEditor.changeContent({
										aiAgentConversation: {
											context: {
												text: target.value
											}
										}
									})
								)}
								variant="outlined"
								color="primary"
								minRows={16}
								maxRows={16}
								multiline
								fullWidth
								placeholder="Defina um conhecimento para o agente aqui"
								inputProps={{
									maxLength: TEXT_CONTEXT_MAX_CHARACTER_COUNT
								}}
								helperText={`${currentTextContextCharacterCount}/${TEXT_CONTEXT_MAX_CHARACTER_COUNT}`}
							/>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default AIAgentSectionEditor
