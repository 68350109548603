import React from "react"

import { Grid, Typography } from "@material-ui/core"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import { PossibleContactRegistrationSources } from "@/protocols/clientCatalog"

import useStyles from "@/pages/Admin/ClientCatalog/ClientProfileInfo/DatesAndIdentifier/styles"

type DatesAndIdentifierProps = {
	id: number
	registeredFrom?: PossibleContactRegistrationSources
	createdAt: Date
	updatedAt: Date
}

// The registration source was not mapped by the front-end.
const UNMAPPED_REGISTRY_ORIGIN = "Origem não mapeada"

// If you fall into this condition, it means that the origin has not yet been mapped and is not listed anywhere in the application, that is, it is unknown.
const ORIGIN_UNKNOWN = "Desconhecido"

const acceptedRegistrationSourcesToItemText: Record<PossibleContactRegistrationSources, string> = {
	integration: "Integração",
	manual: "Manual",
	webhook: "Webhook(Bot)",
	message_blast: "Envio em Massa",
	"message-received": "Mensagem recebida",
	file_import: "Importação de contatos",
	active_campaign_automation_block: "Automação experiência do cliente (ActiveCampaign)",
	whatsapp: UNMAPPED_REGISTRY_ORIGIN,
	unknown: UNMAPPED_REGISTRY_ORIGIN,
	internal_notification: UNMAPPED_REGISTRY_ORIGIN,
	"sync-groups": UNMAPPED_REGISTRY_ORIGIN,
	active_campaign_webhook: UNMAPPED_REGISTRY_ORIGIN,
	direct_integration_webhook: UNMAPPED_REGISTRY_ORIGIN,
	hotmart_webhook: UNMAPPED_REGISTRY_ORIGIN,
	hotmart_v2_webhook: UNMAPPED_REGISTRY_ORIGIN,
	hubspot_webhook: UNMAPPED_REGISTRY_ORIGIN,
	rd_station_webhook: UNMAPPED_REGISTRY_ORIGIN,
	yampi_webhook: UNMAPPED_REGISTRY_ORIGIN,
	email: UNMAPPED_REGISTRY_ORIGIN
}

const DatesAndIdentifier: React.FC<DatesAndIdentifierProps> = (client) => {
	const classes = useStyles()
	const registeredFrom = acceptedRegistrationSourcesToItemText[client.registeredFrom as PossibleContactRegistrationSources] ?? ORIGIN_UNKNOWN

	return (
		<Grid container direction="column" className={classes.footer}>
			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Número identificador:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.id}
				</Typography>
			</Grid>

			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Origem de cadastro:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{registeredFrom}
				</Typography>
			</Grid>

			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Data de cadastro:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.createdAt ? `${formatDateInBrazilianDate(new Date(client.createdAt))} às ${formatDateInHours(new Date(client.createdAt))}` : ""}
				</Typography>
			</Grid>

			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Data da última atualização:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.updatedAt ? `${formatDateInBrazilianDate(new Date(client.updatedAt))} às ${formatDateInHours(new Date(client.updatedAt))}` : ""}
				</Typography>
			</Grid>

		</Grid>
	)
}

export default DatesAndIdentifier
