import React, { useState } from "react"
import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"
import { Button, Chip, Grid } from "@material-ui/core"
import { ContactsFiltersConditions } from "@/pages/Admin/ClientCatalog/ClientManageFilters/ContactsFilters"
import useStyles from "@/components/ContactAction/styles"

import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import FlowListSelection from "@/components/ContactAction/FlowListSelection"
import useCustomStyles from "@/styles/custom"
import ActionDialog from "@/components/ActionDialog"
import { ChatBotFlowInChatBotTriggerFormattedForList } from "@/services/ChatBotFlow"
import { handleGetSingleContactsFilterCount } from "@/utils/contact"
import ApiService from "@/services/Api"
import { useGlobalStateStore } from "@/store/GlobalState"
import SendToBotDialog from "@/components/ContactAction/SendToBotDialog"
import DisableComponentByChannel from "@/components/DisableComponentByChannel"
import HardCoded from "@/services/HardCoded"

type PossibleUserTexts = {
	selectSendToBot: string
	sendToBot: string
	sendToBotButton: string
}

type ContactActionProps = {
	filterConditions?: ContactsFiltersConditions[]
	hasContactsOnCurrentFilter?: boolean
	contactAction: "single-contact" | "filter"
	client?: {
		clientId: number
		contactId?: number
		name?: string
	}
	hideOpenButton?: boolean
	open?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const ContactAction: React.FC<ContactActionProps> = (props) => {
	const {
		filterConditions,
		hasContactsOnCurrentFilter,
		contactAction,
		client,
		hideOpenButton,
		open,
		setOpen
	} = props

	const [openDialog, setOpenDialog] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedFlow, setSelectedFlow] = useState<ChatBotFlowInChatBotTriggerFormattedForList>()
	const [clientTexts, setClientTexts] = useState<PossibleUserTexts>()
	const [openSendToBotDialog, setOpenSendToBotDialog] = useState(false)

	const canEnableBulkContactActionInWABA = HardCoded.checkFeatureFlag("canUseBulkContactActionInWABAChannel")

	const globalStateStore = useGlobalStateStore()
	const inboxChannelId = globalStateStore.currentChannel?.id

	const contactActionSubscriptionData = useSubscriptionLimits("permission", "bulk_contact_action")
	const isContactActionEnabled = !contactActionSubscriptionData.mustBlock
	const tooltipText = isContactActionEnabled ? "Para adicionar contatos a um bot, é necessário realizar um filtro." : "Essa opção esta disponível apenas para o plano avançado."
	const canAddContactsOnBotByFilter = Boolean(hasContactsOnCurrentFilter && filterConditions && contactAction === "filter")
	const isSingleContactAction = contactAction === "single-contact"
	const shouldDisableHoverListener = (Boolean(canAddContactsOnBotByFilter) || isSingleContactAction)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleCloseDialog = async () => {
		setOpenDialog(false)
		if (setOpen) {
			setOpen(false)
		}
	}

	const addContactsOnBotByFilter = async () => {
		await ApiService.post("/clients/bulk-add-contacts-on-bot", {
			customFilterConditions: filterConditions,
			chatBotFlowId: selectedFlow?.id,
			inboxChannelId
		})
	}

	const addSingleContactOnBot = async () => {
		await ApiService.post("/clients/add-contact-on-bot", {
			chatBotFlowId: selectedFlow?.id,
			inboxChannelId,
			client
		})
	}

	const translateUserActionToReadbleText = async () => {
		let contactOnFilterCount = 0

		if (!isSingleContactAction && filterConditions) {
			contactOnFilterCount = await handleGetSingleContactsFilterCount(filterConditions)
		}

		const formattedContactCountNumber = contactOnFilterCount < 10 ? contactOnFilterCount.toString().padStart(2, "0") : contactOnFilterCount

		const isAddingSingleContactOnFilter = !isSingleContactAction && contactOnFilterCount > 1

		const contactActionToClientTexts: Record<ContactActionProps["contactAction"], PossibleUserTexts> = {
			filter: {
				selectSendToBot: `Selecione para qual bot você quer enviar *${formattedContactCountNumber} contato${isAddingSingleContactOnFilter ? "s" : ""}!*`,
				sendToBot: isAddingSingleContactOnFilter ? `Os *${formattedContactCountNumber} contatos* foram enviados ao bot *${selectedFlow?.name}* com sucesso`
					: `*${formattedContactCountNumber} contato* foi enviado ao bot *${selectedFlow?.name}* com sucesso`,
				sendToBotButton: `Adicionar contato${isAddingSingleContactOnFilter ? "s" : ""} ao bot`
			},
			"single-contact": {
				selectSendToBot: "Selecione para qual bot você quer enviar o *contato!*",
				sendToBot: `O contato *${client?.name}* foi enviado ao bot *${selectedFlow?.name}* com sucesso`,
				sendToBotButton: "Adicionar contato ao bot"

			}
		}

		return contactActionToClientTexts[contactAction]
	}

	const handleOpenDialog = async () => {
		setLoading(true)
		setOpenDialog(true)
		setClientTexts(await translateUserActionToReadbleText())
		setLoading(false)
	}

	const handleSave = async () => {
		const contactActionToAddContactOnBot: Record<ContactActionProps["contactAction"], () => Promise<void>> = {
			filter: async () => await addContactsOnBotByFilter(),
			"single-contact": async () => await addSingleContactOnBot()
		}

		const sendToBotByContactAction = contactActionToAddContactOnBot[contactAction]

		setClientTexts(await translateUserActionToReadbleText())

		if (sendToBotByContactAction) {
			await sendToBotByContactAction()
		}

		handleCloseDialog()
		setOpenSendToBotDialog(true)
	}

	return (
		<>
			{!hideOpenButton && (
				<Grid justifyContent="center">
					{canEnableBulkContactActionInWABA ? (
						<Button
							onClick={handleOpenDialog}
							startIcon={<ChatBotIcon />}
							disabled={!canEnableBulkContactActionInWABA || !(canAddContactsOnBotByFilter || isSingleContactAction)}
							variant="contained"
							className={customClasses.secondaryActionButton}
							style={{ padding: contactAction === "single-contact" ? "16px 30px" : 16 }}
						>
							{contactAction === "single-contact" ? "Adicionar contato a um bot" : "Adicionar contatos a um bot"}
							<Chip
								size="small"
								label="Novo"
								className={classes.newFeatureItem}
							/>
						</Button>
					) : (
						<DisableComponentByChannel
							otherRulesToDisabled={contactActionSubscriptionData.mustBlock || !(canAddContactsOnBotByFilter || isSingleContactAction)}
							variant="hover"
							tooltipConfig={{
								tooltipText: tooltipText,
								tooltipDisableRules: shouldDisableHoverListener
							}}
						>
							<Button
								onClick={handleOpenDialog}
								startIcon={<ChatBotIcon />}
								variant="contained"
								className={customClasses.secondaryActionButton}
								style={{ padding: contactAction === "single-contact" ? "16px 30px" : 16 }}
							>
								{contactAction === "single-contact" ? "Adicionar contato a um bot" : "Adicionar contatos a um bot"}
								<Chip
									size="small"
									label="Novo"
									className={classes.newFeatureItem}
								/>
							</Button>
						</DisableComponentByChannel>
					)}
				</Grid>
			)}

			<ActionDialog
				openDialog={openDialog || open}
				loading={loading}
				title={"Adicionar ao bot"}
				saveText={clientTexts?.sendToBotButton}
				cancelText="Cancelar"
				saveButtonDisabled={Boolean(!selectedFlow?.id)}
				onClose={handleCloseDialog}
				onSave={handleSave}
				maxWidth="md"
				fullWidth
			>
				<FlowListSelection
					setSelectedFlow={setSelectedFlow}
					selectedFlow={selectedFlow}
					selectSendToBot={clientTexts?.selectSendToBot || ""}
				/>
			</ActionDialog >

			<SendToBotDialog
				open={openSendToBotDialog}
				setOpenSendToBotDialog={setOpenSendToBotDialog}
				sendToBotText={clientTexts?.sendToBot || ""} />
		</>
	)
}

export default ContactAction
