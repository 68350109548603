type BlackListedElement = {
	htmlTagNames?: Array<keyof HTMLElementTagNameMap>
	classNames?: string[]
	ids?: string[]
}

type ValidInteractionProps<ElementType> = {
	blackListedElements: BlackListedElement
	onClick?: (event: React.MouseEvent<ElementType>) => void
}

type ValidInteractionResponse<ElementType> = {
	onClick?: (event: React.MouseEvent<ElementType>) => void
}

function isValidInteractionMouseClick<ElementType> (blackListedElements: BlackListedElement, event: React.MouseEvent<ElementType>) {
	const pathElements = event.nativeEvent.composedPath() as Element[]

	const clickedOnInteractionElementInsideParent = pathElements.some(element => (
		blackListedElements?.htmlTagNames?.includes(element.tagName?.toLowerCase() as keyof HTMLElementTagNameMap) ||
		blackListedElements?.ids?.includes(element.id) ||
		blackListedElements?.classNames?.some(className => element.className?.includes?.(className))
	))

	if (clickedOnInteractionElementInsideParent) {
		return false
	} else {
		return true
	}
}

export function getValidInteractionProps<ElementType> (props: ValidInteractionProps<ElementType>): ValidInteractionResponse<ElementType> {
	return {
		onClick: (event: React.MouseEvent<ElementType>) => {
			if (isValidInteractionMouseClick(props.blackListedElements, event)) {
				props?.onClick?.(event)
			}
		}
	}
}

/**
 * Currently, templates are displayed in a list, and users often want to preview the content of
 * the template before selecting it. Without this validation, when clicking to preview the media,
 * the template could be automatically selected, leading to unwanted submission.
 * The goal of the code is to prevent the preview interaction from triggering the template selection,
 * ensuring a clearer and safer experience for the user.
 */
export function isValidInteractionChanges<ElementType> (props: {
	blackListedElements: BlackListedElement,
	event: React.ChangeEvent<ElementType>
}) {
	const {
		event,
		blackListedElements
	} = props

	const pathElements = event.nativeEvent.composedPath() as Element[]

	const changesOnInteractionElementInsideParent = pathElements.some(element => (
		blackListedElements?.htmlTagNames?.includes(element.tagName?.toLowerCase() as keyof HTMLElementTagNameMap) ||
		blackListedElements?.ids?.includes(element.id) ||
		blackListedElements?.classNames?.some(className => element.className?.includes?.(className))
	))

	if (changesOnInteractionElementInsideParent) {
		return false
	} else {
		return true
	}
}
