import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import EditMessageSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditMessageSection"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"

const SendMessageBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	return (
		<BaseBlock
			node={node}
		>
			<EditMessageSection
				onSave={(buildedMessages) => chatBotFlowBlockDetails.changeContent({ messagesToSend: buildedMessages })}
				constructionResources={chatBotFlowConstructorStore.constructionResources}
				messages={chatBotFlowBlockDetails.content?.messagesToSend || []}
				chatBotFlowBlockId={node.data.chatBotFlowBlockId}
			/>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default SendMessageBlock
