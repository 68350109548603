import React, { useState, useEffect } from "react"
import {
	Card,
	Grid,
	Typography,
	IconButton,
	Popper,
	InputBase,
	Box,
	CircularProgress,
	Tooltip
} from "@material-ui/core"

import {
	Autocomplete,
	AutocompleteCloseReason
} from "@material-ui/lab"

import {
	Close as CloseIcon,
	AddCircleOutline as AddIcon,
	Done as DoneIcon
} from "@material-ui/icons"

import {
	Divider,
	Loading,
	Notification,
	SvgIcon
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import ClientTagsSkeleton from "@/skeletons/ClientTags"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import { Color } from "@/protocols/color"
import { Tag } from "@/protocols/tag"
import { ErrorType } from "@/hooks/useValidation"

import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"
import close_icon from "@/assets/icons/close_icon.svg"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientTags/styles"
import { promiseWithTimeout } from "@/utils/time"

type ClientTagsProps = {
	clientId: number
}

type TagDataProps = {
	id: number
	name: string,
	color: Color,
	activeCampaignAssociationIsEnabled?: boolean
}

type ChangedTagAction = "ADD" | "REMOVE"

const ClientTags: React.FC<ClientTagsProps> = (props) => {
	const { clientId } = props

	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const tagOptions = chatGlobalStateStore.tag.listOrderedAlphabetically

	const [loading, setLoading] = useState(false)
	const [clientTags, setClientTags] = useState<TagDataProps[]>([])
	const [selectedTags, setSelectedTags] = useState<TagDataProps[]>([])
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)

	const [changedTags, setChangedTags] = useState<Record<number, ChangedTagAction>>({})
	const [loadingTags, setLoadingTags] = useState<boolean>(false)

	const changeTag = (tagId: number, action: ChangedTagAction) => {
		setChangedTags(lastState => {
			lastState[tagId] = action

			return lastState
		})
	}

	const getClientTags = async () => {
		if (!clientId) {
			return
		}

		try {
			const { data } = await ApiService.get(`/tag-association/client/${clientId}/tags`)
			const clientTags = data.tags as Tag[]
			const clientTagIds = clientTags.map(clientTag => clientTag.id)

			chatGlobalStateStore.client.updateById(clientId, {
				tagIds: clientTagIds || []
			})

			setClientTags(clientTags)

			const currentSelectedTags = chatGlobalStateStore.tag.list.filter(tag => clientTagIds.includes(tag.id))

			setSelectedTags(currentSelectedTags)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const removeTags = async (tagIds: number[]) => {
		await ApiService.delete(`/tag-association/client/${clientId}`, {
			params: {
				tagIds: tagIds.join(",")
			}
		})
	}

	const addTags = async (tagIds: number[]) => {
		await ApiService.post(`/tag-association/client/${clientId}`, {
			tagIds
		})
	}

	const handleOpenAddTagPopper = async (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget)

		setLoadingTags(true)

		await promiseWithTimeout(chatGlobalStateStore.tag.loadAllFromServer, 1000)

		setLoadingTags(false)
	}

	const handleCloseAddTagPopper = async (event: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => {
		try {
			/**
			 * When the search input is clicked
			 */
			if (reason === "toggleInput") {
				return
			}

			if (anchorElement) {
				anchorElement.focus()
			}

			setAnchorElement(null)

			const isThereAnyChange = Object.keys(changedTags).length > 0

			if (!isThereAnyChange) {
				return
			}

			const addedTagIds: number[] = []
			const removedTagIds: number[] = []

			for (const changedTagId in changedTags) {
				const action = changedTags[changedTagId]
				const tagId = Number(changedTagId)

				if (action === "ADD") {
					addedTagIds.push(tagId)
				} else if (action === "REMOVE") {
					removedTagIds.push(tagId)
				}
			}

			if (removedTagIds.length) {
				await removeTags(removedTagIds)
			}

			if (addedTagIds.length) {
				await addTags(addedTagIds)
			}

			await getClientTags()

			setChangedTags({})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Algo deu errado ao atualizar as tags" })
			setLoading(false)
		}
	}

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	const handleRemoveTag = async (tagId: number) => {
		try {
			await removeTags([tagId])
			await getClientTags()
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Ocorreu um erro ao remover a tag" })
		}
	}

	useEffect(() => {
		getClientTags()
		// eslint-disable-next-line
	}, [clientId])

	return (
		<>
			<Grid item >
				<Card className={classes.tagsCard}>
					<Loading loading={loading} customLoadingElement={<ClientTagsSkeleton />}>
						<Grid container alignItems="center" justify="space-between">
							<Grid item xs>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: "15px" }}
								>
									Tags
								</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={handleOpenAddTagPopper} aria-describedby="popper" disabled={loading}>
									<AddIcon />
								</IconButton>
							</Grid>

							<Divider orientation="horizontal" size={0.5} />

							<Grid item xs={12}>
								{
									!loading ? (
										clientTags.map(tag =>
											<Grid container direction="row" xs={12} justifyContent="space-between" key={tag.id} className={classes.tagCard} style={{ background: colorToRGB(tag.color) }}>
												<Grid item xs={10}>
													<Grid container xs={12} direction="row">
														{tag.activeCampaignAssociationIsEnabled && <Grid item xs={1}>
															<Tooltip title={"Essa tag foi adicionada ao contato no Active Campaign."}>
																<Grid item
																	className={classes.pluginFeatureIconCircle}
																	style={{ marginLeft: 6 }}
																>
																	<SvgIcon
																		className={classes.pluginFeatureIcon}
																		icon={ActiveCampaignSVGIcon}
																	/>
																</Grid>
															</Tooltip>
														</Grid>}
														<Grid item xs={tag.activeCampaignAssociationIsEnabled ? 11 : 12}>
															<Grid container justifyContent="space-between">
																<Grid item style={{ paddingLeft: 10 }}>
																	<Tooltip title={tag.name}>
																		<span className={classes.tagName}>{tag.name}</span>
																	</Tooltip>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>

												<Grid item xs={1} style={{ paddingRight: 6 }}>
													<IconButton onClick={() => handleRemoveTag(tag.id)}
														className={classes.closeIcon}
														size="small"
													>
														<img src={close_icon} alt="close icon" />
													</IconButton>
												</Grid>
											</Grid>
										)
									) : (
										<Grid container justify="center">
											<CircularProgress size={30} color="inherit" />
										</Grid>
									)
								}
							</Grid>
						</Grid>
					</Loading>
				</Card>
			</Grid>

			<Popper
				id="popper"
				open={Boolean(anchorElement)}
				anchorEl={anchorElement}
				placement="bottom-start"
				className={classes.popper}
			>
				{loadingTags ? (
					<Grid container justify="center" style={{ padding: "16px" }}>
						<CircularProgress size={30} color="inherit" />
					</Grid>
				) : (
					<Autocomplete
						open
						onClose={handleCloseAddTagPopper}
						multiple
						classes={{
							paper: classes.paper,
							option: classes.option,
							popperDisablePortal: classes.popperDisablePortal
						}}
						value={selectedTags}
						onChange={(event, newValue, reason, details) => {
							/**
							 * This rule prevents tags from being removed when the user presses any button during selection.
							 */
							if (!event.type.includes("keydown")) {
								const tagId = details?.option?.id

								const isAddReason = reason === "select-option"
								const isRemoveReason = reason === "remove-option"

								if (tagId) {
									if (isAddReason) {
										changeTag(tagId, "ADD")
									}

									if (isRemoveReason) {
										changeTag(tagId, "REMOVE")
									}
								}

								setSelectedTags(newValue)
							}
						}}
						disableCloseOnSelect
						disablePortal
						renderTags={() => null}
						noOptionsText="Sem tags cadastradas"
						renderOption={(option, { selected }) => {
							return (
								<>
									<DoneIcon
										className={classes.iconSelected}
										style={{ visibility: selected ? "visible" : "hidden" }}
									/>
									<Box className={classes.color} style={{ backgroundColor: colorToRGB(option.color) }} />
									<Typography className={classes.text}>
										{option.name}
									</Typography>
									<CloseIcon
										className={classes.close}
										style={{ visibility: selected ? "visible" : "hidden" }}
									/>
								</>
							)
						}}
						options={tagOptions}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<InputBase
								ref={params.InputProps.ref}
								inputProps={params.inputProps}
								autoFocus
								className={classes.inputBase}
							/>
						)}
						getOptionSelected={(option, value) => option.id === value.id}
					/>
				)}
			</Popper>
		</>
	)
}

export default ClientTags
