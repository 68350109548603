import React, { useState } from "react"

import useStyles from "@/pages/ActiveCampaignChatQuickViewPage/styles"

import { Button, Grid, Typography } from "@material-ui/core"
import Zoid from "zoid"
import ACExternalConversationPanel from "@/components/ACExternalConversationPanel"
import { Divider, Loading } from "@/components"
import { PossibleChatLoadingErrors, useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"
import useDidMount from "@/hooks/useDidMount"
import useActiveCampaignChatQuickView from "@/hooks/useActiveCampaignChatQuickView"
import SocketService from "@/services/Socket"
import useSocket from "@/hooks/useSocket"
import useActiveWindowListener from "@/hooks/useActiveWindowListener"
import useThrottledSound from "@/hooks/useThrottledSound"
import newMessageSound from "@/assets/sounds/new-message.mp3"
import useWABAChat from "@/@integrations/WABA/hooks/useWABAChat"

const ActiveCampaignChatQuickViewPage: React.FC = () => {
	const classes = useStyles()
	const [isChatOpened, setIsChatOpened] = useState<boolean>(false)
	const [isLoaded, setIsLoaded] = useState<boolean>(false)

	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()
	const activeCampaignChatQuickView = useActiveCampaignChatQuickView()
	const socket = useSocket()
	const wabaChat = useWABAChat()

	const activeWindowListener = useActiveWindowListener()
	const notificationThrottledSound = useThrottledSound(newMessageSound)

	const makeAudioNotification = () => {
		notificationThrottledSound.playSound(3000)
	}

	const getChatOpeningFeedbackText = () => {
		const sucessMessage = "Chat encontrado, clique no botão abaixo para abrir o chat"
		const chatErrorToFeedbackText: Record<PossibleChatLoadingErrors, string> = {
			ChatNotFound: "Chat não encontrado, é necessário iniciar uma conversa com o contato dentro da Letalk",
			ClientNotFound: "Contato não encontrado, é necessário criar um contato na Letalk e iniciar uma conversa",
			GenericError: "Erro ao carregar chat, verifique se o plugin está conectado e configurado corretamente para tentar novamente"
		}

		const errorNames = Object.keys(activeCampaignExternalChatGlobalState.chat.chatLoadingErrors) as PossibleChatLoadingErrors[]
		const errorValues = Object.values(activeCampaignExternalChatGlobalState.chat.chatLoadingErrors) as boolean[]

		const firstErrorIndex = errorValues.indexOf(true)
		const erroredKey = errorNames[firstErrorIndex]

		const chatOpeningFeedbackText = chatErrorToFeedbackText[erroredKey] || sucessMessage
		return chatOpeningFeedbackText
	}

	const isThereAnyErrorOnChat = () => {
		const errorValues = Object.values(activeCampaignExternalChatGlobalState.chat.chatLoadingErrors) as boolean[]

		const firstErrorIndex = errorValues.indexOf(true)

		const isThereAnyError = firstErrorIndex !== -1
		return isThereAnyError
	}

	const setupSocketEvents = async () => {
		socket.onMessageStatusChanged(async updatedMessage => {
			const isEventFromOpenedChat = updatedMessage.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(updatedMessage.id, {
					status: updatedMessage.status,
					...(updatedMessage.status === "sent" && { createdAt: updatedMessage.createdAt }),
					...(Boolean(updatedMessage.content) && { content: updatedMessage.content }),
					...(updatedMessage.error && { inboxChannelChatMessageLog: { error: updatedMessage.error } })
				})
			}
		})

		socket.onMessageDeleted(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					content: "",
					deletedAt: String(new Date())
				})
			}
		})

		socket.onMessageReaction(async reactions => {
			activeCampaignExternalChatGlobalState.message.updateById(reactions.messageId, {
				extraData: {
					reactions: reactions.reactions
				}
			})
		})

		socket.onEditedMessageReceived(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					content: message.content,
					extraData: {
						editedMessage: message.extraData?.editedMessage
					}
				})
			}
		})

		socket.onClientDataChanged(async client => {
			const isActualClient = activeCampaignExternalChatGlobalState.chat.current?.client.id === client.id
			if (isActualClient) {
				activeCampaignExternalChatGlobalState.chat.update({
					client
				})
			}
		})

		socket.onChatAttendanceTaken(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "on-going",
					attendance: {
						userName: attendance.userName,
						userId: attendance.userId,
						assignTeamId: attendance.assignTeamId,
						assignUserId: attendance.assignUserId,
						assignmentQueueType: attendance.assignmentQueueType,
						status: "active"
					}
				})
			}
		})

		socket.onChatAttendanceAssigned(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "queue",
					attendance: {
						userName: attendance.userName,
						userId: attendance.userId,
						assignTeamId: attendance.assignTeamId,
						assignUserId: attendance.assignUserId,
						assignmentQueueType: attendance.assignmentQueueType,
						status: "waiting"
					}
				})
			}
		})

		socket.onChatAttendanceFinished(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "archived",
					attendance: {
						userName: "",
						userId: null,
						assignTeamId: null,
						assignUserId: null,
						assignmentQueueType: null,
						status: "finished"
					}
				})
			}
		})

		socket.onNewChatAttendanceNotification(notification => {
			const isEventFromOpenedChat = notification.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.add(notification)
			}
		})

		socket.onNewMessage(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				await activeCampaignExternalChatGlobalState.message.add(message)

				activeCampaignExternalChatGlobalState.message.setRead()

				const isMessageSentByCustomer = message.sentByCustomer

				const isNotActiveWindowMessageNotification = !isMessageSentByCustomer && !activeWindowListener.active

				const chat = activeCampaignExternalChatGlobalState.chat.current
				const isChatBotAttendance = chat?.status === "chat-bot"
				const notifyByAudio = isNotActiveWindowMessageNotification && !isChatBotAttendance

				if (notifyByAudio) {
					makeAudioNotification()
				}
			}
		})

		socket.onMessageUpdated(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				await activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					type: message?.type,
					content: message?.content,
					caption: message?.caption
				})
			}
		})

		wabaChat.setupChatListeners({
			getChatOnStore: (chatId) => {
				if (chatId === activeCampaignExternalChatGlobalState.chat.current?.id) {
					return activeCampaignExternalChatGlobalState.chat.current
				}
			},
			updateChatOnStore: async (chatId, data) => {
				if (chatId === activeCampaignExternalChatGlobalState.chat.current?.id) {
					await activeCampaignExternalChatGlobalState.chat.update(data)
				}
			}
		})
	}

	const loadAndSetupAllChatData = async () => {
		setIsLoaded(false)
		const quickViewPluginCredentials = activeCampaignExternalChatGlobalState.representantUser.current

		if (quickViewPluginCredentials?.instanceId) {
			await SocketService.setup(quickViewPluginCredentials?.instanceId, quickViewPluginCredentials?.authToken)

			await Promise.all([
				setupSocketEvents(),
				activeCampaignExternalChatGlobalState.chat.loadFromServer()
			])
		} else {
			activeCampaignExternalChatGlobalState.chat.setChatLoadingErrors({
				GenericError: true
			})
		}
		setIsLoaded(true)
	}

	const handleOpenChat = async () => {
		setIsChatOpened(true)
		loadAndSetupAllChatData()
	}

	const initialize = async () => {
		const activeContact = activeCampaignChatQuickView.getActiveContactData()
		const quickViewPluginCredentials = await activeCampaignChatQuickView.getQuickViewPluginCredentials(activeContact.phone)

		activeCampaignExternalChatGlobalState.chat.setChatLoadingErrors(quickViewPluginCredentials.chatLoadingErrors)
		activeCampaignExternalChatGlobalState.representantUser.setup({
			userId: Number(quickViewPluginCredentials?.representantUserId),
			userName: quickViewPluginCredentials?.representantUserName,
			authToken: quickViewPluginCredentials?.representantUserAuthToken,
			instanceId: Number(quickViewPluginCredentials?.instanceId),
			inboxChannelUserChatSettings: quickViewPluginCredentials?.inboxChannelUserChatSettings
		})

		setIsLoaded(true)
	}

	useDidMount(() => {
		Zoid.create({
			url: window.location.href,
			tag: "Inbox Letalk"
		})
		initialize()
	})

	return (
		<Grid
			container
			className={classes.container}
		>
			<Loading loading={!isLoaded}>
				{
					isChatOpened ? (
						<ACExternalConversationPanel></ACExternalConversationPanel>
					) : (
						<Grid
							item
							alignContent="center"
							className={classes.chatCoverPage}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									align="center"
								>
									{ getChatOpeningFeedbackText() }
								</Typography>

								<Divider size={1} orientation="horizontal"/>

								{
									!isThereAnyErrorOnChat() && (
										<Button
											color="primary"
											variant="contained"
											type="submit"
											className={classes.openChatButton}
											onClick={handleOpenChat}
										>
											Abrir chat
										</Button>
									)
								}
							</Grid>
						</Grid>
					)
				}
			</Loading>
		</Grid>
	)
}

export default ActiveCampaignChatQuickViewPage
