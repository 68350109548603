import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { Grid, Tooltip } from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import AIAgentSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AIAgentConversationBlock/AIAgentSectionEditor"
import EditMaxBlockCallingConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AIAgentConversationBlock/EditMaxBlockCallingConditionSection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import BlockBodySectionRequiredCondition, { ContinueConditionIcon } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"

import { getConditionRuleByValidationSlug } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"

const AIAgentConversationBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const {
		changeContent,
		content
	} = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const customClasses = useCustomStyles()

	const aiAgentConversationContext = content?.aiAgentConversation?.context?.text

	const aiWasNotAbleToProcessRequestConditionRule = getConditionRuleByValidationSlug("ai-was-not-able-to-process-request", chatBotFlowBlockDetails.nextChatBotFlowBlockRules)

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<AIAgentSectionEditor
					onSave={changeContent}
					chatBotFlowBlockContent={content}
				>
					<Grid
						container
						direction="column"
					>
						<BlockBodySectionContainer
							text={aiAgentConversationContext ? (
								<Tooltip
									title={aiAgentConversationContext}
									placement="right-end"
								>
									<Grid
										style={{
											width: "100%"
										}}
									>
										<ContrastChip
											title={aiAgentConversationContext}
											backgroundColor={colors.unrelated.B0E3FF}
											className={customClasses.inlineText}
											style={{
												display: "inline-flex"
											}}
										/>
									</Grid>
								</Tooltip>
							) : ""}
							title="Conhecimento:"
							placeholder="Nenhum conhecimento adicionado"
						/>
					</Grid>
				</AIAgentSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				{aiWasNotAbleToProcessRequestConditionRule && (
					<BlockBodySection>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={aiWasNotAbleToProcessRequestConditionRule}
						>
							<BlockBodySectionRequiredCondition
								text="Se o agente não souber a resposta"
								icon={<ContinueConditionIcon />}
							/>
						</BlockBodySectionHandle>
					</BlockBodySection>
				)}

				<EditMaxBlockCallingConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(value) => <span>após {value}</span>}
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default AIAgentConversationBlock
