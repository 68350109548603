import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(1, 3),
		backgroundColor: newColors.grey[50],
		minHeight: 48
	},
	statusMessage: {
		fontSize: 14,
		fontWeight: 400
	},
	pauseDateChip: {
		fontSize: 12,
		backgroundColor: newColors.green[600],
		color: newColors.grey[50]
	},
	alertDateChip: {
		fontSize: 12,
		backgroundColor: newColors.yellow[500],
		color: newColors.grey[50]
	},
	statusIcon: {
		width: 12,
		height: 12,
		borderRadius: "100%",
		borderWidth: "2px",
		borderStyle: "solid",
		backgroundColor: newColors.grey[600],
		borderColor: newColors.grey[400]
	},
	statusIconActive: {
		backgroundColor: newColors.green[600],
		borderColor: newColors.green[400]
	},
	statusIconAlert: {
		backgroundColor: newColors.yellow[500],
		borderColor: newColors.yellow[300]
	}
}))

export default useStyles
