import React, { useState } from "react"
import {
	ActionDialog,
	Divider,
	Notification
} from "@/components"

import {
	Grid,
	Typography,
	Link
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"

import {
	ConstructionResources,
	ShortChatBotTrigger,
	TagOption
} from "@/protocols/chatBotConstructor"

import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"

import useDidMount from "@/hooks/useDidMount"

import { renderComponent } from "@/utils/node"

type TagAssociationEditorDialogProps = {
	saveText?: string
	onSave?: (tag?: TagOption) => void
	onClose?: () => void
	onLoadTags: () => Promise<Partial<ConstructionResources> | null>
	trigger: ShortChatBotTrigger
	tags: TagOption[]
}

type TagAssociationEditorDialogType = {
	open: (props: TagAssociationEditorDialogProps) => void
}

const TagAssociationEditorDialog: TagAssociationEditorDialogType & React.FC<TagAssociationEditorDialogProps> = (props) => {
	const {
		saveText,
		onClose,
		onSave,
		onLoadTags,
		trigger,
		tags
	} = props

	const [opened, setOpened] = useState(true)
	const [saving, setSaving] = useState(false)
	const [selectedTag, setSelectedTag] = useState<TagOption | undefined>(undefined)
	const [tagOptions, setTagOptions] = useState<TagOption[]>(tags)

	const handleClose = () => {
		setOpened(false)

		onClose?.()
	}

	const handleChange = (tag: TagOption | null) => {
		if (tag) {
			setSelectedTag(tag)
		} else {
			setSelectedTag(undefined)
		}
	}

	const handleSave = async () => {
		setSaving(true)

		onSave && onSave(selectedTag)

		setSaving(false)

		handleClose()
	}

	const handleSyncTags = async () => {
		try {
			const resources = await onLoadTags()

			if (resources) {
				setTagOptions(resources?.tagOptions)
			}
		} catch (error) {
			Notification.error({ message: "Houve um problema ao carregar as tags." })
		}
	}

	useDidMount(() => {
		handleSyncTags()
	})

	return (
		<ActionDialog
			title="GATILHO POR TAG"
			openDialog={opened}
			saveText={saveText || "FECHAR"}
			fullWidth={true}
			onSave={handleSave}
			onClose={handleClose}
			loading={saving}
			hideCloseButton={true}
			maxWidth="md"
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					item
					xs
				>
					<Typography
						variant="body1"
						color="textPrimary"
					>
						Quando a tag a seguir for <strong>Associada</strong> ao contato
					</Typography>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					item
					xs
				>
					<Grid
						container
						spacing={1}
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<SelectInput
								defaultOption={trigger.tag}
								onChange={handleChange}
								options={tagOptions}
								placeholder="Selecione a Tag"
								getOptionSelected={(option, value) => option.id === value.id}
								noOptionsText="Sem opções"
								loadingText="Carregando..."
								onUpdate={async () => {
									await handleSyncTags()
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				{tagOptions.length === 0 && (
					<Grid
						item
						xs
					>
						<Alert
							severity="warning"
							style={{ alignItems: "center" }}
						>
							<Grid
								container
							>
								<Typography>
									Não encontramos nenhuma tag criada em sua conta. Crie sua primeira tag {" "}
									<Link
										href={`${window.location.origin}/admin/tags`}
										color="inherit"
										target="_blank"
									>
										aqui.
									</Link>
								</Typography>
							</Grid>
						</Alert>

						<Divider orientation="horizontal" size={2} />
					</Grid>
				)}
			</Grid>
		</ActionDialog>
	)
}

TagAssociationEditorDialog.open = (props: TagAssociationEditorDialogProps) => {
	renderComponent(
		"tag-association-editor-dialog",
		<TagAssociationEditorDialog
			{...props}
		/>
	)
}

export default TagAssociationEditorDialog
