import React from "react"
import { Grid, TextField, Typography } from "@material-ui/core"

import BlockBodySectionEditor, { AsideDrawerEvent } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import UrlValidation from "@/validations/UrlValidation"

import { getWebhookUrl } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import { formatUrl } from "@/utils/mask"

import useValidation from "@/hooks/useValidation"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { JsonEditor } from "@/components"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

type WebhookSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const URL_FIELD_NAME = "url"

const WebhookSectionEditor: React.FC<WebhookSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const { validation, clearAllValidations, addValidation } = useValidation()

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const webhookUrl = getWebhookUrl(contentEditor.content)

	const webhookDispatchBodyExample = chatBotFlowConstructorStore.constructionResources.webhookDispatchBodyExample

	const handleSave = (event: AsideDrawerEvent) => {
		const formattedWebhookUrl = formatUrl(webhookUrl)
		const isValidUrl = UrlValidation.validate(formattedWebhookUrl)

		if (isValidUrl) {
			onSave({
				...contentEditor.content,
				webhook: {
					...contentEditor.content.webhook,
					url: formattedWebhookUrl
				}
			})

			clearAllValidations()
		} else {
			event.preventDrawerClose()

			addValidation({ [URL_FIELD_NAME]: "Url inválida" })
		}
	}

	const handleOpen = () => {
		contentEditor.reset()

		clearAllValidations()
	}

	return (
		<BlockBodySectionEditor
			title="WEBHOOK"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<Grid direction="column" spacing={2} xs={12} container >
						<Grid item>
							<TextField
								variant="outlined"
								placeholder="https://letalk.com.br"
								value={webhookUrl}
								onChange={(event) => (
									contentEditor.changeContent({
										webhook: {
											url: event.target.value,
											method: "POST"
										}
									})
								)}
								helperText={validation[URL_FIELD_NAME]}
								error={Boolean(validation[URL_FIELD_NAME])}
								fullWidth
							/>
						</Grid>

						<>
							<Grid item>
								<Typography
									variant="body1"
									style={{ fontWeight: "bold" }}
								>
									Exemplo do formato de envio:
								</Typography>
							</Grid>

							<Grid item>
								<JsonEditor
									mode="json"
									width="24.25rem"
									height="18.75rem"
									value={webhookDispatchBodyExample}
									readOnly
								/>
							</Grid>
						</>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default WebhookSectionEditor
