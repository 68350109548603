import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import MenuSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalMenuBlock/MenuSectionEditor"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import EditMessageSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditMessageSection"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import InvalidResponseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"

import { getContinueConditionRuleFormattedForCreation, getMessageButtonText } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"

const ConditionalMenuBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	const menuMessage = chatBotFlowBlockDetails.content?.messagesToSend?.[0]

	return (
		<BaseBlock
			node={node}
		>
			<EditMessageSection
				constructionResources={chatBotFlowConstructorStore.constructionResources}
				/**
				 * WARNING:
				 * - Make sure to get always the first message, since 'ConditionalMenu' block only support
				 * a single message.
				 */
				onSave={(buildedMessages) => {
					const buildedMessage = buildedMessages[0]

					chatBotFlowBlockDetails.changeContent({ messagesToSend: [buildedMessage] })
				}}
				messages={menuMessage?.content ? [{
					...menuMessage,
					/**
					 * Workaround to avoid breaking layout when we add menu messages
					 * on 'ChatMessageBuilder'.
					 */
					type: "text"
				}] : []}
				disabledInputs={
					[
						"custom-link",
						"file",
						"voice"
					]
				}
				chatBotFlowBlockId={node.data.chatBotFlowBlockId}
			/>

			<BlockBodySectionGroup>
				{conditionRules?.map(rule => (
					<BlockBodySection
						key={rule.id}
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<MenuSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<BlockBodySectionContainer
									styles={{ width: 220, minHeight: 40, padding: "8px 16px" }}
									text={getMessageButtonText(rule)}
								/>
							</MenuSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<MenuSectionEditor
					hideChildSelection
					onSave={(chatBotFlowBlockRule) => (
						chatBotFlowBlockDetails.changeRule("CREATE", {
							...chatBotFlowBlockRule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null,
							validations: chatBotFlowBlockRule?.validations || []
						})
					)}
					chatBotFlowBlockRule={
						getContinueConditionRuleFormattedForCreation(
							chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules
						)
					}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
					>
						Adicionar menu
					</CreateConditionButton>
				</MenuSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<InvalidResponseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Se nenhuma resposta for válida"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default ConditionalMenuBlock
