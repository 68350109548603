import React, { useEffect, useState } from "react"

import DateFnsUtils from "@date-io/date-fns"
import { ptBR } from "date-fns/locale"

import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import {
	Add as AddIcon,
	Close as CloseIcon
} from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

import { ConditionFields, ContactsFiltersConditions, ContactsFiltersType, FilterOperators } from "@/pages/Admin/ClientCatalog/ClientManageFilters/ContactsFilters"

import { ActionDialog, Divider, Notification, Portlet } from "@/components"

import ApiService from "@/services/Api"
import TagService from "@/services/Tag"

import { useGlobalStateStore } from "@/store/GlobalState"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/pages/Admin/ClientCatalog/ClientManageFilters/CreateOrEditFilters/styles"
import useCustomStyles from "@/styles/custom"

import { Tag } from "@/protocols/tag"
import { PossibleContactRegistrationSources } from "@/protocols/clientCatalog"
import { ImportContactsHistoryType } from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsHistory"

type AcceptedRegistrationSources = Extract<PossibleContactRegistrationSources, "message_blast" | "integration" | "manual" | "webhook" | "file_import" | "message-received" | "active_campaign_automation_block">

interface CreateOrEditContactFilterProps {
	openDialog: boolean,
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
	getDataWithFilters: (filters: { contactsFilters: ContactsFiltersConditions[] }) => Promise<void>
	getContactsFilters?: () => Promise<void>
	selectedFilter: ContactsFiltersType
	setSelectedFilter: React.Dispatch<React.SetStateAction<ContactsFiltersType>>
}

const filtersTypes = [
	{
		name: "contact_register_date",
		label: "Data de Criação"
	},
	{
		name: "contact_update_date",
		label: "Data de Atualização"
	},
	{
		name: "contact_tag",
		label: "Tag"
	},
	{
		name: "contact_last_message_date",
		label: "Data de última mensagem transacionada"
	},
	{
		name: "contact_registered_from",
		label: "Origem de cadastro do contato"
	},
	{
		name: "client_catalog_contacts_created_by_contacts_import",
		label: "Criado por importação de contato específica"
	},
	{
		name: "client_catalog_contacts_updated_by_contacts_import",
		label: "Atualizado por importação de contato específica"
	}
]

const CreateOrEditContactFilter = (props: CreateOrEditContactFilterProps) => {
	const { openDialog, setOpenDialog, getDataWithFilters, selectedFilter, setSelectedFilter, getContactsFilters } = props

	const [filterSelectedToEdit, setFilterSelectedToEdit] = useState<ContactsFiltersType>(selectedFilter)

	const [loading, setLoading] = useState(false)

	const isAnSavedFilter = filterSelectedToEdit.id !== undefined

	const [saveFilterDialog, setSaveFilterDialog] = useState<boolean>(false)

	const [tags, setTags] = useState<Tag[]>([] as Tag[])

	const [importContactsHistoryList, setImportContactsHistoryList] = useState<Pick<ImportContactsHistoryType, "id">[]>([])

	const classes = useStyles()

	const customClasses = useCustomStyles()

	const globalStateStore = useGlobalStateStore()

	const { validation, triggerValidation, clearValidation } = useValidation()

	const getOrUpdateTags = async () => {
		const data = await TagService.getTags()

		if (data) {
			setTags(data)
		}
	}

	/**
	 * MAX_IMPORT_HISTORY_COUNT_TO_FILTER rule should be in the API to avoid user changing this value someway
	 * For that reason, this variable is not passed to the requisition
	 */
	const MAX_IMPORT_HISTORY_COUNT_TO_FILTER = 30
	const handleGetImportContactsHistory = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/clients/contacts/imports-history-to-filter")
			const importContactsHistoryListData = response.data as Pick<ImportContactsHistoryType, "id">[]

			setImportContactsHistoryList(importContactsHistoryListData)
		} catch (error) {
			Notification.error({ message: "Houve um erro ao carregar as importações" })
		}
		setLoading(false)
	}

	const handleSaveOrUpdateFilter = async () => {
		setLoading(true)
		try {
			let filterSaved: ContactsFiltersType | undefined

			if (isAnSavedFilter) {
				await ApiService.put(`/clients/contacts-filters/${filterSelectedToEdit.id}`, {
					...filterSelectedToEdit
				},
				{
					params: {
						inboxChannelId: globalStateStore.currentChannel?.id
					}
				}
				)
			} else {
				const response = await ApiService.post("clients/contacts-filters", {
					...filterSelectedToEdit,
					inboxChannelId: globalStateStore.currentChannel?.id
				})

				filterSaved = response.data?.dataValues
			}

			setSelectedFilter(filterSaved || filterSelectedToEdit)
			setOpenDialog(false)
			setSaveFilterDialog(false)
			getContactsFilters && await getContactsFilters()
		} catch (error) {
			Notification.error({ message: "Houve um erro ao salvar o filtro" })
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleOnInputChange = <Type extends ConditionFields> (conditionIndex: number, conditionField: ConditionFields, value?: ContactsFiltersConditions[Type]) => {
		if (value) {
			const newConditions = [...filterSelectedToEdit.conditions]

			newConditions[conditionIndex][conditionField as Type] = value
			setFilterSelectedToEdit({ ...filterSelectedToEdit, conditions: newConditions })
		}
	}

	const handleOnOperatorSelectChange = (value: FilterOperators | undefined, conditionIndex: number) => {
		const newValue = filterSelectedToEdit.conditions

		newValue[conditionIndex].operator = value

		setFilterSelectedToEdit({ ...filterSelectedToEdit, conditions: newValue })
	}

	const handleOnConditionTypeSelectChange = (type: ContactsFiltersConditions["type"], conditionIndex: number) => {
		const newValue = filterSelectedToEdit.conditions

		newValue[conditionIndex] = {} as ContactsFiltersConditions

		newValue[conditionIndex].type = type

		setFilterSelectedToEdit({ ...filterSelectedToEdit, conditions: newValue })
	}

	const handleAddContactsFilterCondition = () => {
		const newValue: ContactsFiltersConditions[] = [...filterSelectedToEdit.conditions, { type: "contact_register_date", operator: "between" }]

		setFilterSelectedToEdit({ ...filterSelectedToEdit, conditions: newValue })
	}

	const handleRemoveContactsFilterCondition = (conditionIndex: number) => {
		const newValue = filterSelectedToEdit.conditions
		newValue.splice(conditionIndex, 1)

		setFilterSelectedToEdit({ ...filterSelectedToEdit, conditions: newValue })
	}

	const handleUseFilters = async () => {
		setLoading(true)
		setOpenDialog(false)
		try {
			setSelectedFilter(filterSelectedToEdit)
			await getDataWithFilters({ contactsFilters: filterSelectedToEdit.conditions })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao aplicar os filtros" })
		}
		setLoading(false)
	}

	const handleOpenSaveContactsFiltersDialog = () => {
		if (!isAnSavedFilter) {
			setFilterSelectedToEdit({ ...filterSelectedToEdit, title: "" })
		}
		setSaveFilterDialog(true)
	}

	const handleCloseSaveContactsFiltersDialog = () => {
		if (!isAnSavedFilter && !filterSelectedToEdit.title) {
			setFilterSelectedToEdit({ ...filterSelectedToEdit, title: "Filtro Temporário" })
		}
		setSaveFilterDialog(false)
	}

	const selectOperator = (condition: ContactsFiltersConditions, conditionIndex: number, defaultOperator?: FilterOperators) => {
		if (!condition.operator) {
			handleOnOperatorSelectChange(defaultOperator, conditionIndex)
		}

		if (condition.operator === "equal") {
			handleOnOperatorSelectChange(defaultOperator, conditionIndex)
		}

		const defaultDateOperators = [
			{ label: "Entre", value: "between" },
			{ label: "Após", value: "greater_than" },
			{ label: "Antes", value: "less_than" }
		]

		const operatorsOptions: Record<string, Array<{ label: string, value: string }>> = {
			contact_register_date: defaultDateOperators,
			contact_tag: [
				{ label: "Contato contém a tag", value: "in" },
				{ label: "Contato não contém a tag", value: "not_in" }
			],
			contact_last_message_date: defaultDateOperators,
			contact_update_date: defaultDateOperators
		}

		const operators = condition.type ? operatorsOptions[condition.type] : []

		return <FormControl fullWidth variant="outlined" size="small">
			<Select
				value={condition.operator}
				onChange={(event) => {
					handleOnOperatorSelectChange(event.target.value as FilterOperators, conditionIndex)
				}}
				fullWidth
			>
				{
					operators?.length > 0 && operators.map((operator: Record<string, string>) => (
						<MenuItem key={operator.value} value={operator.value}> {operator.label} </MenuItem>
					))
				}
			</Select>
		</FormControl>
	}

	const selectRegisteredFrom = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		if (!condition.operator || condition.operator !== "equal") {
			handleOnOperatorSelectChange("equal", conditionIndex)
		}

		if (!condition.registered_from) {
			handleOnInputChange(conditionIndex, "registered_from", "manual")
		}

		const ACCEPT_REGISTRATION_SOURCES: AcceptedRegistrationSources[] = ["integration", "manual", "message_blast", "webhook", "message-received", "file_import", "active_campaign_automation_block"]
		const acceptedRegistrationSourcesToItemText: Record<AcceptedRegistrationSources, string> = {
			integration: "Integração",
			manual: "Manual",
			webhook: "Webhook(Bot)",
			message_blast: "Envio em Massa",
			"message-received": "Mensagem recebida",
			file_import: "Importação de arquivos",
			active_campaign_automation_block: "Automação experiência do cliente (ActiveCampaign)"
		}

		return <FormControl fullWidth variant="outlined" size="small">
			<Select
				value={condition.registered_from}
				onChange={(event) => {
					handleOnInputChange(conditionIndex, "registered_from", event.target.value as string)
				}}
				fullWidth
			>
				{ACCEPT_REGISTRATION_SOURCES.map((acceptedRegistrationSource, index) => {
					const itemText = acceptedRegistrationSourcesToItemText[acceptedRegistrationSource]

					return <MenuItem value={acceptedRegistrationSource} key={`accepted-registration-source-${index}`}> {itemText} </MenuItem>
				})}
			</Select>
		</FormControl>
	}

	const onlyDateInput = (value: string | undefined, conditionField: ConditionFields, conditionIndex: number) => {
		let valueToDateOrDateNow = new Date()

		if (!value) {
			handleOnInputChange(conditionIndex, conditionField, valueToDateOrDateNow?.toISOString())
		} else {
			valueToDateOrDateNow = new Date(value)
		}

		return <Grid>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
				<DateTimePicker
					ampm={false}
					format="dd/MM/yyyy HH:mm"
					animateYearScrolling
					views={["year", "month", "date", "hours", "minutes"]}
					variant="inline"
					size="small"
					value={valueToDateOrDateNow}
					inputVariant="outlined"
					fullWidth
					onChange={(date) => handleOnInputChange(conditionIndex, conditionField, date?.toISOString())}
					autoOk
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	}

	const contactUpdateDateComponent = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={4}>
				{selectOperator(condition, conditionIndex, "between")}
			</Grid>
			<Grid item xs={4}>
				{onlyDateInput(condition.date_from as string, "date_from", conditionIndex)}
			</Grid>
			{
				condition.operator === "between" && <Grid item xs={4}>
					{onlyDateInput(condition.date_to as string, "date_to", conditionIndex)}
				</Grid>
			}
		</Grid>
	}

	const contactRegisterDateComponent = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={4}>
				{selectOperator(condition, conditionIndex, "between")}
			</Grid>
			<Grid item xs={4}>
				{onlyDateInput(condition.date_from as string, "date_from", conditionIndex)}
			</Grid>
			{
				condition.operator === "between" && <Grid item xs={4}>
					{onlyDateInput(condition.date_to as string, "date_to", conditionIndex)}
				</Grid>
			}
		</Grid>
	}

	const contactWithTagComponent = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={6}>
				{selectOperator(condition, conditionIndex, "in")}
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					id="tags-outlined"
					options={tags}
					value={tags.find((tag) => tag.id === Number(condition.tag_id))}
					getOptionLabel={(option) => option.name}
					limitTags={1}
					fullWidth
					onChange={(target, value) => handleOnInputChange(conditionIndex, "tag_id", value?.id)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder="Tags"
						/>
					)}
					size="small"
					onOpen={getOrUpdateTags}
				/>
			</Grid>
		</Grid>
	}

	const contactLastMessageDateComponent = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={4}>
				{selectOperator(condition, conditionIndex, "between")}
			</Grid>
			<Grid item xs={4}>
				{onlyDateInput(condition.date_from as string, "date_from", conditionIndex)}
			</Grid>
			{
				condition.operator === "between" && <Grid item xs={4}>
					{onlyDateInput(condition.date_to as string, "date_to", conditionIndex)}
				</Grid>
			}
		</Grid>
	}

	const contactRegisteredFromComponent = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={12}>
				{selectRegisteredFrom(condition, conditionIndex)}
			</Grid>
		</Grid>
	}

	const selectContactFromImports = (condition: ContactsFiltersConditions, conditionIndex: number, importContactStatus: "created" | "updated") => {
		let conditionField: ConditionFields = "" as ConditionFields

		if (importContactStatus === "created") {
			conditionField = "client_catalog_contacts_created_by_contacts_import_id"
			if (!condition.client_catalog_contacts_created_by_contacts_import_id) {
				handleOnInputChange(conditionIndex, "client_catalog_contacts_created_by_contacts_import_id", importContactsHistoryList?.[0]?.id)
			}
		} else {
			conditionField = "client_catalog_contacts_updated_by_contacts_import_id"
			if (!condition.client_catalog_contacts_updated_by_contacts_import_id) {
				handleOnInputChange(conditionIndex, "client_catalog_contacts_updated_by_contacts_import_id", importContactsHistoryList?.[0]?.id)
			}
		}

		return <Grid container alignItems="center" direction="row" spacing={1} xs={8}>
			<Grid item xs={12}>
				<Autocomplete
					id={`${conditionField}-outlined`}
					options={importContactsHistoryList}
					value={importContactsHistoryList?.find((importContactsHistory) => importContactsHistory.id === condition[conditionField])}
					getOptionLabel={(option) => `Importação #${option.id}`}
					limitTags={1}
					fullWidth
					onChange={(target, value) => handleOnInputChange(conditionIndex, conditionField, value?.id)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder="Importações"
						/>
					)}
					size="small"
					onOpen={handleGetImportContactsHistory}
				/>
			</Grid>
		</Grid>
	}

	const contactCreatedFromImport = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return (
			<>
				<Grid container alignItems="center" direction="row" spacing={1} xs={8}>
					<Grid item xs={12}>
						{selectContactFromImports(condition, conditionIndex, "created")}
					</Grid>
				</Grid>
				<Grid container direction="row" xs={8}>
					<Grid
						xs={12}
						className={classes.conditionHelpText}
					>
						<Typography
							variant="subtitle2"
						>
							*Apenas {MAX_IMPORT_HISTORY_COUNT_TO_FILTER} últimas importações
						</Typography>
					</Grid>
				</Grid>
			</>
		)
	}

	const contactUpdatedFromImport = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		return (
			<>
				<Grid container alignItems="center" direction="row" spacing={1} xs={8}>
					<Grid item xs={12}>
						{selectContactFromImports(condition, conditionIndex, "updated")}
					</Grid>
				</Grid>
				<Grid container direction="row" xs={8}>
					<Grid
						xs={12}
						className={classes.conditionHelpText}
					>
						<Typography
							variant="subtitle2"
						>
							*Apenas {MAX_IMPORT_HISTORY_COUNT_TO_FILTER} últimas importações
						</Typography>
					</Grid>
				</Grid>
			</>
		)
	}

	const renderComponentToFilter = (condition: ContactsFiltersConditions, conditionIndex: number) => {
		if (condition.type === "contact_register_date") {
			return contactRegisterDateComponent(condition, conditionIndex)
		} else if (condition.type === "contact_tag") {
			return contactWithTagComponent(condition, conditionIndex)
		} else if (condition.type === "contact_last_message_date") {
			return contactLastMessageDateComponent(condition, conditionIndex)
		} else if (condition.type === "contact_registered_from") {
			return contactRegisteredFromComponent(condition, conditionIndex)
		} else if (condition.type === "contact_update_date") {
			return contactUpdateDateComponent(condition, conditionIndex)
		} else if (condition.type === "client_catalog_contacts_created_by_contacts_import") {
			return contactCreatedFromImport(condition, conditionIndex)
		} else if (condition.type === "client_catalog_contacts_updated_by_contacts_import") {
			return contactUpdatedFromImport(condition, conditionIndex)
		}
	}

	useEffect(() => {
		setFilterSelectedToEdit(selectedFilter)
	}, [selectedFilter])

	useDidMount(async () => {
		await getOrUpdateTags()
	})

	return <ActionDialog
		openDialog={openDialog}
		maxWidth="md"
		fullWidth
		title={isAnSavedFilter ? "EDITAR FILTRO: " + filterSelectedToEdit.title : "FILTROS DE DADOS"}
		saveText={isAnSavedFilter ? "SALVAR" : "FILTRAR"}
		hideCloseButton={true}
		onClose={() => setOpenDialog(false)}
		loading={loading}
		onSave={!isAnSavedFilter ? handleUseFilters : handleOpenSaveContactsFiltersDialog}
		onSecondaryAction={!isAnSavedFilter ? handleOpenSaveContactsFiltersDialog : undefined}
		secondaryActionText={isAnSavedFilter ? "SALVAR" : "SALVAR ESSE FILTRO"}
	>
		<Grid container style={{ height: "450px", overflow: "auto" }} spacing={2} alignContent="flex-start">
			<Grid item xs={12}>
				<Portlet>
					<Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
						<Grid item>
							<Typography>
								Filtrar contatos que se encaixam em
							</Typography>
						</Grid>
						<Grid item>
							<Select
								style={{ height: "40px" }}
								value={filterSelectedToEdit.conditions && filterSelectedToEdit?.conditions[filterSelectedToEdit?.conditions.findIndex((condition) => condition.type === "main_operator")]?.value}
								variant="outlined"
								onChange={(event) => {
									const mainOperatorIndex = filterSelectedToEdit?.conditions.findIndex((condition) => condition.type === "main_operator")
									handleOnInputChange(mainOperatorIndex, "value", event.target.value as string)
								}}
							>
								<MenuItem value="and"><b> Todas as </b></MenuItem>
								<MenuItem value="or"><b> Pelo menos uma das </b></MenuItem>
							</Select>
						</Grid>
						<Grid item>
							<Typography>
								condições seguintes:
							</Typography>
						</Grid>
					</Grid>

				</Portlet>
			</Grid>
			{filterSelectedToEdit.conditions && filterSelectedToEdit.conditions.map((condition, index) => {
				if (condition.type !== "main_operator") {
					return <Grid key={index} item xs={12}>
						<Portlet>
							{
								filterSelectedToEdit.conditions.length > 2 && <IconButton
									size="small" className={classes.closeIcon}
									onClick={() => handleRemoveContactsFilterCondition(index)}
								>
									<CloseIcon style={{ fontSize: "13px" }} />
								</IconButton>
							}

							<Divider orientation="horizontal" size={2} />

							<Grid container direction="row" spacing={2}>
								<Grid item xs={4}>
									<FormControl fullWidth variant="outlined" size="small">
										<Select
											value={condition.type}
											fullWidth
											onChange={(event) => {
												handleOnConditionTypeSelectChange(event.target.value as ContactsFiltersConditions["type"], index)
											}}
										>
											{filtersTypes.map((type) => (
												<MenuItem key={type.name} value={type.name}> <b> {type.label} </b> </MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								{renderComponentToFilter(condition, index)}
							</Grid>

						</Portlet>
					</Grid>
				}
				return <div key={index}></div>
			})}

			<Divider orientation="horizontal" size={1} />

			<Grid container direction="row" alignItems="center" justifyContent="flex-start">
				<Divider orientation="vertical" size={1} />
				<Grid item>
					<Button
						size="medium"
						variant="contained"
						className={customClasses.buttonAction}
						endIcon={<AddIcon />}
						onClick={() => handleAddContactsFilterCondition()}
					>
						ADICIONAR CONDIÇÃO
					</Button>
					<Divider orientation="horizontal" size={1} />
				</Grid>
			</Grid>
		</Grid>
		<ActionDialog
			title="SALVAR FILTRO"
			openDialog={saveFilterDialog}
			onSave={handleSaveOrUpdateFilter}
			onClose={handleCloseSaveContactsFiltersDialog}
			loading={loading}
			maxWidth="sm"
			fullWidth={true}
		>
			<Grid xs={12}>
				<Grid
					item
					xs
				>
					<InputLabel
						className={customClasses.inputLabel}
					>
						Título do Filtro
					</InputLabel>

					<Divider orientation="horizontal" size={1} />

					<TextField
						value={filterSelectedToEdit.title}
						onChange={({ target }) => {
							setFilterSelectedToEdit({ ...filterSelectedToEdit, title: target.value })
							clearValidation("title")
						}}
						variant="outlined"
						placeholder="Título"
						helperText={validation.title}
						error={!!validation.title}
						fullWidth
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	</ActionDialog>
}

export default CreateOrEditContactFilter
