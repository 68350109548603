import React from "react"

import { Divider, MessageContentBox } from "@/components"

import {
	Divider as MuiDivider,
	Grid
} from "@material-ui/core"

import useStyles from "@/components/ChatMessageBuilder/MessageItem/BaseMessage/styles"

import { getMessageColor } from "@/utils/message"

type MessageItemProps = {
	className?: string
	style?: React.CSSProperties
	childContent: React.ReactElement
	childContentFooter?: React.ReactElement
	childContainerFooter?: React.ReactElement
	childContentHeader?: React.ReactElement
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
	const {
		className,
		style,
		childContent,
		childContentFooter,
		childContainerFooter,
		childContentHeader
	} = props

	const classes = useStyles()

	return (
		<>
			<Grid
				container
				direction="column"
				alignItems="flex-start"
			>
				<MessageContentBox
					className={`${classes.messageContent} ${className}`}
					style={{
						backgroundColor: getMessageColor(true),
						...(style || {})
					}}
				>
					{childContentHeader && (
						<>
							{childContentHeader}

							<Divider orientation="horizontal" size={0.6} />

							<MuiDivider />

							<Divider orientation="horizontal" size={0.6} />
						</>
					)

					}

					{childContent}

					{childContentFooter && (
						<>
							<Divider orientation="horizontal" size={0.6} />

							<MuiDivider />

							<Divider orientation="horizontal" size={0.6} />

							{childContentFooter}
						</>
					)}
				</MessageContentBox>

				{childContainerFooter && (
					<Grid
						style={{
							width: "100%"
						}}
					>
						<Divider orientation="horizontal" size={0.5} />

						{childContainerFooter}
					</Grid>
				)}
			</Grid>
		</>
	)
}

export default MessageItem
