import React from "react"

import { Box, Button, Typography } from "@material-ui/core"

import ConfettiExplosion from "react-confetti-explosion"
import useStyles from "@/components/UpsellDialog/UpsellHandlerDialog/UpsellSuccessfullyCompleted/styles"

type UpsellSuccessfullyCompletedProps = {
	onClose: () => void
	upsellDescription: string
	upsellQuantity: number
}

const UpsellSuccessfullyCompleted: React.FC<UpsellSuccessfullyCompletedProps> = (props) => {
	const classes = useStyles()
	const { onClose, upsellDescription, upsellQuantity } = props

	return (
		<Box className={classes.boxContainer}>
			<Box>
				<Typography variant="h3" className={classes.title}>
					Contratação concluída com sucesso! 🎉
				</Typography>
				<Typography variant="h6" className={classes.subTitle}>
					A contratação foi adicionada com sucesso ao seu plano! Agora você conta com {upsellQuantity.toLocaleString("pt-BR")} {upsellDescription}.
				</Typography>
				<Typography variant="body1" className={classes.description}>
					Essa é uma vantagem que elevará a qualidade e eficiência do seu atendimento! Sinta-se à vontade para explorar esse recurso e continuar aprimorando seus resultados. Aproveite essa funcionalidade ao máximo!
				</Typography>
			</Box>

			<ConfettiExplosion className={classes.confettiExplosion} zIndex={9999999} />

			<Button variant="contained" onClick={onClose} className={classes.button} color="primary">
				Entendido! 👍
			</Button>
		</Box>
	)
}

export default UpsellSuccessfullyCompleted
