import React, { ComponentType, useEffect, useState } from "react"
import ApiService from "@/services/Api"
import { Divider, Loading, Notification } from "@/components"
import { Chip, Grid, Typography } from "@material-ui/core"
import { ActiveCampaignPipelineResponse, ActiveCampaignDeal } from "@/protocols/ActiveCampaignApiProtocol"
import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"
import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import AfterActionConditionSection
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import {
	getCurrentDeal
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import colors from "@/styles/colors"
import DealOptionsSectionEditor
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterDealInActiveCampaignBlock/DealOptionsSectionEditor"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import PluginStatusReport from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/PluginStatusReport"

const RegisterDealInActiveCampaignBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const isPreview = chatBotFlowConstructorStore.isPreview
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })
	const currentDeal = getCurrentDeal(chatBotFlowBlockDetails.content)

	const [pipelines, setPipelines] = useState<Partial<ActiveCampaignPipelineResponse>>({
		dealGroups: [],
		dealStages: []
	})

	const [loading, setLoading] = useState<boolean>(false)

	const {
		validatePlugin,
		getValidationContent,
		validateDealRegistration,
		getIsValidPlugin
	} = useActiveCampaignApi()

	const pluginStatusContent = getValidationContent()
	const isValidPlugin = getIsValidPlugin()

	const getPipelinesActiveCampaign = async () => {
		if (isValidPlugin) {
			try {
				const response = await ApiService.get("/plugin-settings/active-campaign/pipelines")
				const responseData = response.data as ActiveCampaignPipelineResponse
				setPipelines(responseData)
				validateDealRegistration(responseData, currentDeal as ActiveCampaignDeal)
			} catch (error) {
				Notification.error({ message: "Não foi possível carregar os funis de vendas." })
			}
		}
	}

	const handleGetDealData = async () => {
		setLoading(true)
		await validatePlugin()

		await getPipelinesActiveCampaign()
		setLoading(false)
	}

	const getOptionLabel = () => {
		if (currentDeal) {
			return (
				<Grid container alignContent="center" justifyContent="center">
					<Grid item xs={12} container justifyContent="flex-start">
						<Typography>
							Pipeline:
						</Typography>

						<Divider orientation="vertical" size={2} />

						<Chip
							label={`${currentDeal?.pipeline.title}`}
							size="small"
							style={{
								backgroundColor: colors.palette.tag2,
								borderRadius: "8px",
								color: colors.grayScale[11],
								fontWeight: "bold"
							}}
						/>
					</Grid>

					<Divider orientation="horizontal" size={2} />

					<Grid item xs={12} container justifyContent="flex-start">
						<Typography>
							Stage:
						</Typography>

						<Divider orientation="vertical" size={2} />

						<Chip
							label={`${currentDeal?.stage.title}`}
							size="small"
							style={{
								backgroundColor: colors.palette.tag2,
								borderRadius: "8px",
								color: colors.grayScale[11],
								fontWeight: "bold"
							}}
						/>
					</Grid>
				</Grid>
			)
		} else {
			return ""
		}
	}

	useEffect(() => {
		handleGetDealData()
	}, [isValidPlugin, currentDeal?.pipeline.title, currentDeal?.stage.title])

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				{ !isPreview && (
					<PluginStatusReport
						pluginStatusContent={pluginStatusContent}
					/>
				)}

				<DealOptionsSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
					pipelines={pipelines}
					getPipelinesActiveCampaign={getPipelinesActiveCampaign}
				>
					<Loading loading={loading} >
						<BlockBodySectionContainer
							text={getOptionLabel() || ""}
							placeholder="Selecione um funil de vendas e uma etapa"
							title="Escolha um Funil de Vendas e uma Etapa"
							chatBotFlowBlockId={node?.data?.chatBotFlowBlockId}
							errorType="active_campaign_block_missing_deal"
						/>
					</Loading>
				</DealOptionsSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default RegisterDealInActiveCampaignBlock
