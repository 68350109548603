import React from "react"

import { IMessageExtraData, MessageType } from "@/protocols/channel"

import BaseMessage from "@/components/ChatMessageBuilder/MessageItem/BaseMessage"

import { Messages } from "@/components"

type MessageItemProps = {
	type: MessageType
	content?: string
	mediaName?: string
	className?: string
	style?: React.CSSProperties,
	isDefaultSize?: boolean
	extraData?: IMessageExtraData
	copySpecial?: boolean
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
	const {
		type,
		content,
		mediaName,
		className,
		style,
		extraData,
		isDefaultSize,
		copySpecial = true
	} = props

	return (
		<BaseMessage
			className={className}
			style={style}
			childContent={(
				Messages.getChildContent(type, {
					fileName: type === "file" ? (mediaName as string) : "",
					copySpecial,
					translateVariables: true,
					content: type === "text" ? (content || "O conteúdo da mensagem aparecerá aqui...") : (content || ""),
					extraData
				})
			)}
			childContentHeader={(
				Messages.getChildContentHeader({
					extraData,
					fileName: type === "file" ? (mediaName as string) : "",
					translateVariables: true,
					fancyBoxSlug: String(extraData?.header?.content)
				})
			)}
			childContentFooter={
				Messages.getChildContentFooter(type, {
					extraData
				})
			}
			childContainerFooter={(
				Messages.getChildContainerFooter({
					extraData: extraData,
					sentByCustomer: true,
					status: "delivered",
					isDefaultSize
				})
			)}
		/>
	)
}

export default MessageItem
