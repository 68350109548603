import React from "react"
import { Position, useReactFlow, NodeProps } from "reactflow"
import { Grid, Tooltip } from "@material-ui/core"
import {
	Delete as DeleteIcon
} from "@material-ui/icons"

import { PopConfirm } from "@/components"

import BlockBody from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBody"

import { isBlockConnectedFromOutside } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useBlockBodyItemHandleStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle/styles"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock/styles"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import ActiveFlowReport from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ActiveFlowReport"
import DuplicateBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/DuplicateBlock"
import colors from "@/styles/colors"
import FlowBlockStateProvider from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/FlowBlockState"

type BaseBlockProps = {
	node: NodeProps<BlockNodeData>
}

const BaseBlock: React.FC<BaseBlockProps> = ({ node, children }) => {
	const { flowBlock, chatBotFlowBlockId } = node.data

	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId })
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const { deleteElements } = useReactFlow()

	const classes = useStyles({ config: chatBotFlowBlockDetails.blockThemeConfig })
	const blockBodyItemHandleClasses = useBlockBodyItemHandleStyles()
	const chatBotFlowBlockNote = chatBotFlowConstructorStore?.chatBotNotes?.chat_bot_flow_block_notes?.find(note => note.chat_bot_flow_block_id === chatBotFlowBlockId)

	const handleDeleteBlock = () => {
		PopConfirm.open({
			title: "EXCLUIR BLOCO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => deleteElements({ nodes: [node] }),
			confirmButtonText: "EXCLUIR"
		})
	}

	return (
		<FlowBlockStateProvider
			nodeId={node.id}
		>
			<Grid
				className={classes.blockContainer}
			>
				<Grid
					className={classes.blockSelection}
				>
					<BlockBody
						title={flowBlock.title}
						icon={chatBotFlowBlockDetails.blockThemeConfig?.icon?.Component}
						IconProps={{
							style: {
								...(chatBotFlowBlockDetails.blockThemeConfig?.icon?.style || {}),
								color: chatBotFlowBlockDetails.blockThemeConfig?.color?.blockIcon
							}
						}}
						HeaderProps={{
							style: {
								backgroundColor: chatBotFlowBlockDetails.blockThemeConfig?.color.blockHeaderBackground
							}
						}}
						HandleProps={{
							id: String(chatBotFlowBlockId),
							type: "target",
							position: Position.Left,
							className: `${blockBodyItemHandleClasses.handle} ${classes.handle} ${chatBotFlowConstructorStore.hasBlockError(String(chatBotFlowBlockId), "block_without_mandatory_connection") ? classes.animation : null}`,
							isConnectable: false,
							style: {
								opacity: isBlockConnectedFromOutside(chatBotFlowBlockId, chatBotFlowConstructorStore.flowData) ? 0 : 1
							}
						}}
						ChatbotNoteProps={{
							note: {
								id: chatBotFlowBlockNote?.id,
								chat_bot_flow_id: chatBotFlowConstructorStore.flowData.id,
								chat_bot_flow_block_id: chatBotFlowBlockId,
								content: chatBotFlowBlockNote?.content
							},
							onCreateOrEditCallback: async (note) => {
								const chatBotFlowBlockNotes = chatBotFlowConstructorStore.chatBotNotes?.chat_bot_flow_block_notes?.filter(note => note.chat_bot_flow_block_id !== chatBotFlowBlockNote?.chat_bot_flow_block_id) || []

								if (note) {
									chatBotFlowBlockNotes.push(note)
								}

								chatBotFlowConstructorStore?.setChatBotNotes?.({
									...chatBotFlowConstructorStore.chatBotNotes,
									...{
										chat_bot_flow_block_notes: [
											...chatBotFlowBlockNotes
										]
									}
								})
							},
							onDeleteCallback: async () => {
								const chatBotFlowBlockNotes = chatBotFlowConstructorStore.chatBotNotes?.chat_bot_flow_block_notes?.filter(note => note.chat_bot_flow_block_id !== chatBotFlowBlockNote?.chat_bot_flow_block_id) || []

								chatBotFlowConstructorStore?.setChatBotNotes?.({
									...chatBotFlowConstructorStore.chatBotNotes,
									...{
										chat_bot_flow_block_notes: [
											...chatBotFlowBlockNotes
										]
									}
								})
							},
							noteIconColor: chatBotFlowBlockDetails.blockThemeConfig?.color?.blockIcon
						}}
						disableChatbotNoteButton={chatBotFlowConstructorStore.getChatBotFlowBlockById(chatBotFlowBlockId)?.status === "CREATED"}
					>
						{children}
					</BlockBody>
				</Grid>

				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.optionsContainer}
				>
					<Grid item className={classes.optionButton}>
						<DuplicateBlock
							flowBlock={{
								...flowBlock,
								default_content: chatBotFlowBlockDetails.content,
								default_next_block_flow_rules: chatBotFlowBlockDetails.nextChatBotFlowBlockRules
							}}
							positionBlock={{
								xPos: node.xPos,
								yPos: node.yPos
							}}
							buttonClassName={classes.optionButton}
						/>
					</Grid>

					<Grid item className={classes.optionButton} onClick={handleDeleteBlock}>
						<Tooltip
							title={"Excluir bloco"}
						>
							<DeleteIcon fontSize="small" style={{ color: colors.grayScale[11] }} />
						</Tooltip>
					</Grid>

					<Grid item>
						<ActiveFlowReport
							activeInboxChannelChatFlows={chatBotFlowConstructorStore.flowData.active_inbox_channel_chat_flows || []}
							changeChatBotFlow={chatBotFlowConstructorStore.changeChatBotFlow}
							buttonClassName={classes.optionButton}
							nextChatBotFlowBlockId={chatBotFlowBlockId}
							chatBotFlowId={chatBotFlowConstructorStore.flowData.id}
							tooltipTitle="Contatos que estão nesse bloco atualmente"
							buttonTitle="Excluir todos contatos do bloco"
						/>
					</Grid>
				</Grid>
			</Grid>
		</FlowBlockStateProvider>
	)
}

export default BaseBlock
