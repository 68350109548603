import React from "react"
import { Button, Grid, Tooltip } from "@material-ui/core"

import {
	Reply,
	Phone,
	Launch,
	FileCopy
} from "@material-ui/icons"

import Divider from "@/components/Divider"

import { getInboxMessageColor } from "@/utils/message"
import { isLastItem } from "@/utils/array"

import {
	IMessageExtraData,
	MessageStatus,
	ButtonMessageData
} from "@/protocols/channel"

import useStyles, { BUTTON_MESSAGE_WIDTH } from "@/components/Messages/ButtonMessage/styles"

type ButtonMessageProps = {
	extraData: IMessageExtraData
	sentByCustomer: boolean
	isDefaultSize?: boolean
	status: MessageStatus
}

type ButtonItemProps = {
	text: string
	sentByCustomer: boolean
	style?: React.CSSProperties
	status: MessageStatus
	type: ButtonMessageData["type"]
}

const ButtonItem: React.FC<ButtonItemProps> = (props) => {
	const {
		sentByCustomer,
		text,
		style,
		status,
		type
	} = props

	const classes = useStyles()

	const getButtonIcon = (buttonType?: ButtonMessageData["type"]): JSX.Element | null => {
		const buttonTypeToButtonIcon: Record<ButtonMessageData["type"], JSX.Element | null> = {
			"quick-reply": <Reply />,
			phone: <Phone />,
			url: <Launch />,
			"copy-code": <FileCopy />,
			unknown: null
		}

		if (!buttonType) {
			return null
		}

		return buttonTypeToButtonIcon[buttonType]
	}

	return (
		<Tooltip
			title="Somente seu destinatário pode clicar no botão."
		>
			<Grid>
				<Button
					variant="contained"
					fullWidth
					className={classes.button}
					style={{
						backgroundColor: getInboxMessageColor(sentByCustomer, status),
						...style
					}}
					classes={{
						label: classes.buttonText
					}}
					startIcon={getButtonIcon(type)}
				>
					{text}
				</Button>
			</Grid>
		</Tooltip>
	)
}

export const ButtonMessageContainerFooter: React.FC<ButtonMessageProps> = (props) => {
	const {
		extraData,
		sentByCustomer,
		status,
		isDefaultSize = true
	} = props

	const buttons = extraData?.button?.buttons
	const totalButtons = buttons?.length as number

	const canShowDualButton = () => {
		const DUAL_BUTTON_AMOUNT = 2
		const MAX_WORD_SIZE_PER_BUTTON = 25

		const isExpectedDualButton = totalButtons === DUAL_BUTTON_AMOUNT

		const firstButtonWordSize = buttons?.[0]?.text?.length as number
		const secondButtonWordSize = buttons?.[1]?.text?.length as number

		const isFirstButtonWordOversized = firstButtonWordSize > MAX_WORD_SIZE_PER_BUTTON
		const isSecondButtonWordOversized = secondButtonWordSize > MAX_WORD_SIZE_PER_BUTTON

		return (
			isExpectedDualButton &&
			!isFirstButtonWordOversized &&
			!isSecondButtonWordOversized
		)
	}

	if (canShowDualButton()) {
		return (
			<Grid
				container
				style={{
					width: "100%",
					...(isDefaultSize && { maxWidth: BUTTON_MESSAGE_WIDTH })
				}}
				justify="space-between"
			>
				{buttons?.map((button, index) => (
					<Grid
						key={index}
						item
						style={{
							...(isDefaultSize && { width: (BUTTON_MESSAGE_WIDTH / totalButtons) - 2 })
						}}
					>
						<ButtonItem
							sentByCustomer={sentByCustomer}
							text={button.text}
							key={button.id}
							status={status}
							type={button?.type}
						/>
					</Grid>
				))}
			</Grid>
		)
	} else {
		return (
			<>
				{buttons?.map((button, index) => (
					<>
						<ButtonItem
							sentByCustomer={sentByCustomer}
							text={button.text}
							key={button.id}
							style={{
								...(isDefaultSize && { width: BUTTON_MESSAGE_WIDTH })
							}}
							status={status}
							type={button?.type}
						/>

						{!isLastItem(index, totalButtons) && (
							<Divider orientation="horizontal" size={0.5} />
						)}
					</>
				))}
			</>
		)
	}
}

export const ButtonMessageContentBoxStyle: React.CSSProperties = {
	width: BUTTON_MESSAGE_WIDTH
}
