import React from "react"
import {
	Grid,
	Button,
	CircularProgress,
	IconButton
} from "@material-ui/core"

import {
	Divider,
	Notification,
	ScrolledBottomList
} from "@/components"

import { getChildProps } from "@/components/ScrolledBottomList"

import { ExpandMore as BackListToBottom } from "@material-ui/icons"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/styles"
import useCustomStyles from "@/styles/custom"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"

import MediaUploadTrap from "@/pages/Attendance/Chat/ConversationPanel/MediaUploadTrap"
import NotificationMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/NotificationMessage"
import CommonMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/CommonMessage"
import { useGlobalStateStore } from "@/store/GlobalState"

const MessageList: React.FC = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()
	const isScrollPastBeginning = chatGlobalStateStore.conversationPanel.scrolledBottomList.scrollPastBeginning.isPast
	const isWabaChannel = globalStateStore.instance.current_channel_type === "waba"

	const handleLoadEarlierMessages = async () => {
		if (chatGlobalStateStore.chat.current) {
			await chatGlobalStateStore.message.loadEarlierMessagesByChatId(chatGlobalStateStore.chat.current.id)
		}
	}

	const handleScroll = (maxScrollSize: number, currentScroll: number) => {
		/**
		 * We only enable scroll to bottom button to show if its past the beggining of the chat
		 */
		chatGlobalStateStore.conversationPanel.scrolledBottomList.scrollPastBeginning.change(maxScrollSize, currentScroll)
	}

	const handleScheduleChannelMessagesLoad = async () => {
		if (!chatGlobalStateStore.chat.current) {
			return
		}

		const { channelType, id, inboxChannelId } = chatGlobalStateStore.chat.current

		chatGlobalStateStore.chat.updateById(id, { syncingMessages: true })

		const result = await socket.scheduleChatMessagesLoad({
			channelType,
			inboxChannelChatId: id,
			inboxChannelId
		})

		const chatStatus = result?.chatStatus

		if (chatStatus) {
			chatGlobalStateStore.chat.updateById(id, {
				finishedSyncingMessages: chatStatus.areAllMessagesLoaded
			})
		}

		if (result) {
			if (chatStatus?.areAllMessagesLoaded) {
				Notification.success({
					message: "Todas As mensagens mais antigas foram carregadas!"
				})
			} else {
				Notification.success({
					message: "As mensagens mais antigas foram carregadas!"
				})
			}
		} else {
			Notification.error({
				message: "Houve um erro inesperado ao tentar carregar as mensagens mais antigas, tente novamente mais tarde."
			})
		}

		chatGlobalStateStore.chat.updateById(id, { syncingMessages: false })
	}

	return useCustomMemo(() => (
		<MediaUploadTrap
			onMedia={(medias) => chatGlobalStateStore.conversationPanel.media.add(medias)}
		>
			<Grid
				direction="row"
			>
				<Grid
					container
					direction="column"
					wrap="nowrap"
					className={`${classes.chatMessagesContainer} ${customClasses.scrollBar}`}
				>
					{chatGlobalStateStore.chat.current && !chatGlobalStateStore.chat.current?.finishedSyncingMessages && (
						<Grid
							container
							justify="center"
							alignItems="center"
						>
							<Divider size={1} orientation="horizontal" />

							{
								!isWabaChannel && (
									(chatGlobalStateStore.chat.current?.fullyLoadedAllEarlierMessages === false || chatGlobalStateStore.chat.current?.syncingMessages === true) ? (
										<CircularProgress />
									) : (
										<Button
											variant="contained"
											onClick={handleScheduleChannelMessagesLoad}
											className={classes.loadMoreMessagesButton}
										>
											CARREGAR MENSAGENS MAIS ANTIGAS
										</Button>
									)
								)
							}
							<Divider size={2} orientation="horizontal" />
						</Grid>
					)}

					<ScrolledBottomList
						ref={chatGlobalStateStore.conversationPanel.scrolledBottomList.ref}
						onListTopReached={handleLoadEarlierMessages}
						onScroll={handleScroll}
						listTopReachedOffset={128}
					>
						{chatGlobalStateStore.message.current
							.map(message => {
								const isNotificationMessage = message?.type?.includes("notification")

								return (
									<Grid
										key={message.id}
										{...(getChildProps(message.id))}
									>
										{isNotificationMessage ? (
											<NotificationMessage
												messageId={message.id}
											/>
										) : (
											<CommonMessage
												messageId={message.id}
											/>
										)}
									</Grid>
								)
							})}
					</ScrolledBottomList>
				</Grid>
				{
					Boolean(chatGlobalStateStore.chat.currentOpenedId) &&
					isScrollPastBeginning &&
					<Grid
						className={classes.scrollButtonArea}
					>
						<IconButton
							className={classes.roundedIconButton}
							onClick={chatGlobalStateStore.conversationPanel.scrolledBottomList.ref.current?.forceScrollBottom}
						>
							<BackListToBottom
								fontSize="large"
							/>
						</IconButton>
					</Grid>
				}
			</Grid>
		</MediaUploadTrap>
	), [
		chatGlobalStateStore.conversationPanel.scrolledBottomList.scrollPastBeginning.isPast,
		chatGlobalStateStore.message.current.length,
		chatGlobalStateStore.chat.current?.id,
		chatGlobalStateStore.message.currentHash,
		chatGlobalStateStore.chat.current?.fullyLoadedAllEarlierMessages,
		chatGlobalStateStore.chat.current?.syncingMessages
	])
}

export default MessageList
