import React, { ComponentType } from "react"
import clsx from "clsx"
import { Grid, Typography } from "@material-ui/core"
import { NodeProps, Position, useReactFlow } from "reactflow"

import { PlayArrow as TriggerNodeIcon } from "@material-ui/icons"
import {
	ReactComponent as ClickIcon
} from "@/assets/icons/click.svg"

import BlockBody from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBody"
import { SvgIcon } from "@/components"

import useBlockBodyItemHandleStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle/styles"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import TriggerItem from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem"
import TriggerCreator from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerCreator"

import { getNodeByDefaultType } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowNode"

import { TriggerNodeData } from "@/protocols/chatBotFlow"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/styles"

const TriggerNode: ComponentType<NodeProps<TriggerNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const chatBotTriggers = chatBotFlowConstructorStore.flowData?.chat_bot_triggers?.filter(({ status }) => status !== "DELETED") || []

	const blockBodyItemHandleClasses = useBlockBodyItemHandleStyles()
	const classes = useStyles()
	const { getNodes } = useReactFlow()

	const emptyNode = getNodeByDefaultType("emptyNode", getNodes())

	const isTriggerEmpty = chatBotTriggers.length === 0
	const isTriggerConnectedToEmptyNode = Boolean(emptyNode)
	const isTriggerConnectedToBlock = Boolean(chatBotFlowConstructorStore.flowData?.initial_chat_bot_flow_block_id)
	const isTriggerConnected = isTriggerConnectedToBlock || isTriggerConnectedToEmptyNode

	const handleCreateTrigger = () => {
		TriggerCreator.open({
			constructionResources: chatBotFlowConstructorStore.constructionResources,
			onSave: (flowTrigger) => chatBotFlowConstructorStore.changeChatBotFlowTrigger("CREATE", flowTrigger),
			loadConstructionResources: chatBotFlowConstructorStore.loadConstructionResources,
			loadSpecificConstructionResources: chatBotFlowConstructorStore.loadSpecificConstructionResources
		})
	}

	const hasMandatoryConnectionError = !isTriggerConnectedToBlock && chatBotFlowConstructorStore.hasBlockError(node.data.handleId, "block_without_mandatory_connection")

	return (
		<BlockBody
			title={isTriggerEmpty ? "Iniciar" : "Gatilho para acionar o bot"}
			icon={<TriggerNodeIcon />}
			hideChatbotNoteButton={true}
			IconProps={{
				style: {
					color: colors.palette.primary
				}
			}}
			HeaderProps={{
				style: {
					backgroundColor: colors.unrelated.F9F3FD,
					position: "relative"
				}
			}}
			HandleProps={{
				id: node.data.handleId,
				type: "source",
				position: Position.Right,
				isConnectable: !isTriggerConnected,
				className: clsx({
					[blockBodyItemHandleClasses.handle]: true,
					[blockBodyItemHandleClasses.connected]: isTriggerConnected,
					[blockBodyItemHandleClasses.animation]: hasMandatoryConnectionError
				})
			}}
		>
			<Grid
				container
				className={classes.container}
			>
				{isTriggerEmpty ? (
					<Grid
						container
						alignItems="center"
						justify="center"
						direction="column"
						className={classes.content}
						onClick={handleCreateTrigger}
					>
						<SvgIcon
							autoSize
							icon={ClickIcon}
							className={classes.icon}
						/>

						<Typography
							variant="h2"
							color="textPrimary"
							align="center"
							className={classes.text}
						>
							Adicione um gatilho para acionar o fluxo do bot
						</Typography>
					</Grid>
				) : (
					<>
						{chatBotTriggers.map(trigger => (
							<TriggerItem
								key={trigger.id}
								trigger={trigger}
								changeChatBotFlowTrigger={chatBotFlowConstructorStore.changeChatBotFlowTrigger}
								constructionResources={chatBotFlowConstructorStore.constructionResources}
								loadSpecificConstructionResources={chatBotFlowConstructorStore.loadSpecificConstructionResources}
							/>
						))}
					</>
				)}
			</Grid>
		</BlockBody>
	)
}

export default TriggerNode
