import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
const useStyles = makeStyles({
	smallGrayFooter: {
		fontSize: 10,
		color: colors.grayScale[5]
	},
	smallGrayFooterDataType: {
		fontSize: 10,
		color: colors.unrelated["818285"],
		fontWeight: 600
	},
	footer: {
		padding: "0px 32px",
		height: 75,
		display: "flex",
		flexDirection: "column",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	footerInfoSize: {
		height: 12
	}
})

export default useStyles
