import React, { useEffect, useState } from "react"

import { ActionDialog, Loading } from "@/components"
import { Grid, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { formatDateInBrazilianDate, translatedDaysOfWeek } from "@/utils/time"
import { Link } from "react-router-dom"
import { useGlobalStateStore } from "@/store/GlobalState"

import ErrorHandler from "@/services/ErrorHandler"

import InboxChannelSettings, { SendMessagesTimeLimitsValueType } from "@/services/InboxChannelSettings"
import ConfirmMessageBlastScheduleOutsideChannelSettingsHoursSkeleton from "@/components/ScheduleDialog/ConfirmMessageBlastScheduleOutsideChannelSettingsHoursDialog/ConfirmMessageBlastScheduleOutsideOfChannelConfigurationHoursSkeleton"
import { ErrorType } from "@/hooks/useValidation"

type ConfirmMessageBlastScheduleOutsideChannelSettingsHoursDialogProps = {
	isDialogOpen: boolean
	onSave: () => void
	onClose: () => void
	time: Date
	contactCount: number
	messageCount: number
}

const ConfirmMessageBlastScheduleOutsideChannelSettingsHoursDialog: React.FC<ConfirmMessageBlastScheduleOutsideChannelSettingsHoursDialogProps> = (props) => {
	const [messageSendingConfigurationInformation, setMessageSendingConfigurationInformation] = useState<SendMessagesTimeLimitsValueType | null>(null)
	const [errorGetSendMessagesTimeLimitSettings, setErrorGetSendMessagesTimeLimitSettings] = useState(false)
	const [loadingGetSendMessagesTimeLimitSettings, setLoadingGetSendMessagesTimeLimitSettings] = useState(false)

	const globalStateStore = useGlobalStateStore()
	const inboxChannelId = globalStateStore.currentChannel?.id as number

	const linkToChannelSettings = `/admin/settings/inbox-channel/${inboxChannelId}`
	const weekDay = translatedDaysOfWeek[messageSendingConfigurationInformation?.day_code ?? 0]
	const channelSetupStartTime = messageSendingConfigurationInformation?.from_hour
	const channelSetupEndTime = messageSendingConfigurationInformation?.to_hour

	useEffect(() => {
		const fetchSendMessagesTimeLimitSettings = async () => {
			setLoadingGetSendMessagesTimeLimitSettings(true)
			try {
				const dayCodeOfTheScheduleDate = new Date(props.time).getDay()
				const data: { value: SendMessagesTimeLimitsValueType[] } = await InboxChannelSettings.get("send_messages_time_limits", String(inboxChannelId))

				const scheduleDayConfiguration = data.value.find((day) => day.day_code === dayCodeOfTheScheduleDate) as SendMessagesTimeLimitsValueType
				setMessageSendingConfigurationInformation(scheduleDayConfiguration)
				setLoadingGetSendMessagesTimeLimitSettings(false)
			} catch (error) {
				setErrorGetSendMessagesTimeLimitSettings(true)
				setLoadingGetSendMessagesTimeLimitSettings(false)
				ErrorHandler.handle(error as ErrorType)
			}
		}

		if (props.isDialogOpen) {
			fetchSendMessagesTimeLimitSettings()
		}
	}, [props.isDialogOpen, inboxChannelId])

	return (
		<ActionDialog
			onClose={() => {
				props.onClose()
			}}
			onSave={() => {
				props.onSave()
				props.onClose()
			}}
			saveText="CONFIRMAR ENVIO"
			cancelText="CANCELAR"
			openDialog={props.isDialogOpen}
			title="Agendamento fora dos limites de configuração do canal"
			saveButtonDisabled={loadingGetSendMessagesTimeLimitSettings}
			maxWidth="sm"
		>
			<Loading loading={loadingGetSendMessagesTimeLimitSettings} customLoadingElement={<ConfirmMessageBlastScheduleOutsideChannelSettingsHoursSkeleton />}>
				<Typography variant="body1">
					Você está tentando agendar o envio de uma mensagem fora do horário permitido para este canal. {!errorGetSendMessagesTimeLimitSettings ? (
						<Typography component="span" variant="body1">
							O horário permitido para <strong>{weekDay}</strong> é das <strong>{channelSetupStartTime}</strong> às <strong>{channelSetupEndTime}</strong>.
						</Typography>
					) : null}
				</Typography>

				<Alert icon={false} severity="warning" style={{ marginTop: 12 }}>
					<Typography variant="body1">
						A mensagem será programada para o próximo dia disponível, de acordo com suas configurações e dentro dos horários estipulados. Caso queira alterar as configurações do canal, a nova configuração entrará em vigor no próximo agendamento. Para fazer isso, basta <Link to={linkToChannelSettings}>clicar aqui</Link>.
					</Typography>
				</Alert>
				<Grid container direction="column" spacing={1}>
					<Grid item>
						<Typography variant="body1" style={{ marginTop: 24 }}>
							Quantidade de contatos: {props.contactCount}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1">
							Quantidade de mensagens: {props.messageCount}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1">
							Data do agendamento: {formatDateInBrazilianDate(props.time)}
						</Typography>
					</Grid>
				</Grid>
			</Loading>
		</ActionDialog>
	)
}

export default ConfirmMessageBlastScheduleOutsideChannelSettingsHoursDialog
