import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	search: {
		height: 50,
		"& .MuiOutlinedInput-root": {
			height: 50,
			"& input": {
				padding: "8px 14px"
			}
		}
	},
	select: {
		height: 50,
		minWidth: 180
	},
	syncButton: {
		width: 50,
		height: 50
	},
	noTemplateTitle: {
		color: colors.grayScale[4],
		fontSize: "32px",
		lineHeight: "48px"
	},
	noTemplateText: {
		color: colors.unrelated["818285"],
		fontSize: "20px"
	},
	noTemplateImage: {
		maxWidth: "60%"
	}
})

export default useStyles
