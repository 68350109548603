import React, { useState } from "react"
import { Button, CircularProgress, FormControl, FormHelperText, Grid } from "@material-ui/core"

import AceEditor, { IAceEditorProps } from "react-ace"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-textmate"

import useValidation from "@/hooks/useValidation"
import { Overwrite } from "@/protocols/overwrite"

type JsonEditorProps = Overwrite<IAceEditorProps, {
	value?: Record<string | number, unknown> | null
	onChange?: (newJson: string) => void
	onSave?: (jsonData: string) => Promise<void>
	helperText?: string
	error?: boolean
}>

const INPUT_NAME = "json-editor"

const JsonEditor: React.FC<JsonEditorProps> = (props) => {
	const { value, onChange, onSave, helperText, error, ...rest } = props

	const { addValidation, clearValidation, validation } = useValidation()

	const stringifyValue = JSON.stringify(value, null, 2)

	const [jsonData, setJsonData] = useState(stringifyValue)
	const [loadingSaveJson, setLoadingSaveJson] = useState<boolean>(false)

	const currentError: boolean = Boolean(validation[INPUT_NAME]) ?? error
	const currentHelperText: string = validation[INPUT_NAME] ?? helperText

	const handleChange = (newValue: string) => {
		clearValidation(INPUT_NAME)

		setJsonData(newValue)

		onChange && onChange(newValue)
	}

	const handleSave = async () => {
		setLoadingSaveJson(true)

		if (onSave) {
			try {
				const parseJsonData = JSON.parse(jsonData)

				await onSave(parseJsonData)
			} catch (error) {
				addValidation({ [INPUT_NAME]: "Algo está errado. Revise os dados inseridos" })
			}
		}

		setLoadingSaveJson(false)
	}

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
			>
				<FormControl
					error={currentError}
					fullWidth
				>
					<AceEditor
						{...rest}
						name={INPUT_NAME}
						value={jsonData}
						wrapEnabled={true}
						mode="json"
						theme="textmate"
						onChange={handleChange}
						fontSize={16}
						showPrintMargin={true}
						showGutter={true}
						highlightActiveLine={true}
						editorProps={{ $blockScrolling: true }}
						setOptions={{
							showLineNumbers: true,
							tabSize: 2
						}}
					/>
				</FormControl>
				{currentHelperText && (
					<FormHelperText
						error={currentError}
					>
						{currentHelperText}
					</FormHelperText>
				)}
			</Grid>

			{onSave && (
				<Grid
					item
				>
					<Grid
						container
						justify="flex-end"
					>
						<Grid
							item
						>
							<Button
								onClick={handleSave}
								color="primary"
								variant="contained"
								disableElevation
								disabled={loadingSaveJson}
								endIcon={loadingSaveJson && <CircularProgress size={20} color="inherit" />}
							>
								Salvar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default JsonEditor
