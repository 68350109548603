import ApiService from "@/services/Api"
import { DetailedWABAChannelMessageTemplate, WABAChannelMessageTemplate } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import { TemplatesFiltersData, TemplatesWhereData } from "@/pages/Admin/Templates"
import { ManageTemplate } from "@/pages/Admin/Templates/ManageTemplateForm"

class WABAChannelMessageTemplateService {
	async syncAllTemplates (channelId?: number): Promise<void> {
		await ApiService.get(`/waba/channel/${channelId}/message/template/sync-all`)
	}

	async retrieveAllByInboxChannelId (inboxChannelId?: number): Promise<WABAChannelMessageTemplate[]> {
		const response = await ApiService.get<{ wabaChannelMessageTemplates: WABAChannelMessageTemplate[] }>(`/waba/channel/${inboxChannelId}/message/template`)

		return response.data.wabaChannelMessageTemplates
	}

	async retrieveAllDetailedByInboxChannelId (params: TemplatesWhereData & TemplatesFiltersData, inboxChannelId?: number): Promise<{rows: DetailedWABAChannelMessageTemplate[], count: number}> {
		const response = await ApiService.get(`/waba/channel/${inboxChannelId}/message/template/detailed`, {
			params
		})

		return response.data
	}

	async createMessageTemplate (data: ManageTemplate, inboxChannelId?: number): Promise<void> {
		await ApiService.post(`/waba/channel/${inboxChannelId}/message/template`, { ...data })
	}

	async editMessageTemplate (data: ManageTemplate, inboxChannelId?: number): Promise<void> {
		await ApiService.put(`/waba/channel/${inboxChannelId}/message/template`, { ...data })
	}
}

export default new WABAChannelMessageTemplateService()
