import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { BLOCK_SECTION_BORDER_RADIUS } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
		minHeight: theme.spacing(6),
		borderRadius: BLOCK_SECTION_BORDER_RADIUS,
		backgroundColor: colors.unrelated.F6FAFD
	},
	shortcutNameText: {
		color: colors.grayScale[4],
		fontWeight: "bold",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	templateStatusChip: {
		cursor: "pointer",
		height: 20
	},
	title: {
		color: colors.unrelated.A1AAB3
	},
	listContainer: {
		backgroundColor: colors.unrelated.F6F6F6,
		overflowY: "scroll",
		height: "100%",
		maxHeight: "calc(100vh - 128px)",
		paddingTop: 0
	},
	listItem: {
		paddingBottom: theme.spacing(2),
		outline: "none"
	},
	templateName: {
		width: "100%",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		display: "inline-block"
	}
}))

export default useStyles
