import React, { useMemo } from "react"
import clsx from "clsx"
import { Grid, ButtonBase, DialogActions, Button } from "@material-ui/core"

import { PopConfirm, Portal } from "@/components"
import { PopConfirmProps } from "@/components/PopConfirm"

import AsideDrawer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AsideDrawer"
import DeleteButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/DeleteButton"

import { getValidInteractionProps } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/nativeEvent"
import { timeout } from "@/utils/time"

import useGlobalItemSelection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useGlobalItemSelection"
import useConstantId from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConstantId"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor/styles"
import useCustomStyles from "@/styles/custom"
import useActionDialogStyles from "@/components/ActionDialog/styles"

import { FLOW_EDITOR_CONTAINER_ID, BLOCK_BODY_SECTION_EDITOR_ID } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"
import { BLOCK_BODY_SECTION_EDITOR_WIDTH } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

export type AsideDrawerEvent = {
	closable: boolean
	/**
	 * AsideDrawer automatically closes when save button
	 * is pressed. In case you want to prevent that action, call this method.
	 */
	preventDrawerClose: () => void
}

type BlockBodySectionEditorProps = {
	title: string
	hideChildSelection?: boolean
	hideSaveButton?: boolean
	hideCancelButton?: boolean
	disabled?: boolean
	cancelText?: string
	saveText?: string
	AsideDrawerProps?: {
		children?: React.ReactElement
		width?: string
	}
	DeletePopConfirmProps?: Omit<PopConfirmProps, "onConfirm">
	onClose?: () => void
	onOpen?: () => void
	onSave?: (event: AsideDrawerEvent) => void
	onDelete?: () => void
}

const BlockBodySectionEditor: React.FC<BlockBodySectionEditorProps> = (props) => {
	const {
		title,
		children,
		AsideDrawerProps,
		DeletePopConfirmProps,
		onClose,
		onSave,
		onDelete,
		onOpen,
		cancelText,
		saveText,
		disabled,
		hideChildSelection,
		hideSaveButton,
		hideCancelButton
	} = props

	const blockSectionId = useConstantId()
	const flowBlockSectionEditorSelection = useGlobalItemSelection("flow-editor-right-drawer")
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const actionDialogClasses = useActionDialogStyles()

	const isBeingEdited = flowBlockSectionEditorSelection.isSelected(blockSectionId)
	const flowEditorContainer = useMemo(() => document.getElementById(FLOW_EDITOR_CONTAINER_ID), []) as Element

	const handleOpen = () => {
		flowBlockSectionEditorSelection.clearSelection()
		flowBlockSectionEditorSelection.toggleSelected(blockSectionId)

		onOpen?.()
	}

	const handleClose = () => {
		flowBlockSectionEditorSelection.clearSelection()

		onClose?.()
	}

	const buildAsideDrawerEvent = (): AsideDrawerEvent => ({
		closable: true,
		preventDrawerClose: function () {
			this.closable = false
		}
	})

	const handleSave = () => {
		const asideDrawerEvent = buildAsideDrawerEvent()

		onSave?.(asideDrawerEvent)

		if (asideDrawerEvent.closable) {
			handleClose()
		}
	}

	const handleDeleteWithCloseAnimation = async () => {
		handleClose()

		/**
		 * Wait for close animation to run after calling 'handleClose'.
		 */
		await timeout(100)

		onDelete?.()
	}

	const handleDelete = async () => {
		if (DeletePopConfirmProps) {
			PopConfirm.open({
				...DeletePopConfirmProps,
				onConfirm: handleDeleteWithCloseAnimation
			})
		} else {
			handleDeleteWithCloseAnimation()
		}
	}

	return (
		<>
			<Grid
				item
				component={ButtonBase}
				disabled={disabled}
				className={clsx({
					[classes.section]: true,
					[classes.sectionBeingEdited]: isBeingEdited && !hideChildSelection
				})}
				{...getValidInteractionProps({
					blackListedElements: {
						htmlTagNames: ["a"]
					},
					onClick: handleOpen
				})}
			>
				{children}
			</Grid>

			<Portal
				container={flowEditorContainer}
			>
				<AsideDrawer
					id={BLOCK_BODY_SECTION_EDITOR_ID}
					opened={isBeingEdited}
					onClose={handleClose}
					title={title}
					anchor="right"
					width={AsideDrawerProps?.width || BLOCK_BODY_SECTION_EDITOR_WIDTH}
				>
					<Grid
						container
						direction="column"
						justifyContent="space-between"
					>
						<Grid>
							{AsideDrawerProps?.children}
						</Grid>

						<Grid>
							<DialogActions
								className={actionDialogClasses.dialogActions}
							>
								<Grid
									className={classes.dialogFooter}
									container
									alignItems="center"
									justify="flex-start"
								>
									{onDelete && (
										<DeleteButton
											onClick={handleDelete}
										/>
									)}
								</Grid>

								{!hideCancelButton && (
									<Button
										onClick={handleClose}
										className={clsx({
											[customClasses.cancelButton]: true,
											[actionDialogClasses.button]: true
										})}
									>
										{cancelText || "CANCELAR"}
									</Button>
								)}

								{!hideSaveButton && (
									<Button
										onClick={handleSave}
										variant="contained"
										color="primary"
										className={actionDialogClasses.button}
									>
										{saveText || "SALVAR"}
									</Button>
								)}
							</DialogActions>
						</Grid>
					</Grid>
				</AsideDrawer>
			</Portal>
		</>
	)
}

export default BlockBodySectionEditor
