import React, { useState } from "react"
import { Link as LinkRouter } from "react-router-dom"

import {
	Button,
	Grid,
	Typography,
	Chip,
	Tooltip
} from "@material-ui/core"
import {
	Forum as ChatIcon,
	Wifi as ConnectedIcon,
	WhatsApp as WhatsAppIcon,
	Settings as SettingsIcon
} from "@material-ui/icons"

import { Alert } from "@material-ui/lab"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"
import {
	IChannel,
	WhatsappChannelStatus
} from "@/protocols/channel"
import { WhatsappComputedStatus } from "@/@integrations/Whatsapp/protocols/channel"

import { deviceIsMobile } from "@/utils/checkDevice"
import { formatPhoneNumber } from "@/utils/mask"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import {
	getWhatsappComputedStatus,
	getWhatsappStatusColor,
	getWhatsappStatusIcon,
	getWhatsappStatusHelperMessage
} from "@/@integrations/Whatsapp/utils/channel"

import {
	Divider,
	Portlet,
	PopConfirm
} from "@/components"

import useDidMount from "@/hooks/useDidMount"
import useBreakpoint from "@/hooks/useBreakpoint"
import { useGlobalStateStore } from "@/store/GlobalState"
import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"

import SendPhoneToWebhookDialog
	from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SendPhoneToWebhookDialog"

import StorageService from "@/services/Storage"

import colors from "@/styles/colors"
import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SessionCardItem/styles"

export type SessionCardItemProps = ConnectionFlowComponentDefaultProps & {
	inboxChannel: IChannel
}

export type SendWebhookContactData = {
	phone: string,
}

const SessionCardItem: React.FC<SessionCardItemProps> = (props) => {
	const {
		resetSteps,
		inboxChannel
	} = props

	const globalStateStore = useGlobalStateStore()
	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const classes = useStyles()
	const whatsappConnection = useWhatsappConnection()
	const isSmall = useBreakpoint({ type: "down", breakpoint: "sm" })
	const [contactData, setContactData] = useState<SendWebhookContactData>({} as SendWebhookContactData)
	const whatsappChannelStatus = inboxChannel.status as WhatsappChannelStatus
	const handleReset = () => {
		const props = {
			title: "Deseja encerrar sessão?",
			confirmButtonText: "ENCERRAR",
			onConfirm: () => resetSteps()
		}
		PopConfirm.open(props)
	}

	const reset = () => {
		resetSteps()
	}

	const getStatusChip = () => {
		const statusColor = getWhatsappStatusColor(whatsappChannelStatus)
		const statusIcon = getWhatsappStatusIcon(whatsappChannelStatus)

		const statusTagMap: Record<WhatsappComputedStatus, React.ReactNode> = {
			connected: (
				<Chip
					id="conected-status-chip"
					icon={statusIcon}
					className={classes.statusChip}
					label="Conectado"
					style={{ backgroundColor: statusColor }}
				/>
			),
			disconnected: (
				<Chip
					id="disconected-status-chip"
					icon={statusIcon}
					className={classes.statusChip}
					label="Desconectado"
					style={{ backgroundColor: statusColor }}
				/>
			),
			reconnecting: (
				<Chip
					icon={statusIcon}
					className={classes.statusChip}
					label="Reconectando..."
					style={{ backgroundColor: statusColor }}
				/>
			)
		}

		const computedStatus = getWhatsappComputedStatus(whatsappChannelStatus)

		return statusTagMap[computedStatus]
	}

	const getStatusMessageInfo = () => {
		const statusMessageInfoMap: Record<WhatsappComputedStatus, React.ReactNode> = {
			connected: getWhatsappStatusHelperMessage(whatsappChannelStatus),
			disconnected: (
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className={classes.statusMessageContainer}
					style={{ backgroundColor: colors.unrelated.EFC7CB }}
				>
					<Typography
						variant="body1"
						align="center"
						className={classes.statusMessageText}
					>
						{getWhatsappStatusHelperMessage(whatsappChannelStatus)}
					</Typography>
				</Grid>
			),
			reconnecting: (
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className={classes.statusMessageContainer}
					style={{ backgroundColor: colors.unrelated.F9D3B7 }}
				>
					<Typography
						variant="body1"
						align="center"
						className={classes.statusMessageText}
					>
						{getWhatsappStatusHelperMessage(inboxChannel.status, handleReset)}
					</Typography>
				</Grid>
			)
		}

		const computedStatus = getWhatsappComputedStatus(whatsappChannelStatus)

		return statusMessageInfoMap[computedStatus]
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onWhatsappLoggedOut(() => {
			resetSteps()
		})
	}

	const formatDate = (dateInString: string | undefined) => {
		if (!dateInString) {
			return ""
		}

		const date = new Date(dateInString)

		const formattedDate = `${formatDateInBrazilianDate(date)} às ${formatDateInHours(date)}`

		return formattedDate
	}
	const instanceId = StorageService.get(StorageService.reservedKeys.INBOX_INSTANCE_ID) as string
	const isAbleGenerateQRCode = Number(instanceId) >= 0 && Number(instanceId) <= 8000

	useDidMount(() => {
		addWhatsappEventListeners()
	})

	const statusColor = getWhatsappStatusColor(whatsappChannelStatus)
	const statusChip = getStatusChip()
	const statusMessageInfo = getStatusMessageInfo()
	const computedStatus = getWhatsappComputedStatus(whatsappChannelStatus)
	const phoneNumber = inboxChannel?.extraData?.user?.phoneNumber
	const channel = globalStateStore?.channels?.find(channel => channel.id === Number(globalStateStore?.whatsappChannel?.id))
	const userHasChannelWithFiveMinutesUnpaired = channel?.extraData?.hasChannelWithFiveMinutesUnpaired

	return (
		<>
			{!isAbleGenerateQRCode && <Alert severity="error" icon={false}>
				Estamos enfrentando uma instabilidade, mas estamos confiantes de que resolveremos esse problema o mais rápido possível. Agradecemos sua compreensão e pedimos que tente novamente em breve.
			</Alert>}
			<Divider orientation="horizontal" size={2} />
			<Portlet
				style={{
					borderLeft: `8px solid ${statusColor}`,
					backgroundColor: colors.grayScale[11],
					padding: 16
				}}
			>
				<Grid
					container
					direction="column"
				>
					<Grid
						container
						alignContent="center"
						alignItems="center"
						justifyContent="space-between"
					>
						<Grid
							container
							direction="column"
							className={classes.equalSpacedContainer}
						>
							<Grid
								container
								alignItems="center"
							>
								<Grid
									container
									alignItems="center"
									justifyContent="center"
									className={classes.whatsappIconContainer}
									style={{
										backgroundColor: computedStatus === "connected" ? colors.unrelated["5ABE66"] : colors.grayScale[3]
									}}
								>
									<WhatsAppIcon
										className={classes.whatsappIcon}
									/>
								</Grid>

								<Divider orientation="vertical" size={2} />

								<Typography
									variant="body1"
									className={classes.phoneNumberText}
								>
									{formatPhoneNumber(phoneNumber)}
								</Typography>
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid
								container
								direction="row"
							>
								<Grid
									item
								>
									<Typography
										variant="caption"
										color="textPrimary"
										style={{
											...(computedStatus === "disconnected" && { color: statusColor })
										}}
									>
										{computedStatus === "disconnected" ? (
											`Sem sessão desde ${formatDate(inboxChannel?.syncControlData?.last_authentication_revoked_date)}`
										) : (
											`Sessão ativa desde ${formatDate(inboxChannel?.syncControlData?.last_successful_connection_date)} |`
										)}
									</Typography>
								</Grid>
								<Divider orientation="vertical" size={0.5} />
								<Grid
									item
								>
									{(computedStatus !== "disconnected" && !userHasChannelWithFiveMinutesUnpaired) && (
										<Typography
											variant="caption"
											className={classes.disconnectButton}
											onClick={handleReset}
										>
											Encerrar sessão
										</Typography>
									)
									}
								</Grid>
							</Grid>
						</Grid>

						<Grid
							container
							className={classes.equalSpacedContainer}
						>
							{statusChip}
						</Grid>

						<Grid
							container
							className={classes.equalSpacedContainer}
						>
							{(computedStatus === "disconnected" && !userHasChannelWithFiveMinutesUnpaired) && (
								<Button
									disableElevation
									startIcon={<ConnectedIcon />}
									variant="contained"
									onClick={reset}
									className={`${whatsappConnectionFlowClasses.actionButton} ${classes.connectButton}`}
									disabled={!isAbleGenerateQRCode}
								>
									CONECTAR
								</Button>
							)}

							<Divider orientation={isSmall ? "horizontal" : "vertical"} size={2} />

							<Button
								disableElevation
								startIcon={<ChatIcon />}
								variant="contained"
								component={LinkRouter}
								to={"/attendance"}
								target={deviceIsMobile() ? "_SELF" : "_BLANK"}
								className={`${whatsappConnectionFlowClasses.actionButton} ${classes.openInboxButton}`}
							>
								IR PARA INBOX
							</Button>
							<Tooltip
								title="Configurações do canal"
							>
								<Button
									disableRipple
									disableElevation
									component={LinkRouter}
									variant="contained"
									to={`/admin/settings/inbox-channel/${globalStateStore.currentChannel?.id}`}
									className={classes.channelSettingsButton}
								>
									<SettingsIcon />
								</Button>
							</Tooltip>
						</Grid>
					</Grid>

					{(statusMessageInfo && !userHasChannelWithFiveMinutesUnpaired) && (
						<>
							<Divider orientation="horizontal" size={2} />

							<Grid>
								{statusMessageInfo}
							</Grid>
						</>
					)}

					{(userHasChannelWithFiveMinutesUnpaired && computedStatus !== "connected") && (
						<SendPhoneToWebhookDialog
							resetSteps={resetSteps}
							computedStatus={computedStatus}
							handleReset={handleReset}
							contactData={contactData}
							setContactData={setContactData}
						/>
					)}
				</Grid>
			</Portlet>
		</>
	)
}

export default SessionCardItem
