import { makeStyles } from "@material-ui/core"
import { unusedHandlersAnimation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowBlockAnimations"

const useStyles = makeStyles({
	button: {
		fontSize: 14
	},
	...unusedHandlersAnimation
})

export default useStyles
