import React from "react"
import { OutlinedInput, InputAdornment } from "@material-ui/core"
import { Refresh as MaxBlockCallingConditionConditionIcon } from "@material-ui/icons"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import BlockBodySectionRequiredCondition from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"

import useConditionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConditionEditor"

import { getConditionByValidationSlug } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

type EditMaxBlockCallingConditionSectionProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	nextChatBotFlowBlockRules: ChatBotFlowBlockRule[]
	renderText?: (limitText: React.ReactNode) => React.ReactNode
}

const EditMaxBlockCallingConditionSection: React.FC<EditMaxBlockCallingConditionSectionProps> = (props) => {
	const {
		onSave,
		nextChatBotFlowBlockRules,
		renderText
	} = props

	const maxBlockCallingLimitCondition = getConditionByValidationSlug("max-block-calling-limit", nextChatBotFlowBlockRules)
	const conditionEditor = useConditionEditor({ chatBotFlowBlockRule: maxBlockCallingLimitCondition?.rule })

	if (!maxBlockCallingLimitCondition) {
		return null
	}

	const formattedMaxBlockCallingLimitConditionText = (
		<>
			{maxBlockCallingLimitCondition.text}
			{" "}
			{maxBlockCallingLimitCondition.value === 1 ? "interação" : "interações"}
		</>
	)

	return (
		<BlockBodySection>
			<BlockBodySectionHandle
				chatBotFlowBlockRule={maxBlockCallingLimitCondition.rule}
			>
				<BlockBodySectionEditor
					onOpen={conditionEditor.reset}
					title="SEGUIR APÓS QUANTIDADE DE INTERAÇÕES"
					onSave={() => onSave(conditionEditor.rule)}
					AsideDrawerProps={{
						children: (
							<OutlinedInput
								type="number"
								placeholder="5"
								value={conditionEditor.retrieveValidationValue(maxBlockCallingLimitCondition.validationIndex)}
								fullWidth
								onChange={({ target }) => conditionEditor.changeValidationValue(maxBlockCallingLimitCondition.validationIndex, Number(target.value))}
								endAdornment={(
									<InputAdornment
										position="end"
									>
										interações
									</InputAdornment>
								)}
								inputProps={{
									min: 1
								}}
							/>
						),
						width: "450px"
					}}
				>
					<BlockBodySectionRequiredCondition
						text={renderText?.(formattedMaxBlockCallingLimitConditionText) || formattedMaxBlockCallingLimitConditionText}
						icon={<MaxBlockCallingConditionConditionIcon />}
					/>
				</BlockBodySectionEditor>
			</BlockBodySectionHandle>
		</BlockBodySection>
	)
}

export default EditMaxBlockCallingConditionSection
