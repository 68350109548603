import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	Divider as MuiDivider
} from "@material-ui/core"
import { Divider } from "@/components"
import newColors from "@/styles/newColors"

const TemplatePageSkeleton = () => {
	return (
		<>
			{Array.from(new Array(3)).map((_, index) => (
				<Grid item style={{ width: 332, paddingBottom: 17, paddingLeft: 17 }} key={index}>
					<Grid container style={{ padding: 16, border: `1px solid ${newColors.grey[100]}`, borderRadius: "8px" }}>
						<Grid container justifyContent="space-between" direction="row">
							<Skeleton variant="rect" width="60%" height="22px" />
							<Skeleton variant="rect" width="5%" height="22px" />
						</Grid>
						<Divider orientation="horizontal" size={2}/>
						<Grid container direction="row">
							<Skeleton variant="rect" width="30%" height="32px" style={{ borderRadius: 15 }} />
							<Divider orientation="vertical" size={1}/>
							<Skeleton variant="rect" width="30%" height="32px" style={{ borderRadius: 15 }} />
						</Grid>
						<Divider orientation="horizontal" size={1}/>
						<Skeleton variant="rect" width="100%" height="322px" style={{ marginTop: 8, borderRadius: 8 }} />
						<Divider orientation="horizontal" size={3.0}/>
						<MuiDivider style={{ width: "100%" }} />
						<Grid container justifyContent="flex-end">
							<Skeleton variant="text" width="30%" height="35px" style={{ borderRadius: 8 }} />
						</Grid>
					</Grid>
				</Grid>
			))}
		</>
	)
}

export default TemplatePageSkeleton
