import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	item: {
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: 4,
		backgroundColor: newColors.grey[0],
		border: "1px solid",
		padding: 16,
		borderColor: newColors.grey[200]
	},
	chipContainer: {
		display: "flex",
		gap: "5px",
		flexFlow: "wrap"
	},
	planChip: {
		backgroundColor: newColors.getPrimaryColor(),
		color: newColors.grey[0],
		fontWeight: "bold"
	},
	addonsChip: {
		backgroundColor: newColors.purple[200],
		color: newColors.getPrimaryColor(),
		fontWeight: "bold"
	},
	title: {
		fontWeight: "bold",
		fontSize: "16px"
	},
	enabledLimitChip: {
		backgroundColor: newColors.purple[200],
		color: newColors.getPrimaryColor(),
		fontWeight: "bold"
	},
	disabledLimitChip: {
		backgroundColor: newColors.grey[200],
		color: newColors.grey[700]
	}
})

export default useStyles
