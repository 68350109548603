import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	dialogContent: {
		padding: 8,
		minHeight: "590px"
	},
	dialogTitle: {
		color: newColors.grey[700],
		fontWeight: "bold",
		fontSize: 24,
		lineHeight: 1.5
	},
	dialogDescription: {
		maxWidth: 550,
		marginTop: 12,
		color: newColors.grey[700]
	},
	dialogDescriptionLink: {
		color: newColors.grey[700],
		fontWeight: "bold",
		marginLeft: 4,
		"&:hover": {
			color: newColors.getPrimaryColor()
		}
	},
	planAddonsInformationText: {
		fontSize: 16,
		fontWeight: 700,
		maxWidth: 440,
		color: newColors.purple[600],
		marginTop: 36
	},
	dialogActions: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "start",
		gap: 24,
		marginTop: 24,
		paddingRight: 112,

		[theme.breakpoints.down("sm")]: {
			paddingRight: 0
		}
	},
	termsOfUse: {
		fontStyle: "italic"
	},
	termsOfUseLink: {
		color: newColors.grey[700],
		fontWeight: "bold",
		"&:hover": {
			color: newColors.purple[700]
		}
	},
	dialogActionPrimaryButton: {
		width: "100%",
		backgroundColor: newColors.purple[600],
		color: newColors.grey[0],
		textTransform: "uppercase",
		boxShadow: "none",
		marginBottom: 6,
		"&:hover": {
			backgroundColor: newColors.purple[700]
		}
	},
	dialogActionSecondaryButton: {
		color: newColors.grey[500],
		fontWeight: "normal",
		"&:hover": {
			background: "transparent",
			color: newColors.grey[600]
		}
	},
	benefitsList: {
		marginTop: 24,
		marginBottom: 24,
		listStyleType: "none",
		display: "flex",
		flexDirection: "column",
		gap: 12
	},
	benefitItem: {
		display: "flex",
		alignItems: "start",
		gap: 8
	},
	benefitItemIcon: {
		color: newColors.purple[600]
	},
	benefitItemText: {
		fontWeight: "bold"
	},
	confettiExplosion: {
		position: "absolute",
		width: "-1px",
		height: "-1px",
		padding: 0,
		margin: "-1px",
		overflow: "hidden",
		clip: "rect(0, 0, 0, 0)",
		whiteSpace: "nowrap",
		border: 0
	},
	imageContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		width: "100%",
		backgroundColor: newColors.green[100],
		borderTopLeftRadius: 48,

		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	dialogImage: {
		width: 430,
		position: "absolute",
		bottom: 0,
		right: 0
	},
	closeButton: {
		padding: 4,
		position: "absolute",
		backgroundColor: newColors.green[200],
		borderRadius: "100%",
		top: 12,
		right: 12,
		"&:hover": {
			backgroundColor: newColors.green[300]
		}
	}
}))

export default useStyles
