import React from "react"
import { useGlobalStateStore } from "@/store/GlobalState"

import WABASideBar from "@/@integrations/WABA/components/Menu/SideBar"
import SideBar from "@/@integrations/Whatsapp/components/Menu/SideBar"

type MenuProps = {
	openDrawer: boolean
	onClose: () => void
}

const Menu = (props: MenuProps) => {
	const {
		openDrawer,
		onClose
	} = props

	const globalStateStore = useGlobalStateStore()
	const isWabaChannel = globalStateStore.instance.current_channel_type === "waba"

	return (
		<>
			{
				isWabaChannel ? <WABASideBar onClose={onClose} openDrawer={openDrawer} /> : <SideBar onClose={onClose} openDrawer={openDrawer} />
			}
		</>
	)
}

export default Menu
