import { MappedMessageErrorCode } from "@/@integrations/WABA/protocols/error"
import { MessageErrorDetails } from "@/protocols/messages"

export const getWABAMessageErrorDetails = (error: string): Partial<MessageErrorDetails> | undefined => {
	const errorCode = error as MappedMessageErrorCode

	const errorCodeToMessageErrorDetails: Record<MappedMessageErrorCode, Partial<MessageErrorDetails> | undefined> = {
		ChatWithoutOpenedAttendanceWindow: {
			title: "Sem permissão de comunicação da WABA",
			description: "Só é possível enviar mensagens em formato livre dentro de 24h desde a última mensagem enviada pelo contato."
		},
		ContactDoesNotExist: {
			title: "Contato não existe",
			description: "Só é possível enviar mensagens para números de telefone que estejam registrados no Whatsapp."
		},
		TemplateDoesNotExist: {
			title: "Template não existe na Meta",
			description: "Só é possível enviar templates que foram criados com sucesso na Meta."
		},
		SendableTemplateMessageDoesNotExist: {
			title: "Template não existe na Letalk",
			description: "Só é possível enviar templates que foram sincronizados com a Letalk."
		},
		SendableSessionMessageNotMapped: {
			title: "Tipo de mensagem não implementado",
			description: "Só é possível enviar mensagens em formato livre que foram implementadas na Letalk."
		},
		InvalidMessagePayload: {
			title: "Mensagem inválida",
			description: "Só é possível enviar mensagens em formato livre que estejam dentro do padrão estrutural da Meta."
		},
		InvalidTemplatePayload: {
			title: "Template com algumas variáveis ausentes",
			description: "Só é possível enviar templates que estejam com todas as variáveis preenchidas."
		},
		GupshupAccountWithoutCredits: {
			title: "Conta da Gupshup sem creditos",
			description: "Só é possível enviar mensagens enquanto houver creditos na conta da Gupshup."
		},
		AuthException: {
			title: "Autenticação falhou",
			description: "Só é possível enviar mensagens se o token de acesso for válido."
		},
		ApiMethod: {
			title: "Erro no método de API",
			description: "Só é possível enviar mensagens se as permissões necessárias foram concedidas corretamente."
		},
		PermissionDenied: {
			title: "Permissão negada",
			description: "Só é possível enviar mensagens se a permissão necessária for concedida."
		},
		AccessTokenHasExpired: {
			title: "O token de acesso expirou",
			description: "Só é possível enviar mensagens com um token de acesso válido."
		},
		ApiPermission: {
			title: "Permissão da API não concedida",
			description: "Só é possível enviar mensagens se as permissões da API forem concedidas."
		},
		ApiTooManyCalls: {
			title: "Limite de chamadas de API excedido",
			description: "Só é possível enviar mensagens se o limite de chamadas de API não for excedido."
		},
		RateLimitIssues: {
			title: "Limite de volume da conta do WhatsApp Business atingido",
			description: "Só é possível enviar mensagens se o limite de volume da conta não for atingido."
		},
		RateLimitHit: {
			title: "Limite de taxa de transferência de dados da API de Nuvem atingido",
			description: "Só é possível enviar mensagens se a taxa de transferência de dados não for excedida."
		},
		SpamRateLimitHit: {
			title: "Limite de taxa de spam atingido para o número de telefone",
			description: "Só é possível enviar mensagens se o limite de envio de spam não for excedido."
		},
		RateLimitExceededForAccountPair: {
			title: "Limite de volume de emparelhamento atingido entre remetente e destinatário",
			description: "Só é possível enviar mensagens se o limite de envio para o mesmo número não for excedido."
		},
		AccountRegisterDeregisterRateLimitExceeded: {
			title: "Limite de taxa de registro/exclusão de contas excedido para o número de telefone",
			description: "Só é possível registrar ou excluir contas se o limite de tentativas não for excedido."
		},
		TemporarilyBlockedForPoliciesViolations: {
			title: "Conta bloqueada temporariamente por violações de políticas",
			description: "Só é possível usar a conta se as violações de políticas forem resolvidas."
		},
		BusinessAccountMessagingRestricted: {
			title: "Conta impedida de enviar mensagens a usuários neste país",
			description: "Só é possível enviar mensagens se a conta estiver autorizada a enviar mensagens neste país."
		},
		AccountHasBeenLocked: {
			title: "Conta bloqueada devido a violação de políticas",
			description: "Só é possível acessar a conta se as questões relacionadas à violação de políticas forem resolvidas ou se os dados de verificação forem corrigidos."
		},
		ApiUnknown: {
			title: "Erro de API desconhecida",
			description: "Só é possível prosseguir se a solicitação for corrigida ou se a API estiver disponível."
		},
		ApiService: {
			title: "Serviço de API indisponível",
			description: "Só é possível continuar quando o serviço estiver disponível novamente."
		},
		ParameterValueIsNotValid: {
			title: "Valor do parâmetro inválido",
			description: "Só é possível continuar se o número de telefone comercial for válido e estiver ativo."
		},
		InvalidParameter: {
			title: "Parâmetro inválido",
			description: "Só é possível processar a solicitação se todos os parâmetros estiverem corretos e compatíveis."
		},
		UserNumberIsPartOfAnExperiment: {
			title: "O Número faz parte de um experimento",
			description: "Só é possível enviar mensagens para usuários que não estão participando de experimentos."
		},
		SomethingWentWrong: {
			title: "Erro desconhecido ao enviar mensagem",
			description: "Ocorreu uma falha ao enviar a mensagem devido a um erro inesperado."
		},
		AccessDenied: {
			title: "Acesso Negado",
			description: "Só é possível continuar após verificar se as permissões necessárias foram concedidas."
		},
		RequiredParameterIsMissing: {
			title: "Parâmetro obrigatório ausente",
			description: "Só é possível enviar mensagem após incluir todos os parâmetros obrigatórios."
		},
		ServiceUnavailable: {
			title: "Serviço indisponível",
			description: "Só é possível enviar mensagem quando o serviço estiver disponível."
		},
		RecipientCannotBeSender: {
			title: "O destinatário não pode ser o remetente",
			description: "Só é possível enviar mensagem para um número de telefone diferente do remetente."
		},
		BusinessEligibitilyPaymentIssue: {
			title: "Problemas de elegibilidade da empresa devido a falhas na forma de pagamento",
			description: "Só é possível enviar mensagem se a forma de pagamento estiver configurada corretamente."
		},
		IncorrectCertificate: {
			title: "Erro de certificado: número de telefone não registrado",
			description: "Só é possível enviar mensagem se o número de telefone estiver registrado corretamente."
		},
		ReEngagementMessage: {
			title: "Mensagem não enviada porque mais de 24 horas se passaram desde a última resposta do destinatário",
			description: "Só é possível enviar mensagem se o destinatário tiver respondido nas últimas 24 horas."
		},
		MetaChoseNotToDeliver: {
			title: "A Meta escolheu não entregar a mensagem, para manter o engajamento saudável do ecossistema.",
			description: "Só é possível enviar mensagem se a entrega for permitida pela Meta."
		},
		UnsupportedMessageType: {
			title: "Tipo de mensagem incompatível",
			description: "Só é possível enviar mensagens que sejam do tipo compatível com as diretrizes da Meta."
		},
		MediaDownloadError: {
			title: "Erro ao baixar mídia enviada pelo usuário",
			description: "Só é possível baixar mídia se o arquivo estiver acessível e não houver problemas na entrega."
		},
		MediaUploadError: {
			title: "Erro ao carregar mídia usada na mensagem",
			description: "Só é possível carregar mídia compatível com os formatos suportados."
		},
		AccountInMaintenanceMode: {
			title: "Conta em modo de manutenção",
			description: "Só e possível realizar ações quando a conta não estiver em manutenção."
		},
		TemplateParamCountMismatch: {
			title: "Incompatibilidade na contagem de parâmetros do template",
			description: "Só é possível enviar mensagem quando o número de valores de parâmetros variáveis corresponder ao número de parâmetros definidos no template."
		},
		TemplateHydratedTextTooLong: {
			title: "Texto do template longo demais",
			description: "Só é possível enviar a mensagem se o texto traduzido estiver dentro do limite de comprimento permitido."
		},
		TemplateFormatCharacterPolicyViolated: {
			title: "Política de caracteres do template violada",
			description: "Só é possível enviar a mensagem se o conteúdo do template seguir as políticas de caracteres da Meta."
		},
		TemplateParameterFormatMismatch: {
			title: "Incompatibilidade no formato do parâmetro do template",
			description: "Só é possível enviar a mensagem se os valores dos parâmetros variáveis estiverem formatados corretamente de acordo com o template."
		},
		TemplateIsPaused: {
			title: "Template pausado",
			description: "Só é possível enviar a mensagem se o template não estiver pausado."
		},
		TemplateIsDisabled: {
			title: "Template desabilitado",
			description: "Só é possível enviar a mensagem se o template estiver ativo."
		},
		FlowIsBlocked: {
			title: "Fluxo bloqueado",
			description: "Só é possível enviar mensagens se o fluxo não estiver bloqueado."
		},
		FlowIsThrottled: {
			title: "Fluxo limitado",
			description: "Só é possível enviar mensagens se o fluxo não estiver limitado."
		},
		IncompleteDeregistration: {
			title: "Cancelamento do registro anterior incompleto",
			description: "Só é possível cancelar o registro do número após completar a tentativa anterior."
		},
		ServerTemporarilyUnavailable: {
			title: "Servidor temporariamente indisponível",
			description: "Só é possível enviar mensagem quando o servidor estiver disponível."
		},
		TwoStepVerificationPinMismatch: {
			title: "O PIN de confirmação em duas etapas está incorreto.",
			description: "Só é possível enviar mensagem quando o PIN de confirmação em duas etapas estiver correto."
		},
		PhoneNumberReverificationNeeded: {
			title: "Reverificação do número de telefone necessária",
			description: "Só é possível enviar mensagem após a verificação do número de telefone."
		},
		TooManyTwoStepVerificationPinGuesses: {
			title: "Muitas tentativas do PIN de confirmação em duas etapas",
			description: "Só é possível enviar mensagem após aguardar o período de bloqueio por muitas tentativas do PIN."
		},
		TwoStepVerificationPinGuessedTooFast: {
			title: "Retentativa muito rápida do PIN de confirmação em duas etapas",
			description: "Só é possível enviar mensagem após respeitar o intervalo de tempo entre tentativas do PIN."
		},
		PhoneNumberNotRegistered: {
			title: "Número de telefone não registrado",
			description: "Só é possível enviar mensagem após registrar o número de telefone na Plataforma do WhatsApp Business."
		},
		PhoneNumberRegistrationCooldown: {
			title: "Aguarde alguns minutos antes de tentar registrar este número de telefone",
			description: "Só é possível enviar mensagem após esperar 5 minutos para registrar o número de telefone que foi excluído recentemente."
		},
		GenericUserError: {
			title: "Erro genérico do usuário",
			description: "Falha ao enviar a mensagem devido a um erro desconhecido com seus parâmetros de solicitação."
		},
		Unknown: {}
	}

	return errorCodeToMessageErrorDetails[errorCode]
}
