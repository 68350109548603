import React, { ComponentType } from "react"
import { NodeProps, useReactFlow } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"
import { WABAChannelMessageTemplate } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import { ButtonMessageData } from "@/protocols/channel"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import WABATemplateMessageSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/WABATemplateMessageSectionEditor"
import InvalidResponseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"

import {
	getContinueConditionRuleFormattedForCreation,
	getMessageButtonText
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import {
	getCurrentWABATemplate
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

const ConditionalWABAButtonTemplateBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const currentWABAMessageTemplate = getCurrentWABATemplate(chatBotFlowConstructorStore.constructionResources, chatBotFlowBlockDetails.content)
	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")
	const chatBotFlowBlockRule = getContinueConditionRuleFormattedForCreation(
		chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules
	)
	const reactFlow = useReactFlow()

	const availableWabaChannelMessageTemplates = chatBotFlowConstructorStore.constructionResources.wabaChannelMessageTemplates
		.filter(template => template?.message.extraData?.button && template.message.extraData.button.buttons.length > 0)

	const handleDeleteAllConditionsButtonsInBlock = () => {
		conditionRules.forEach(rule => {
			const edges = reactFlow.getEdges()
			const edge = edges.find(edge => edge.sourceHandle === rule.id)

			if (edge) {
				reactFlow.deleteElements({ edges: [edge] })
			}

			chatBotFlowBlockDetails.changeRule("DELETE", rule)
		})
	}

	const handleGetWABAChannelMessageTemplatesButton = async () => {
		await chatBotFlowConstructorStore.loadSpecificConstructionResources("wabaChannelMessageTemplates")
	}

	const handleCreateConditionsButtonsInBlock = (input: {
		wabaTemplateButtons?: ButtonMessageData[]
	}) => {
		handleDeleteAllConditionsButtonsInBlock()

		input.wabaTemplateButtons?.forEach(button => {
			/**
			 * We only implemented "quick-reply" template buttons, because for the block to work,
			 * User interaction in chat is required. The "quick-reply" buttons are the only ones that allow
			 * capture direct responses from the contact, which makes it possible to take actions based on these responses.
			 */
			if (button.type !== "quick-reply") {
				return
			}

			chatBotFlowBlockDetails.changeRule("CREATE", {
				id: button.id,
				next_chat_bot_flow_block_id: null,
				validations: [{
					...chatBotFlowBlockRule.validations[0],
					second_param: button.text
				}],
				logic: "and",
				slug: "after-action",
				category: "continue-condition"
			})
		})
	}

	const handleSaveTemplate = (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
		chatBotFlowBlockDetails.changeContent({
			wabaChannelMessageTemplate: {
				id: wabaChannelMessageTemplate.id
			}
		})

		handleCreateConditionsButtonsInBlock({
			wabaTemplateButtons: wabaChannelMessageTemplate.message.extraData?.button?.buttons
		})
	}

	return (
		<BaseBlock
			node={node}
		>
			<WABATemplateMessageSectionEditor
				onSave={handleSaveTemplate}
				chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				wabaChannelMessageTemplate={currentWABAMessageTemplate}
				availableWabaChannelMessageTemplates={availableWabaChannelMessageTemplates}
				onOpen={handleGetWABAChannelMessageTemplatesButton}
			/>

			<BlockBodySectionGroup>
				{conditionRules.map(rule => {
					return (
						<BlockBodySection
							key={rule.id}
						>
							<BlockBodySectionHandle
								chatBotFlowBlockRule={rule}
							>
								<BlockBodySectionContainer
									styles={{ width: 220, minHeight: 40, padding: "8px 16px" }}
									text={getMessageButtonText(rule)}
								/>
							</BlockBodySectionHandle>
						</BlockBodySection>
					)
				})}

				{currentWABAMessageTemplate?.message.extraData?.button?.buttons.map(button => {
					if (button.type !== "quick-reply") {
						return (
							<BlockBodySection
								key={button.id}
							>
								<BlockBodySectionContainer
									styles={{ width: 220, minHeight: 40, padding: "8px 16px" }}
									text={button.text}
									tooltipText="Apenas botões de resposta rápida podem interagir com o bot. Por isso, não implementamos botões do tipo link, telefone ou cupom."
								/>
							</BlockBodySection>
						)
					}
				})}
			</BlockBodySectionGroup>

			<BlockBodySectionGroup>
				<InvalidResponseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Se nenhuma resposta for válida"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default ConditionalWABAButtonTemplateBlock
