import React from "react"

import { Grid, Typography } from "@material-ui/core"

import { ReactComponent as ActiveCampaignSVGIcon } from "@/assets/images/logos/active_campaign.svg"
import { ReactComponent as TipsIcon } from "@/assets/icons/tips_icon.svg"
import { WarningRounded as WarningIcon } from "@material-ui/icons"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"
import { Divider, SvgIcon } from "@/components"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useActiveCampaignApi, {
	ActiveCampaignPluginStatus
} from "@/hooks/useActiveCampaignApi"

import { getCurrentTagsOptions } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { TagOption } from "@/protocols/chatBotConstructor"

import HardCoded from "@/services/HardCoded"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ManageClientTagsBlock/TagSectionEditor/styles"
import colors from "@/styles/colors"

type TagSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	action: "add" | "remove"
}

const TagSectionEditor: React.FC<TagSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children,
		action
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })
	const {
		validatePlugin,
		status
	} = useActiveCampaignApi()

	const isEnableManagerTagsActiveCampaignText = HardCoded.checkFeatureFlag("managerTagsIntegrationText")

	const classes = useStyles()

	const currentTagOptions = getCurrentTagsOptions(chatBotFlowConstructorStore.constructionResources, contentEditor.content)

	const handleChange = (tags: TagOption[] | null) => {
		const notEditedActions = contentEditor?.content?.clientTagsManagementData?.actions?.filter(({ type }) => type !== action) || []

		const updatedActions = tags?.map(tag => ({
			type: action,
			tagId: tag.id
		}))

		contentEditor.changeContent({
			clientTagsManagementData: {
				actions: [
					...notEditedActions,
					...(updatedActions || [])
				]
			}
		})
	}

	const handleTagManagerTextByActiveCampaignPluginStatus = (): JSX.Element => {
		const handleMap: Record<ActiveCampaignPluginStatus, JSX.Element> = {
			success: (
				<>
					<SvgIcon icon={ActiveCampaignSVGIcon} />

					<Divider orientation="vertical" size={1} />

					<Typography
						className={classes.text}
					>
						O plugin de conexão com a API do Active Campaign está conectado,
						portanto qualquer atualização de TAGS que for feita na Letalk
						também será feita no Active Campaign.
					</Typography>
				</>
			),
			"plugin-check-error": (
				<>
					<WarningIcon
						fontSize="medium"
						style={{
							color: colors.unrelated.ECCA71
						}}
					/>

					<Divider orientation="vertical" size={1} />

					<Typography
						className={classes.text}
					>
						O plugin de conexão com a API do Active Campaign está habilitado, mas não
						foi possível conectar a sua conta.
					</Typography>
				</>
			),
			"plugin-disabled": (
				<>
					<SvgIcon icon={TipsIcon} fontSize="large" />

					<Typography
						className={classes.text}
					>
						<span style={{ fontWeight: "bold" }}>Dica:</span> Você sabia que você pode ativar o seu plugin e conectar
						a Letalk ao Active Campaign e dessa forma todas as TAGs atualizadas
						na Letalk serão atualizadas no Active Campaign também.
					</Typography>
				</>
			)
		}

		return handleMap[status as ActiveCampaignPluginStatus]
	}

	return (
		<BlockBodySectionEditor
			title={action === "add" ? "ADICIONAR TAGS" : "REMOVER TAGS"}
			onSave={() => onSave(contentEditor.content)}
			onOpen={async () => {
				contentEditor.reset()
				await validatePlugin()
			}}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								spacing={1}
								alignItems="center"
							>
								<Grid item style={{ width: "100%" }}>
									<SelectInput
										multiple
										defaultOption={action === "add" ? currentTagOptions.tagsToAdd : currentTagOptions.tagsToRemove}
										onChange={handleChange}
										options={chatBotFlowConstructorStore.constructionResources.tagOptions}
										placeholder="Tags"
										getOptionSelected={(option, value) => option.id === value.id}
										noOptionsText="Sem opções"
										loadingText="Carregando..."
										renderTag={tagOption => (
											<TagChip
												color={tagOption.color}
												name={tagOption.name}
											/>
										)}
										onUpdate={async () => {
											await chatBotFlowConstructorStore.loadSpecificConstructionResources("tagOptions")
										}}
									/>
								</Grid>
							</Grid>

							{isEnableManagerTagsActiveCampaignText && (
								<>
									<Divider orientation="horizontal" size={2} />

									<Grid
										container
										className={classes.container}
										alignItems="center"
										style={{
											backgroundColor: status === "success" ? colors.unrelated.F6FAFD
												: status === "plugin-disabled" ? colors.unrelated.F8F8F8
													: colors.unrelated.FCF9EE
										}}
									>
										{handleTagManagerTextByActiveCampaignPluginStatus()}
									</Grid>
								</>
							)}
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default TagSectionEditor
