import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	importBotMenu: {
		justifyContent: "space-between",
		alignItems: "center",
		display: "flex"
	},
	importBotTemplateMenu: {
		justifyContent: "space-between",
		alignItems: "center",
		display: "flex",
		marginRight: "16px"
	}
})

export default useStyles
