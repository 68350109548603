import { ChatListFilter } from "@/store/ChatGlobalState"

import ErrorHandlerService from "@/services/ErrorHandler"
import Storage from "@/services/Storage"
import { ErrorType } from "@/hooks/useValidation"

type SavedChatListFilter = Omit<ChatListFilter, "text" | "fromLastMessageTransactedDate" | "toLastMessageTransactedDate">

type GetSavedChatListFilterInput = {
	userId: number
	instanceId: number
}

type SaveChatListFilterInput = GetSavedChatListFilterInput & {
	chatListFilter: SavedChatListFilter
}

const mountDataKey = (where: GetSavedChatListFilterInput) => `chat-list-filter:user-${where.userId}:instance-${where.instanceId}`

export const getSavedChatListFilter = (input: GetSavedChatListFilterInput): SavedChatListFilter | null => {
	try {
		const chatListFilterKey = mountDataKey(input)

		const chatListFilter = Storage.get<SavedChatListFilter>(chatListFilterKey)

		if (!chatListFilter) {
			return null
		}

		return chatListFilter
	} catch (error) {
		ErrorHandlerService.handle(error as ErrorType)
		return null
	}
}

export const saveChatListFilter = (input: SaveChatListFilterInput): void => {
	try {
		const {
			chatListFilter
		} = input
		const chatListFilterKey = mountDataKey(input)

		Storage.set<SavedChatListFilter>(chatListFilterKey, {
			attendants: chatListFilter.attendants,
			chatTypes: chatListFilter.chatTypes,
			tags: chatListFilter.tags,
			teams: chatListFilter.teams
		})
	} catch (error) {
		ErrorHandlerService.handle(error as ErrorType)
	}
}
