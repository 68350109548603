import React, { useState } from "react"
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	Grid,
	IconButton
} from "@material-ui/core"
import {
	Person as ActiveFlowIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"

import { ActionDialog, Divider, PopConfirm, ProductReportClient } from "@/components"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ActiveFlowReport/styles"

import { ActiveInboxChannelChatFlow, FlowData } from "@/protocols/chatBotConstructor"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import colors from "@/styles/colors"
import { ErrorType } from "@/hooks/useValidation"

type ActiveFlowReportProps = {
	activeInboxChannelChatFlows: ActiveInboxChannelChatFlow[]
	tooltipTitle?: string
	dialogTitle?: string
	buttonClassName?: string
	chatBotFlowId?: number
	changeChatBotFlow: (flow: Partial<Pick<FlowData["flow"], "active" | "name" | "updated_at" | "active_inbox_channel_chat_flows">>) => void
	nextChatBotFlowBlockId?: number
	buttonTitle?: string
}

const ActiveFlowReport: React.FC<ActiveFlowReportProps> = (props) => {
	const {
		tooltipTitle,
		buttonClassName,
		dialogTitle,
		activeInboxChannelChatFlows,
		chatBotFlowId,
		changeChatBotFlow,
		nextChatBotFlowBlockId,
		buttonTitle
	} = props

	const [opened, setOpened] = useState(false)
	const filteredActiveInboxChannelChatFlows =
		nextChatBotFlowBlockId ? activeInboxChannelChatFlows?.filter(flow => flow.current_chat_bot_flow_block_id === nextChatBotFlowBlockId) : activeInboxChannelChatFlows

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const handleOpenDialog = () => {
		setOpened(true)
	}

	const handleCloseDialog = () => {
		setOpened(false)
	}

	const handleFilterActiveInboxChannelChatFlows = (inboxChannelChatFlowIds: number[]) => {
		let updatedActiveInboxChannelChatFlows = []

		if (inboxChannelChatFlowIds.length > 0) {
			updatedActiveInboxChannelChatFlows = activeInboxChannelChatFlows.filter((activeInboxChannelChatFlow) => {
				return !inboxChannelChatFlowIds.includes(activeInboxChannelChatFlow.id)
			})
		}

		changeChatBotFlow({
			active_inbox_channel_chat_flows: [
				...updatedActiveInboxChannelChatFlows
			]
		})
	}

	const handleRemoveContact = async (action: "removeAllContactsOfChatBotFlow" | "removeOneContactsOfChatBotFlow", inboxChannelChatFlowIds?: number[]) => {
		PopConfirm.open({
			title: "EXCLUIR CLIENTE DO FLUXO/BLOCO",
			description: "Essa ação irá finalizar os atendimentos, exceto aqueles que estão em atendimento por um atendente humano. Tem certeza de que deseja continuar? Essa ação é irreversível.",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/chat-bot/flow-manager/${chatBotFlowId}/remove-contacts`, {
						params: {
							action,
							inboxChannelChatFlowsId: inboxChannelChatFlowIds?.join(",")
						}
					})
					handleFilterActiveInboxChannelChatFlows(inboxChannelChatFlowIds || [])
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	return (
		<>
			<Tooltip
				title={tooltipTitle || ""}
			>
				<Grid
					onClick={handleOpenDialog}
					className={`${buttonClassName || classes.button}`}
				>
					<ActiveFlowIcon fontSize="small" style={{ color: colors.grayScale[11] }} />
					<Typography className={classes.buttonText}>
						{filteredActiveInboxChannelChatFlows.length}
					</Typography>
				</Grid>
			</Tooltip>

			<ActionDialog
				title={dialogTitle || "CONTATOS"}
				hideCloseButton
				onClose={handleCloseDialog}
				openDialog={opened}
				maxWidth="sm"
				fullWidth
			>
				{filteredActiveInboxChannelChatFlows?.length > 0 ? (
					<>
						<Grid container justifyContent="flex-end">
							<Button
								variant="contained"
								onClick={() => handleRemoveContact("removeAllContactsOfChatBotFlow")}
								className={classes.saveButton}
								endIcon={<DeleteIcon />}
								color="primary"
							>
								{ buttonTitle }
							</Button>
						</Grid>

						<Divider orientation="horizontal" size={1} />

						<TableContainer>
							<Table stickyHeader size="small">
								<TableHead>
									<TableRow>
										<TableCell>
											ID Bloco Atual
										</TableCell>

										<TableCell>
											Contato
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody
									className={customClasses.reportTableBodyText}
								>
									{filteredActiveInboxChannelChatFlows?.map(activeFlow => (
										<TableRow
											key={activeFlow.id}
											tabIndex={-1}
										>
											<TableCell>
												{activeFlow.current_chat_bot_flow_block_id}
											</TableCell>

											<TableCell>
												<Grid container alignItems="center" justifyContent="center">
													<ProductReportClient
														client={activeFlow.client}
													/>

													<IconButton
														onClick={() => handleRemoveContact("removeOneContactsOfChatBotFlow", [activeFlow.id])}
													>
														<DeleteIcon />
													</IconButton>
												</Grid>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				) : (
					<Typography
						variant="body1"
					>
						Nenhum contato está aqui atualmente
					</Typography>
				)}
			</ActionDialog>
		</>
	)
}

export default ActiveFlowReport
