import React from "react"

import { getValidationValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRuleValidation"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleCategory,
	ChatBotFlowBlockRuleSlug,
	ChatBotFlowBlockRuleValidationSlug
} from "@/protocols/chatBot"
import {
	NextFlowBlockRule
} from "@/protocols/chatBotConstructor"

export type Condition = {
	rule: ChatBotFlowBlockRule
	text: React.ReactNode
	validationIndex: number
	value: string | number
}

export function getConditionRuleByCategory<Rule extends Pick<ChatBotFlowBlockRule, "category">> (category: ChatBotFlowBlockRuleCategory, chatBotFlowBlockRules: Rule[]) {
	return chatBotFlowBlockRules?.find(rule => rule.category === category)
}

export function getConditionRuleBySlug<Rule extends Pick<ChatBotFlowBlockRule, "slug">> (slug: ChatBotFlowBlockRuleSlug, chatBotFlowBlockRules: Rule[]) {
	return chatBotFlowBlockRules?.find(rule => rule.slug === slug)
}

export function getConditionRuleByValidationSlug<Rule extends Pick<ChatBotFlowBlockRule, "validations">> (validationSlug: ChatBotFlowBlockRuleValidationSlug, chatBotFlowBlockRules: Rule[]) {
	return chatBotFlowBlockRules?.find(rule => (
		rule?.validations?.some(validation => validation.slug === validationSlug)
	))
}

export const getContinueConditionRuleFormattedForCreation = (rules: NextFlowBlockRule[], defaultValidationSlug?: ChatBotFlowBlockRuleValidationSlug): ChatBotFlowBlockRule => {
	const ruleFormattedForCreation = {} as ChatBotFlowBlockRule

	const rule = (getConditionRuleByCategory("continue-condition", rules) || { validations: [] }) as NextFlowBlockRule

	ruleFormattedForCreation.category = rule.category
	ruleFormattedForCreation.logic = rule.logic
	ruleFormattedForCreation.slug = rule.slug
	ruleFormattedForCreation.validations = rule.validations

	if (defaultValidationSlug) {
		const defaultValidation = rule.validations.find(({ slug }) => slug === defaultValidationSlug)
		ruleFormattedForCreation.validations = defaultValidation ? [defaultValidation] : []
	}

	return ruleFormattedForCreation
}

export const getConditionByValidationSlug = (validationSlug: ChatBotFlowBlockRuleValidationSlug, chatBotFlowBlockRules: ChatBotFlowBlockRule[]): Condition | null => {
	const rule = getConditionRuleByValidationSlug(validationSlug, chatBotFlowBlockRules)

	if (!rule) {
		return null
	}

	const validationIndex = rule.validations.findIndex(({ slug }) => slug === validationSlug)
	const validation = rule.validations?.[validationIndex]
	const value = Number(getValidationValue(validation))
	const text = (
		<b><u>{value ?? ""}</u></b>
	)

	return {
		rule,
		text,
		value,
		validationIndex
	}
}

export const getInvalidResponseCondition = (chatBotFlowBlockRules: ChatBotFlowBlockRule[]): Condition | null => {
	const rule = getConditionRuleByValidationSlug("invalid-response", chatBotFlowBlockRules)

	if (!rule) {
		return null
	}

	const validationIndex = rule.validations.findIndex(({ slug }) => slug === "invalid-response")
	const validation = rule.validations?.[validationIndex]
	const value = getValidationValue(validation)
	const text = "Se nenhuma resposta for válida"

	return {
		rule,
		text,
		value,
		validationIndex
	}
}

export const getElseRule = (chatBotFlowBlockRules: ChatBotFlowBlockRule[]): ChatBotFlowBlockRule | null => {
	const rule = chatBotFlowBlockRules?.find(rule => rule.logic === "else")

	if (!rule) {
		return null
	}

	return rule
}

export const getMessageReceivedCondition = (chatBotFlowBlockRules: ChatBotFlowBlockRule[]): Condition | null => {
	const rule = getConditionRuleByValidationSlug("message-received", chatBotFlowBlockRules)

	if (!rule) {
		return null
	}

	const validationIndex = rule.validations.findIndex(({ slug }) => slug === "message-received")
	const validation = rule.validations?.[validationIndex]
	const value = getValidationValue(validation)
	const text = "Se nenhuma resposta for inválida"

	return {
		rule,
		text,
		value,
		validationIndex
	}
}

export const sanitizeChatBotFlowRuleRemovingWhiteSpaces = (chatBotFlowBlockRule: ChatBotFlowBlockRule): ChatBotFlowBlockRule => {
	const formattedRuleValidations = chatBotFlowBlockRule.validations.map((validation) => {
		if (validation.slug === "client-response-value" || validation.slug === "message-received") {
			const formattedStingValue = validation?.second_param?.toString().trim()
			return {
				...validation,
				content: formattedStingValue,
				second_param: formattedStingValue
			}
		}
		return validation
	})

	return {
		...chatBotFlowBlockRule,
		validations: formattedRuleValidations
	}
}

export const getMessageButtonText = (chatBotFlowBlockRule: ChatBotFlowBlockRule) => {
	const messageReceivedCondition = getMessageReceivedCondition([chatBotFlowBlockRule])

	return String(messageReceivedCondition?.value)
}

export const getMessageRandomPathCondition = (chatBotFlowBlockRules: ChatBotFlowBlockRule[]): Condition | null => {
	const rule = getConditionRuleByValidationSlug("random-path-value", chatBotFlowBlockRules)

	if (!rule) {
		return null
	}

	const validationIndex = rule.validations.findIndex(({ slug }) => slug === "random-path-value")
	const validation = rule.validations?.[validationIndex]
	const value = getValidationValue(validation)
	const text = "Se nenhuma resposta for inválida"

	return {
		rule,
		text,
		value,
		validationIndex
	}
}

export const getMessageRandomPathText = (chatBotFlowBlockRule: ChatBotFlowBlockRule) => {
	const messageRandomPathCondition = getMessageRandomPathCondition([chatBotFlowBlockRule])

	return String(messageRandomPathCondition?.value)
}
