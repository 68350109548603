import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { BLOCK_SECTION_BORDER_RADIUS } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"
import { unusedHandlersAnimation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowBlockAnimations"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
		minHeight: theme.spacing(6),
		borderRadius: BLOCK_SECTION_BORDER_RADIUS,
		backgroundColor: colors.unrelated.F6FAFD
	},
	textPlaceholder: {
		color: colors.grayScale[7]
	},
	title: {
		color: colors.unrelated.A1AAB3
	},
	information: {
		color: colors.unrelated["818181"],
		fontSize: theme.spacing(1.25)
	},
	...unusedHandlersAnimation
}))

export default useStyles
