import React, { useState } from "react"
import { Player } from "@lottiefiles/react-lottie-player"

import { Button, Grid, Typography } from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ValidateFlow/styles"
import clsx from "clsx"
import useCustomStyles from "@/styles/custom"

import { useReactFlow, useStoreApi } from "reactflow"
import useConstantId from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConstantId"
import useGlobalItemSelection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useGlobalItemSelection"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowValidation from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowValidation"

import { Divider, Loading, Portal, SvgIcon } from "@/components"
import AsideDrawer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AsideDrawer"
import SaveFlowButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SaveFlowButton"

import { timeout } from "@/utils/time"

import {
	FLOW_EDITOR_CONTAINER_ID,
	BLOCK_BODY_SECTION_EDITOR_ID
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"
import { flowValidationConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowValidation"

import { WarningRounded as WarningIcon } from "@material-ui/icons"
import { ReactComponent as ValidateFlowIcon } from "@/assets/icons/validate_flow_icon.svg"
import NoPendingGif from "@/assets/lotties/no_pending.json"

type ValidateFlowProps = {
	canValidateFlow?: boolean
}

const ValidateFlow: React.FC<ValidateFlowProps> = (props) => {
	const {
		children,
		canValidateFlow
	} = props

	const flowBlockSectionEditorSelection = useGlobalItemSelection("flow-editor-right-drawer")

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const reactFlow = useReactFlow()
	const reactFlowStore = useStoreApi()
	const blockSectionId = useConstantId()

	const isBeingEdited = flowBlockSectionEditorSelection.isSelected(blockSectionId)
	const flowEditorContainer = document.getElementById(FLOW_EDITOR_CONTAINER_ID) as Element

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		validateFlow
	} = useChatBotFlowValidation()

	const [loading, setLoading] = useState(false)

	const handleClose = () => {
		flowBlockSectionEditorSelection.clearSelection()
	}

	const handleOpen = () => {
		flowBlockSectionEditorSelection.clearSelection()
		flowBlockSectionEditorSelection.toggleSelected(blockSectionId)
	}

	const handleValidateFlow = async () => {
		setLoading(true)
		const blockErrorsContent = validateFlow(chatBotFlowConstructorStore.flowData)
		chatBotFlowConstructorStore.setValidationErrors(blockErrorsContent)
		chatBotFlowConstructorStore.setBlockTargetId(null)
		await timeout(2000)
		setLoading(false)
	}

	const targetBlock = (nodeId?: string) => {
		const { nodeInternals } = reactFlowStore.getState()
		const nodes = Array.from(nodeInternals).map(([, node]) => node)

		if (nodeId) {
			const node = nodes.find(node => node.id === nodeId)

			if (node) {
				chatBotFlowConstructorStore.setBlockTargetId(nodeId)
				const x = node.position.x + Number(node.width) / 2
				const y = node.position.y + Number(node.height) / 2

				reactFlow.setCenter(x, y, { zoom: 1.2, duration: 1000 })
			}
		}
	}

	return (
		<>
			<Grid
				onClick={() => {
					if (!isBeingEdited) {
						handleOpen()
						if (canValidateFlow) {
							handleValidateFlow()
						}
					}
				}}
			>
				{ children }
			</Grid>

			<Portal
				container={flowEditorContainer}
			>
				<AsideDrawer
					id={BLOCK_BODY_SECTION_EDITOR_ID}
					opened={isBeingEdited}
					onClose={handleClose}
					title="Validação do bot"
					anchor="right"
					width="400px"
					childrenProps={{
						contentClassName: classes.childrenContent
					}}
				>
					<Grid
						container
						spacing={2}
						className={classes.container}
					>
						<Grid
							item
							xs={12}
							className={classes.contentContainer}
						>
							<Loading loading={loading}>
								<Grid
									container
									spacing={3}
									className={clsx({
										[classes.blockContainer]: true,
										[customClasses.scrollBar]: true
									})}
								>
									<Grid item style={{ width: "100%" }}>
										{chatBotFlowConstructorStore.validationErrors.length > 0 ? (
											<Grid
												container
											>
												<Typography
													className={classes.containerTitleText}
												>
													Pendências:
												</Typography>

												<Divider
													orientation="horizontal"
													size={1}
												/>

												{chatBotFlowConstructorStore.validationErrors?.map(blockError => {
													return (
														<Grid
															key={blockError.block.nodeId}
															container
															alignItems="center"
															justifyContent="space-between"
															className={classes.blockError}
														>
															<WarningIcon
																fontSize="medium"
																className={classes.warningIcon}
															/>

															<Grid
																container
																alignItems={blockError.type === "flow_error" ? "center" : "flex-start"}
																style={{
																	backgroundColor: blockError.block.color.blockHeaderBackground,
																	cursor: blockError.type === "flow_error" ? "default" : "pointer"
																}}
																className={classes.blockErrorContent}
																onClick={() => targetBlock(blockError.block?.nodeId)}
															>
																{ React.cloneElement(
																	blockError.block.icon.Component,
																	{
																		style: {
																			...(blockError.block.icon.style || {}),
																			color: blockError.block.color.blockIcon
																		}
																	}
																) }
																<Grid
																	className={clsx({
																		[classes.blockInfoContainer]: true
																	})}
																>
																	{ blockError.type === "block_error" ? (
																		<>
																			<Typography
																				className={clsx({
																					[classes.blockInfoTitle]: true
																				})}
																			>
																				{blockError.block.name}
																			</Typography>

																			<Divider orientation="horizontal" size={1} />

																			{blockError.errors.map(error => (
																				<Typography
																					key={`${blockError.block.nodeId}-${error}`}
																					className={clsx({
																						[classes.blockInfoText]: true
																					})}
																				>
																					- { flowValidationConfig[error].errorMessage }
																				</Typography>
																			))}
																		</>
																	) : (
																		<Typography
																			className={clsx({
																				[classes.blockInfoTitle]: true
																			})}
																		>
																			{flowValidationConfig[blockError.errors[0]].errorMessage}
																		</Typography>
																	)}
																</Grid>
															</Grid>

															<Divider orientation="horizontal" size={2} />
														</Grid>
													)
												})}
												{chatBotFlowConstructorStore.validationErrors.length > 0 && (
													<Grid
														item
														xs={12}
													>
														<Grid
															container
															justifyContent="center"
														>
															<Typography
																className={classes.containerTitleText}
															>
																{ `${chatBotFlowConstructorStore.validationErrors.length} pendências` }
															</Typography>
														</Grid>
													</Grid>
												)}
											</Grid>
										) : (
											<Grid
												container
												justifyContent="center"
												className={classes.noPendingContainer}
											>
												<Player
													src={NoPendingGif}
													speed={0.3}
													loop
													autoplay={true}
												/>

												<Typography
													className={classes.noPendingTitle}
												>
													Seu Bot não possui pendencias!
												</Typography>

												<Divider orientation="horizontal" size={2} />

												<SaveFlowButton
													onClose={handleClose}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Loading>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
							>
								<Button
									variant="contained"
									className={classes.validateButton}
									onClick={handleValidateFlow}
									endIcon={
										<SvgIcon
											icon={ValidateFlowIcon}
											className={classes.validateButtonIcon}
										/>
									}
								>
									Validar novamente
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</AsideDrawer>
			</Portal>
		</>
	)
}

export default ValidateFlow
