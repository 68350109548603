import React, { useState } from "react"
import {
	CardHeader,
	CardContent,
	CardActions,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	Chip,
	Tooltip,
	Grid
} from "@material-ui/core"
import { MoreVert as MoreVertIcon } from "@material-ui/icons"
import { Divider, Notification, PopConfirm, Portlet } from "@/components"
import useStyles from "@/pages/Admin/Templates/components/TemplateCard/styles"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import { WABATemplateCategory, WABATemplateQuality, WABATemplateStatus } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import { BuildedMessage } from "@/protocols/messages"
import WhatsappBackground from "@/assets/images/waba/whatsapp_background.png"
import logo from "@/assets/images/logos/letalk-small-logo.svg"
import ApiService from "@/services/Api"
import { useGlobalStateStore } from "@/store/GlobalState"
import { useHistory } from "react-router-dom"
import { TEMPLATE_QUALITY_TO_READABLE_QUALITY, TEMPLATE_STATUS_TO_READABLE_STATUS } from "@/pages/Admin/Templates"

type TemplateCardProps = {
	id: number
	name: string
	status: WABATemplateStatus
	quality: WABATemplateQuality
	category: WABATemplateCategory
	message: BuildedMessage
	onDelete: (templateId: number) => void
	onEdit?: () => void
}

const TemplateCard: React.FC<TemplateCardProps> = (props) => {
	const {
		id,
		category,
		message,
		name,
		quality,
		status,
		onDelete
	} = props
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const globalStateStore = useGlobalStateStore()
	const history = useHistory()
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const confirmDelete = () => {
		PopConfirm.open({
			title: "EXCLUIR TEMPLATE?",
			description: "Tem certeza de que deseja continuar? Essa ação é irreversível e também removerá o template da plataforma GupShup.",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/waba/channel/${globalStateStore.currentChannel?.id}/message/template/${name}`, {
						params: {
							id: id
						}
					})
					onDelete(id)
				} catch (error) {
					Notification.error({
						message: "Ocorreu um erro inesperado ao excluir o template. Por favor tente novamente."
					})
				}
			},
			confirmButtonText: "Excluir"
		})
	}

	const onEdit = () => {
		history.push("/admin/template/manage", {
			isEditMode: true,
			id
		})
	}

	const templateCategoryToReadableCategory: Record<WABATemplateCategory, string> = {
		authentication: "Autenticação",
		marketing: "Marketing",
		otp: "Otp",
		utility: "Utilidade"
	}

	const classes = useStyles({
		qualityBackgroundColor: TEMPLATE_QUALITY_TO_READABLE_QUALITY[quality].color,
		statusBackgroundColor: TEMPLATE_STATUS_TO_READABLE_STATUS[status].color
	})

	return (
		<Grid item className={classes.templateItem} xs={4}>
			<Portlet
				style={{
					width: 330
				}}>
				<CardHeader
					title={<Tooltip
						title={name}
					>
						<Typography className={classes.name}>{name}</Typography>
					</Tooltip>}
					action={
						<IconButton onClick={handleMenuOpen} className={classes.moreOptionsButton}>
							<MoreVertIcon />
						</IconButton>
					}
					className={classes.header}
				/>
				<Divider orientation="horizontal" size={1} />
				<Grid className={classes.feedbackGrid}>
					<Tooltip
						title={TEMPLATE_STATUS_TO_READABLE_STATUS[status]?.description || ""}
						disableHoverListener={!TEMPLATE_STATUS_TO_READABLE_STATUS[status]?.description}
					>
						<Chip label={TEMPLATE_STATUS_TO_READABLE_STATUS[status].label} className={classes.statusChip} />
					</Tooltip>
					<Tooltip
						title={TEMPLATE_QUALITY_TO_READABLE_QUALITY[quality]?.description || ""}
						disableHoverListener={!TEMPLATE_QUALITY_TO_READABLE_QUALITY[quality]?.description}
					>
						<Chip label={TEMPLATE_QUALITY_TO_READABLE_QUALITY[quality].label} className={classes.qualityChip} />
					</Tooltip>
				</Grid>

				<Divider orientation="horizontal" size={2} />
				<CardContent
					className={classes.content}
					style={{
						backgroundImage: `url(${WhatsappBackground})`
					}}>
					<MessageItem
						type={message.type}
						content={message.content}
						mediaName={message?.mediaName}
						extraData={message?.extraData}
						isDefaultSize={false}
						copySpecial={false}
					/>
				</CardContent>
				<Divider orientation="horizontal" size={3} />
				<CardActions className={classes.footer}>
					<Tooltip title={"Criado a partir da Letalk"}>
						<img
							alt="Letalk"
							src={logo}
						/>
					</Tooltip>
					<Chip label={templateCategoryToReadableCategory[category]} className={classes.categoryChip} />
				</CardActions>
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleMenuClose}
				>	<Tooltip
						title={"A edição de templates de mídia não está disponível pela Letalk."}
						disableHoverListener={message.type === "text"}
					>
						<MenuItem onClick={onEdit} disabled={message.type !== "text"}>Editar</MenuItem>
					</Tooltip>
					<MenuItem onClick={confirmDelete}>Excluir</MenuItem>
				</Menu>
			</Portlet>
		</Grid>
	)
}

export default TemplateCard
