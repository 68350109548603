import React from "react"
import { Typography, Grid } from "@material-ui/core"

import { Divider } from "@/components"

import useStyles from "@/components/ListItemCategory/styles"

type ListItemCategoryProps = {
	title: string
}

const ListItemCategory: React.FC<ListItemCategoryProps> = (props) => {
	const { title } = props

	const classes = useStyles()

	return (
		<Grid
			item
			xs={12}
			className={classes.container}
		>
			<Divider size={2} orientation="horizontal" />

			<Typography
				variant="h2"
				className={classes.title}
			>
				{title}
			</Typography>

			<Divider size={2} orientation="horizontal" />
		</Grid>
	)
}

export default ListItemCategory
