import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import copy from "copy-to-clipboard"
import { Grid, Link, Typography } from "@material-ui/core"

import {
	ActionDialog,
	Divider,
	Loading,
	Breadcrumb,
	Notification,
	JsonEditor
} from "@/components"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useHotjar from "@/hooks/useHotjar"

import { NotificationsActive as NewsIcon } from "@material-ui/icons"

import ChatBotConfig from "@/pages/Admin/ChatBot/ChatBotList/ChatBotConfig"
import ChatBotListItem from "@/pages/Admin/ChatBot/ChatBotList/ChatBotListItem"
import ChatBotListSkeleton from "@/skeletons/Admin/ChatBotListSkeleton"
import ChatBotCreation from "@/pages/Admin/Flow/ChatBotCreation"

import ErrorHandlerService from "@/services/ErrorHandler"
import ChatBotFlowService, { CategorizedChatBotFlows } from "@/services/ChatBotFlow"
// import User from "@/services/User"

import { ChatBotFlowTriggerType } from "@/protocols/chatBot"

import { letalkLinks } from "@/utils/link"

import useStyles from "@/pages/Admin/ChatBot/ChatBotList/styles"
import { useGlobalStateStore } from "@/store/GlobalState"

export const chatBotTriggerTranslatedByType: Record<ChatBotFlowTriggerType | "no-triggers", string> = {
	"attendance-started-by-client": "O atendimento é iniciado pelo cliente",
	"webhook-triggered": "Um Webhook é recebido",
	"no-triggers": "Os gatilhos ainda não foram configurados",
	"client-tag-associated": "Uma tag é associada ao contato"
}

const FlowList = () => {
	const [chatBotList, setChatBotList] = useState<CategorizedChatBotFlows>()
	const [loading, setLoading] = useState<boolean>(true)
	const [handleExportChatBotDialogOpen, setHandleExportChatBotDialogOpen] = useState(false)
	const [chatBotFlowJson, setChatBotFlowJson] = useState<string>()

	const classes = useStyles()
	const history = useHistory()
	const globalStateStore = useGlobalStateStore()

	const isWabaChannel = globalStateStore.currentChannel?.channelType === "waba"

	const { triggerValidation } = useValidation()

	const hotjar = useHotjar()
	hotjar.setupHotjar()

	const handleGetChatBotList = async () => {
		setLoading(true)

		try {
			const categorizedChatBotFlows = await ChatBotFlowService.getAllCategorized("flow")

			setChatBotList(categorizedChatBotFlows)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(false)
	}

	const handleDuplicateChatBot = async (chatBotFlowId: number) => {
		try {
			const chatBotFlow = await ChatBotFlowService.duplicate(chatBotFlowId)

			history.push(`/admin/flow/${chatBotFlow.id}`)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleChangeChatBotConfig = (chatBotFlowId: number) => {
		ChatBotConfig.open({
			chatBotFlowId,
			title: "CONFIGURAÇÕES DO BOT"
		})
	}

	const handleExportChatBotOpen = async (chatBotFlowId: number) => {
		const exportedChatBotFlow = await ChatBotFlowService.export(chatBotFlowId)

		const serializedData = JSON.stringify(exportedChatBotFlow, null, 2)
		setChatBotFlowJson(serializedData)
		setHandleExportChatBotDialogOpen(true)
	}
	const handleExportChatBotClose = () => {
		setHandleExportChatBotDialogOpen(false)
	}

	const handleOnSaveExportChatBot = async () => {
		if (chatBotFlowJson) {
			copy(chatBotFlowJson)
			Notification.success({ message: "Dados do fluxo copiado com sucesso!" })
		}
		setHandleExportChatBotDialogOpen(false)
	}

	// const getIsWillShowAllBotVersions = async () => {
	// 	const userInfo = await User.getInfo()

	// 	return userInfo?.botVersions.willShow
	// }

	useDidMount(async () => {
		// const isWillShowAllBotVersions = await getIsWillShowAllBotVersions()

		// if (isWillShowAllBotVersions) {
		// 	history.push("/admin/bots")
		// }

		handleGetChatBotList()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ChatBotListSkeleton />}
		>
			<Grid
				container
			>
				<Breadcrumb
					data={[
						{ name: "Bots", pathname: "/admin/flow" }
					]}
				/>

				<Divider orientation="horizontal" size={3} />

				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item>
						<Typography
							variant="h5"
							color="textPrimary"
							className={classes.title}
						>
							Bots
						</Typography>
					</Grid>

					<Grid
						item
					>
						<Grid container>
							<ChatBotCreation
								onChatBotCreated={handleGetChatBotList}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={5} orientation="horizontal" />

				{
					!isWabaChannel && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								className={classes.tipsContainer}
								justifyContent="flex-start"
							>
								<Grid
									item
									xs={12}
								>
									<Grid
										container
										justifyContent="flex-start"
										alignItems="center"
									>
										<NewsIcon className={classes.tipsIcon} />

										<Divider orientation="vertical" size={0.5} />

										<Typography className={classes.tipsHeaderText} >
											Novidade!
										</Typography>
									</Grid>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Grid
										container
										justifyContent="flex-start"
										alignItems="center"
									>
										<Grid
											item
											xs={12}
										>
											<Typography className={classes.tipsTitleText}>
												Você pode fazer muito mais com o Bot
											</Typography>
										</Grid>
										<Grid
											item
											xs={12}
										>
											<Typography className={classes.tipsContentText} >
												Agora é possível <strong>adicionar vários contatos ao bot</strong> diretamente na aba de contatos. Você pode realizar essa ação utilizando filtros para segmentar seus contatos. Saiba mais {" "}
												<Link
													href={letalkLinks.wikiHowToAddBulkContactsOnBot}
													underline="always"
													target="_blank"
													color="inherit"
												>
													clicando aqui
												</Link>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)
				}

				<Divider size={1} orientation="horizontal" />

				<Grid
					container
					spacing={3}
				>
					{Object.values(chatBotList || {} as CategorizedChatBotFlows).map(triggerWithChatBotFlows => (
						<>
							{triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers?.length > 0 ? (
								<Grid
									item
									xs={12}
									key={triggerWithChatBotFlows.type}
								>
									<Divider orientation="horizontal" size={4} />

									<Grid
										container
										spacing={2}
										direction="column"
									>
										<Grid
											item
											xs={12}
										>
											<Typography
												variant="h4"
												color="textPrimary"
												className={classes.eventTrigger}
											>
												{triggerWithChatBotFlows.type !== "no-triggers" ? "BOTS ACIONADOS QUANDO:" : "BOTS QUE:"}
											</Typography>

											<Divider orientation="horizontal" size={1.5} />

											<Typography
												variant="h4"
												className={classes.descriptionTrigger}
											>
												{chatBotTriggerTranslatedByType[triggerWithChatBotFlows.type]}
											</Typography>
										</Grid>
									</Grid>

									<Divider orientation="horizontal" size={5} />

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											spacing={2}
											direction="column"
										>
											{triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers.map(bot => (
												<Grid
													item
													xs={12}
													key={bot.id}
												>
													<Grid container spacing={2} direction="column">

														<Grid
															item
															xs
														>
															<ChatBotListItem
																chatBotFlow={{
																	active: bot.active,
																	updated_at: bot.updated_at,
																	name: bot.name,
																	id: bot.id,
																	consolidated_data: bot.consolidated_data,
																	tag: bot?.tag
																}}
																chatBotCategory={triggerWithChatBotFlows.type}
																chatBotConstructorPath={`/admin/flow/${bot.id}`}
																webhookTemplateType={bot?.webhook_template?.type}
																onDuplicate={async () => {
																	await handleDuplicateChatBot(bot.id)
																}}
																onConfig={() => handleChangeChatBotConfig(bot.id)}
																onExport={() => handleExportChatBotOpen(bot.id)}
															/>
														</Grid>
													</Grid>
												</Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
							) : null}
						</>
					))}
				</Grid>

				<ActionDialog
					title="Exportar Bot"
					saveText="Copiar código"
					openDialog={handleExportChatBotDialogOpen}
					onSave={handleOnSaveExportChatBot}
					onClose={handleExportChatBotClose}
					fullWidth
				>
					<JsonEditor
						className={classes.disableSelection}
						height="300px"
						defaultValue={chatBotFlowJson}
						readOnly
					/>
				</ActionDialog>

			</Grid>
		</Loading>
	)
}

export default FlowList
