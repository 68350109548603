import React, { useEffect, useState } from "react"
import clsx from "clsx"
import {
	Grid,
	IconButton,
	Link,
	Typography,
	InputAdornment
} from "@material-ui/core"

import {
	Send as SendIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	Divider
} from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useFileUpload, { DefaultUploadFileResponse } from "@/hooks/useFileUpload"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"
import useWABAChat from "@/@integrations/WABA/hooks/useWABAChat"

import ReplyMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/ReplyMessage"
import MediaPreview from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview"
import VoiceInput from "@/pages/Attendance/Chat/ConversationPanel/Input/VoiceInput"
import FileInput from "@/pages/Attendance/Chat/ConversationPanel/Input/FileInput"
import InputBlockMessage from "@/pages/Attendance/Chat/ConversationPanel/Input/InputBlockMessage"
import TextInput from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"
import EmojiPicker from "@/pages/Attendance/Chat/ConversationPanel/Input/EmojiPicker"
import QuickReply from "@/pages/Attendance/Chat/ConversationPanel/Input/QuickReply"
import WABAMessageTemplateInput from "@/@integrations/WABA/components/Chat/WABAMessageTemplate/WABAMessageTemplateInput"
import WABAInputBlockMessage from "@/@integrations/WABA/components/Chat/WABAInputBlockMessage"

import TextEnhancementButton from "@/components/Skynet/TextEnhancementButton"

import { IMessageExtraData, ISendableMessage } from "@/protocols/channel"
import { BuildedMessage } from "@/protocols/messages"

import MediaService, { Media } from "@/services/Media"
import DateService from "@/services/Date"
import MessageProcessingStepsLogService from "@/services/MessageProcessingStepsLog"
import ErrorHandlerService from "@/services/ErrorHandler"
import HardCoded from "@/services/HardCoded"

import { isMediaMessage, buildMessage, generateMessageId } from "@/utils/message"
import { deleteChatMessageSketch, saveChatMessageSketch } from "@/utils/chat"

import Mentions from "@/components/ChatMessageBuilder/Mentions"
import { CustomLinkButtons } from "@/components/ChatMessageBuilder/CustomLink"
import IncludedCustomLinkList from "@/components/ChatMessageBuilder/CustomLink/IncludedCustomLinkList"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/styles"
import { ErrorType } from "@/hooks/useValidation"
import { Message } from "@/protocols/chatGlobalStateProtocol"

type MessageMediaData = {
	name: string
	url: string
	key: string
	file: null | File
}

export type MessageOptionsType = IMessageExtraData["options"]

type InputBlockInfo = {
	inputBlockedText?: string
	inputBlockedLinkText?: string
	inputBlockedLinkAction?: () => Promise<void>
}
type InputBlockType = "alreadyHasAttendance" | "newAttendance" | "noAttendance"

type InputBlockTypeToInfo = Record<InputBlockType, InputBlockInfo>

const TEXT_INPUT_CONTAINER_ELEMENT_ID = "text-input-container"

const Input: React.FC = () => {
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()

	const wabaChat = useWABAChat()
	const classes = useStyles()
	const socket = useSocket()
	const customClasses = useCustomStyles()
	const fileUpload = useFileUpload({ requestPath: "/inbox/channel/chats/media/upload" })

	const currentChatAttendanceStatus = chatGlobalStateStore.chat.current?.attendance?.status

	const isActiveAttendance = currentChatAttendanceStatus === "active"
	const isCurrentUserAttendance = chatGlobalStateStore.chat.current?.attendance?.userId === globalStateStore.user.id

	const canType = isActiveAttendance && isCurrentUserAttendance

	const handleTakeChatAttendance = async () => {
		await chatGlobalStateStore.attendance.takeOnCurrentChat()
	}

	const handleFinishChatAttendance = async () => {
		await chatGlobalStateStore.attendance.finishOnCurrentChat()
	}

	let inputBlockType: InputBlockType
	const inputBlockInfo: InputBlockTypeToInfo = {
		alreadyHasAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa finalizar o atendimento atual.",
			inputBlockedLinkText: "Finalizar o atendimento com esse cliente",
			inputBlockedLinkAction: handleFinishChatAttendance
		},
		newAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa assumir o atendimento.",
			inputBlockedLinkText: "Assumir um atendimento com esse cliente",
			inputBlockedLinkAction: handleTakeChatAttendance
		},
		noAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa iniciar o atendimento.",
			inputBlockedLinkText: "Iniciar um atendimento com esse cliente",
			inputBlockedLinkAction: handleTakeChatAttendance
		}
	}

	if (!canType) {
		if (isActiveAttendance && !isCurrentUserAttendance) {
			inputBlockType = "alreadyHasAttendance"
		} else if (currentChatAttendanceStatus === "waiting") {
			inputBlockType = "newAttendance"
		} else {
			inputBlockType = "noAttendance"
		}
	}

	const [messageOptions, setMessageOptions] = useState<MessageOptionsType>({})

	const [customLink, setCustomLink] = useState<IMessageExtraData["customLink"]>()

	const [openCustomLinkDialog, setOpenCustomLinkDialog] = useState(false)

	const currentChatId = chatGlobalStateStore?.chat?.current?.id

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			if (chatGlobalStateStore.conversationPanel.media.current.length > 0) {
				chatGlobalStateStore.conversationPanel.media.clear()
			}

			if (chatGlobalStateStore.conversationPanel.replyMessage.current) {
				chatGlobalStateStore.conversationPanel.replyMessage.clear()
			}
		}
	}

	window.addEventListener(
		"keydown",
		eventKeyDownHandler
	)

	const onMessageSent = (sendableMessage: ISendableMessage, sentMessage: Message) => {
		/**
		 * Since we created a temporary message, we remove it
		 * in order to add the correct one that has its data
		 * synced with database.
		 */
		chatGlobalStateStore.message.removeById([sendableMessage.id])
		chatGlobalStateStore.message.add(sentMessage)

		const isProcessingStepsDialogOpenedForTempMessage = chatGlobalStateStore.message.processingStepsDialog.messageId === sendableMessage.id

		if (isProcessingStepsDialogOpenedForTempMessage) {
			chatGlobalStateStore.message.processingStepsDialog.open(sentMessage.id)
		}
	}

	const handleSaveMessageSketch = (text: string) => {
		if (currentChatId) {
			saveChatMessageSketch(text, currentChatId)
		}
	}

	const handleSendMediaMessage = async (media: Media, extraData?: IMessageExtraData) => {
		const messageId = generateMessageId()

		try {
			const messageProcessingStepsLog = new MessageProcessingStepsLogService({
				inboxChannelChatMessageTempId: messageId,
				onStepChanged: (messageId, processingSteps) => chatGlobalStateStore.message.updateById(messageId, { inboxChannelChatMessageLog: { processingSteps } })
			})

			if (!chatGlobalStateStore.chat.current || !media) {
				return
			}

			const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current

			const newMessage = await messageProcessingStepsLog.trackStep("create_temporary_message", async () => (
				buildMessage(messageId, {
					inboxChannelChatId: id,
					inboxChannelId,
					type: media.type,
					replyMessage: chatGlobalStateStore.conversationPanel.replyMessage.current
				})
			))

			const mediaData: MessageMediaData = {
				name: "",
				url: "",
				key: "",
				file: null
			}

			if (newMessage.type === "file") {
				newMessage.fileName = media.data.name
			}

			mediaData.name = media.data.name

			if (media.url) {
				mediaData.url = media.url
				mediaData.key = media.key as string
				newMessage.content = media.url
			} else {
				mediaData.file = media.data
				newMessage.content = URL.createObjectURL(media.data)
			}

			messageProcessingStepsLog.trackStep("show_temporary_message_on_frontend", async () => {
				await chatGlobalStateStore.message.add({
					...newMessage,
					uploadingMedia: true
				})
			})

			if (mediaData.file) {
				await messageProcessingStepsLog.trackStep("save_media_on_server", async () => {
					const result = await fileUpload.uploadFile<DefaultUploadFileResponse>(mediaData.file as File, "media", {
						inboxChannelChatId: id,
						mediaType: newMessage.type
					}, {
						onUploadProgressUpdate: (progressInPercentage) => {
							chatGlobalStateStore.message.updateById(newMessage.id, {
								uploadProgressInPercentage: progressInPercentage
							})
						}
					})

					mediaData.url = result.url as string
					mediaData.key = result.key as string
					newMessage.content = result.url as string
				})
			}

			await messageProcessingStepsLog.trackStep("communicate_channel_server_from_frontend", async () => {
				const message = await socket.sendMessage({
					mediaName: mediaData.name,
					mediaUrl: mediaData.url,
					mediaKey: mediaData.key,
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					content: "",
					type: newMessage.type,
					feature: "attendance",
					replyMessageId: newMessage.replyMessage?.id,
					extraData: {
						...extraData,
						options: {
							mentions: messageOptions?.mentions
						}
					},
					tempMessageId: newMessage.id,
					processingSteps: messageProcessingStepsLog.currentProcessingSteps
				})

				onMessageSent(newMessage, {
					...newMessage,
					...message,
					uploadingMedia: false,
					/**
					 * We only add caption data to file messages,
					 * since it is the way whatsapp use to handle filename.
					 */
					...(newMessage.type !== "file" && { caption: "" })
				})
			})
		} catch (error) {
			const err = error as ErrorType
			chatGlobalStateStore.message.updateById(messageId, {
				status: "not-sent", inboxChannelChatMessageLog: { error: err?.message }
			})
			ErrorHandlerService.handle(error as Error)
		}
	}

	const getActiveChannelIndex = function () {
		const activeChannelId = Number(globalStateStore?.currentChannel?.id)
		const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)
		return activeChannelIndex
	}

	const handleSendTextMessage = async (text: string, extraData?: IMessageExtraData) => {
		const messageId = generateMessageId()
		try {
			const textWithoutWhiteSpaceOnEnd = text.trim()
			const isValidText = Boolean(textWithoutWhiteSpaceOnEnd)
			const messageProcessingStepsLog = new MessageProcessingStepsLogService({
				inboxChannelChatMessageTempId: messageId,
				onStepChanged: (messageId, processingSteps) => chatGlobalStateStore.message.updateById(messageId, { inboxChannelChatMessageLog: { processingSteps } })
			})

			if (!chatGlobalStateStore.chat.current || !isValidText) {
				return
			}

			const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current
			const activeChannelIndex = getActiveChannelIndex()
			const actualChatSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.chat
			const signatureSettings = actualChatSettings?.signature
			const signatureText = chatGlobalStateStore.chat.current.type === "user" ? signatureSettings?.individual_signature_text : signatureSettings?.group_signature_text

			const newMessage = await messageProcessingStepsLog.trackStep("create_temporary_message", async () => (
				buildMessage(messageId, {
					inboxChannelChatId: id,
					inboxChannelId,
					type: "text",
					replyMessage: chatGlobalStateStore.conversationPanel.replyMessage.current
				})
			))

			newMessage.content = textWithoutWhiteSpaceOnEnd

			if (customLink?.buttons && customLink?.buttons?.length > 0) {
				newMessage.type = "custom-link"
			}
			messageProcessingStepsLog.trackStep("show_temporary_message_on_frontend", async () => {
				await chatGlobalStateStore.message.add({
					...newMessage,
					content: `${signatureText}${newMessage.content}`
				})
			})

			await messageProcessingStepsLog.trackStep("communicate_channel_server_from_frontend", async () => {
				const message = await socket.sendMessage({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					content: newMessage.content,
					type: newMessage.type,
					feature: "attendance",
					replyMessageId: newMessage.replyMessage?.id,
					extraData: {
						...extraData,
						options: {
							mentions: messageOptions?.mentions
						},
						customLink
					},
					tempMessageId: newMessage.id,
					processingSteps: messageProcessingStepsLog.currentProcessingSteps
				})

				onMessageSent(newMessage, {
					...newMessage,
					...message
				})
			})
		} catch (error) {
			const err = error as ErrorType
			chatGlobalStateStore.message.updateById(messageId, {
				status: "not-sent", inboxChannelChatMessageLog: { error: err?.message }
			})
			ErrorHandlerService.handle(error as Error)
		}
	}

	const handleSendBatchMessages = () => {
		const attendantClickedOnSendMessageFrontEndButtonAt = DateService.currentWorldDate()

		chatGlobalStateStore.conversationPanel.scrolledBottomList.enableAutoScrollBottom()

		const [media] = chatGlobalStateStore.conversationPanel.media.current
		const text = chatGlobalStateStore.conversationPanel.textInput.value

		if (media) {
			handleSendMediaMessage(media, { attendantClickedOnSendMessageFrontEndButtonAt })
		}

		if (text) {
			handleSendTextMessage(text, { attendantClickedOnSendMessageFrontEndButtonAt })
			deleteChatMessageSketch(chatGlobalStateStore.chat.current?.id as number)
			setCustomLink({})
		}

		chatGlobalStateStore.conversationPanel.media.clear()
		chatGlobalStateStore.conversationPanel.textInput.clear()
		chatGlobalStateStore.conversationPanel.replyMessage.clear()

		setMessageOptions({})
	}

	const onQuickReplySelect = (message: BuildedMessage) => {
		if (isMediaMessage(message.type)) {
			const media = MediaService.buildMediaByUrl({
				name: message.mediaName as string,
				type: message.type,
				url: message.content,
				key: message.mediaKey
			})

			chatGlobalStateStore.conversationPanel.media.add([media])
		} else {
			chatGlobalStateStore.conversationPanel.textInput.addText(message.content)
		}

		if (message.extraData?.customLink?.buttons && message.extraData?.customLink?.buttons?.length > 0) {
			setCustomLink({ ...customLink, buttons: message.extraData?.customLink?.buttons })
		}
	}

	const handleAddMedia = (medias: Media[]) => {
		chatGlobalStateStore.conversationPanel.media.add(medias)
		chatGlobalStateStore.conversationPanel.textInput.focus()
	}

	// const handleOnCustomLinkInputAddLink = (value: CustomLinkButtons) => {
	// 	setCustomLink({
	// 		buttons: [
	// 			value
	// 		]
	// 	})
	// }

	const handleOnCustomLinkInputEditLink = (value: CustomLinkButtons) => {
		setCustomLink({
			buttons: [
				value
			]
		})
		setOpenCustomLinkDialog(true)
	}

	const handleOnDeleteCustomLink = async (index: number) => {
		const buttons = customLink?.buttons

		buttons?.splice(index, 1)

		setCustomLink({
			buttons: [
				...(buttons || [])
			]
		})
	}

	useEffect(() => {
		setMessageOptions({})
	}, [currentChatId])

	return useCustomMemo(() => (
		<Grid
			container
			alignItems="center"
			className={customClasses.chatInputContainer}
		>
			{chatGlobalStateStore.chat.current && (
				<>
					<InputBlockMessage
						blocked={!canType}
					>
						<Typography
							variant="body1"
							color="textPrimary"
							align="left"
						>
							{inputBlockInfo[inputBlockType]?.inputBlockedText}
							{" "}
							<Link
								onClick={inputBlockInfo[inputBlockType]?.inputBlockedLinkAction}
								className={classes?.takeAttendanceText}
							>
								{inputBlockInfo[inputBlockType]?.inputBlockedLinkText}
							</Link>
						</Typography>
					</InputBlockMessage>

					{(chatGlobalStateStore.chat.current?.channelType === "waba" && canType) && (
						<WABAInputBlockMessage
							blocked={chatGlobalStateStore.chat.current?.wabaChannelChatCommunicationPermission?.status !== "active"}
							WABAMessageTemplateSelectorProps={{
								inboxChannelId: chatGlobalStateStore.chat.current.inboxChannelId,
								onClose: chatGlobalStateStore.conversationPanel.textInput.focus,
								onSelect: (wabaChannelMessageTemplate) => wabaChat.onWABAMessageTemplateSelect({
									wabaChannelMessageTemplate: wabaChannelMessageTemplate,
									onMessageSent: onMessageSent,
									updateMessageOnStore: chatGlobalStateStore.message.updateById,
									addMessageOnStore: chatGlobalStateStore.message.add,
									chatOnStore: chatGlobalStateStore.chat.current,
									replyMessage: chatGlobalStateStore.conversationPanel.replyMessage.current,
									messageFeature: "attendance"
								})
							}}
						/>
					)}

					{chatGlobalStateStore.conversationPanel.replyMessage.current && (
						<>
							<Grid
								container
								alignItems="center"
								justify="center"
							>
								<Grid
									container
									className={classes.inputItemContainer}
								>
									<ReplyMessage
										id={chatGlobalStateStore.conversationPanel.replyMessage.current.id}
										senderName={chatGlobalStateStore.client.current?.nickname || ""}
										content={chatGlobalStateStore.conversationPanel.replyMessage.current.content}
										sentByCustomer={chatGlobalStateStore.conversationPanel.replyMessage.current.sentByCustomer}
										type={chatGlobalStateStore.conversationPanel.replyMessage.current.type}
										caption={chatGlobalStateStore.conversationPanel.replyMessage.current.caption}
										extraData={chatGlobalStateStore.conversationPanel.replyMessage.current.extraData}
									/>
								</Grid>

								<IconButton
									onClick={() => chatGlobalStateStore.conversationPanel.replyMessage.clear()}
								>
									<CloseIcon />
								</IconButton>
							</Grid>

							<Divider orientation="horizontal" size={1} />
						</>
					)}

					<MediaPreview
						medias={chatGlobalStateStore.conversationPanel.media.current}
						onClear={() => chatGlobalStateStore.conversationPanel.media.clear()}
					/>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<Grid
							container
							id={TEXT_INPUT_CONTAINER_ELEMENT_ID}
						>
							<TextInput
								onSubmit={handleSendBatchMessages}
								disabled={!canType}
								ref={chatGlobalStateStore.conversationPanel.textInput.ref}
								onMedia={medias => chatGlobalStateStore.conversationPanel.media.add(medias)}
								chatId={chatGlobalStateStore.chat.current.id}
								onChange={handleSaveMessageSketch}
								getInputProps={textInputHandler => ({
									endAdornment: (
										<InputAdornment position="end">
											<TextEnhancementButton
												TextEnhancementDialogProps={{
													anchorElement: document.getElementById(TEXT_INPUT_CONTAINER_ELEMENT_ID)
												}}
												text={textInputHandler.getCurrentValue()}
												onReplace={text => textInputHandler.replaceText(text)}
												IconButtonSvgIconProps={{
													className: clsx({
														[classes.fadeInOutAnimation]: Boolean(textInputHandler.getCurrentValue())
													})
												}}
											/>
										</InputAdornment>
									)
								})}
							/>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<IncludedCustomLinkList
						links={customLink?.buttons}
						onDelete={handleOnDeleteCustomLink}
						onClick={handleOnCustomLinkInputEditLink}
					/>

					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
					>
						{chatGlobalStateStore.chat.current?.channelType === "waba" && (
							<WABAMessageTemplateInput
								WABAMessageTemplateSelectorProps={{
									inboxChannelId: chatGlobalStateStore.chat.current.inboxChannelId,
									onClose: chatGlobalStateStore.conversationPanel.textInput.focus,
									onSelect: (wabaChannelMessageTemplate) => wabaChat.onWABAMessageTemplateSelect({
										onMessageSent: onMessageSent,
										wabaChannelMessageTemplate: wabaChannelMessageTemplate,
										chatOnStore: chatGlobalStateStore.chat.current,
										addMessageOnStore: chatGlobalStateStore.message.add,
										updateMessageOnStore: chatGlobalStateStore.message.updateById,
										replyMessage: chatGlobalStateStore.conversationPanel.replyMessage.current,
										messageFeature: "attendance"
									})
								}}
							/>
						)}

						{/* <CustomLinkInput
							setExtraData={handleOnCustomLinkInputAddLink}
							open={openCustomLinkDialog}
							setOpen={setOpenCustomLinkDialog}
							disabled={true}
							linkToEdit={customLink?.buttons ? customLink?.buttons[0] : undefined}
						/> */}
						{
							HardCoded.checkFeatureFlag("groupMentions") && <Mentions
								onSave={async (optionsSelected) => optionsSelected && setMessageOptions({
									...messageOptions,
									...{
										mentions: {
											...messageOptions?.mentions,
											...optionsSelected
										}
									}
								})}
								disabled={currentChatAttendanceStatus !== "active" || chatGlobalStateStore.chat.current?.type !== "group"}
								mentionsSelected={{
									...messageOptions?.mentions
								}}
							/>
						}
						<QuickReply
							onSelect={onQuickReplySelect}
							onClose={chatGlobalStateStore.conversationPanel.textInput.focus}
							shortcutEnabled={Boolean(chatGlobalStateStore.chat.current)}
							substituteVariables={true}
							withoutTriggerHistoryBackEvent={true}
							chatId={chatGlobalStateStore.chat.current.id}
							disabled={!canType}
						/>

						<VoiceInput
							onMedia={audio => chatGlobalStateStore.conversationPanel.media.add([audio])}
							disabled={!canType}
						/>

						<EmojiPicker
							onEmoji={(emoji) => chatGlobalStateStore.conversationPanel.textInput.ref.current?.addTextByCursor(emoji)}
							disabled={!canType}
						/>

						<FileInput
							onMedia={handleAddMedia}
							disabled={!canType}
						/>

						<IconButton
							onClick={handleSendBatchMessages}
							disabled={!canType}
						>
							<SendIcon />
						</IconButton>
					</Grid>
				</>
			)}
		</Grid >
	), [
		JSON.stringify(customLink),
		JSON.stringify(messageOptions),
		openCustomLinkDialog,
		canType,
		currentChatAttendanceStatus,
		chatGlobalStateStore.chat.current?.id,
		chatGlobalStateStore.chat.current?.status,
		chatGlobalStateStore.conversationPanel.media.current.length,
		chatGlobalStateStore.conversationPanel?.replyMessage?.current?.id,
		chatGlobalStateStore.chat.current?.wabaChannelChatCommunicationPermission?.status
	])
}

export default Input
