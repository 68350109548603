const colors = {
	palette: {
		primary: "#7E0DD6",
		confirm: "#1AB956",
		error: "#D84910",
		tag1: "#5CC395",
		tag2: "#5BD1D7",
		tag3: "#F1C34E",
		link: "#007CBA",
		background: "#F8F9FA",
		button1: "#03DAC5",
		borderColor: "#7E0DD61F",
		activeAttendance: "#06D755",
		inactiveAttendance: "#C1C1C1",
		whatsappSentMessage: "#E7FFDB",
		triggeredMessage: "#9350C8",
		triggeredMessageLighter: "#E1D3F8"
	},
	grayScale: {
		1: "#212429",
		2: "#DDE2E5",
		3: "#ACB5BD",
		4: "#495057",
		5: "#969696",
		6: "#ABABAB",
		7: "#BFBFBF",
		8: "#D0D0D0",
		9: "#E1E1E1",
		10: "#F0F0F0",
		11: "#FFFFFF",
		12: "#E0E6ED"
	},
	soft: {
		yellow: "#FBEECC",
		purple: "#E1D3F8",
		blue: "#D3E4F8"
	},
	unrelated: {
		"0DD682": "#0DD682",
		EFEFEF: "#EFEFEF",
		F8F8F8: "#F8F8F8",
		F9F9F9: "#F9F9F9",
		EFC7CB: "#EFC7CB",
		F9D3B7: "#F9D3B7",
		"5ABE66": "#5ABE66",
		49505780: "#49505780",
		F6F6F6: "#F6F6F6",
		E5E0DB: "#E5E0DB",
		C5BEB7: "#C5BEB7",
		818285: "#818285",
		696969: "#696969",
		C3D60D: "#C3D60D",
		"4950570D": "#4950570D",
		f44336: "#f44336",
		a5a5a5: "#a5a5a5",
		FF2E42: "#FF2E42",
		FFCCD1: "#FFCCD1",
		"43B79D": "#43B79D",
		CCE7E1: "#CCE7E1",
		FD8C44: "#FD8C44",
		FDDFCC: "#FDDFCC",
		FBBF23: "#FBBF23",
		F3F3F3: "#F3F3F3",
		DADADA: "#DADADA",
		"8F8F8F": "#8F8F8F",
		D5D5D5: "#D5D5D5",
		"9D55D7": "#9D55D7",
		F3DBFF: "#F3DBFF",
		FFDBE4: "#FFDBE4",
		DBF2FF: "#DBF2FF",
		ADADAD: "#ADADAD",
		F1F1F1: "#F1F1F1",
		E809AE: "#E809AE",
		F8DAD3: "#F8DAD3",
		D4F8D3: "#D4F8D3",
		DCF5D9: "#DCF5D9",
		D32F2F: "#D32F2F",
		586069: "#586069",
		f6f8fa: "#f6f8fa",
		757575: "#757575",
		"50C7AC": "#50C7AC",
		CC456D: "#CC456D",
		EBAF78: "#EBAF78",
		E6E6E4: "#E6E6E4",
		D65745: "#D65745",
		F0F2F5: "#F0F2F5",
		F79722: "#F79722",
		AEE2C2: "#AEE2C2",
		F85B37: "#F85B37",
		F6FAFD: "#F6FAFD",
		D657451A: "#D657451A",
		E3E6E8: "#E3E6E8",
		818181: "#818181",
		A1AAB3: "#A1AAB3",
		C6C9CB: "#C6C9CB",
		C9C9C9: "#C9C9C9",
		b80087: "#b80087",
		"000000": "#000000",
		E64747: "#E64747",
		B0E3FF: "#B0E3FF",
		F0F2F4: "#F0F2F4",
		990101: "#990101",
		FFE8E8: "#FFE8E8",
		E7F8F5: "#E7F8F5",
		"12C29E": "#12C29E",
		EFEDFD: "#EFEDFD",
		"4C37D4": "#4C37D4",
		E6F6F9: "#E6F6F9",
		"07ABC3": "#07ABC3",
		EDF2FF: "#EDF2FF",
		"004CFF": "#004CFF",
		f4f5f5: "#f4f5f5",
		FCFCFD: "#FCFCFD",
		F9F3FD: "#F9F3FD",
		cccffb: "#cccffb",
		DBEBFF: "#DBEBFF",
		"4B74A8": "#4B74A8",
		E647471F: "#E647471F",
		"50CA81": "#50CA81",
		"807c7c": "#807c7c",
		"3E3E3E": "#3E3E3E",
		E7E7E7: "#E7E7E7",
		EDEDED: "#EDEDED",
		EBEBEB: "#EBEBEB",
		F5A46B: "#F5A46B",
		"009688": "#009688",
		"8D9BA8": "#8D9BA8",
		FE9E59: "#FE9E59",
		FF1919: "#FF1919",
		FF5656: "#FF5656",
		"47ED65": "#47ED65",
		959595: "#959595",
		FEF6EB: "#FEF6EB",
		FA685E: "#FA685E",
		FBBF27: "#FBBF27",
		b7b7b7: "#b7b7b7",
		FFFFFF80: "#FFFFFF80",
		FF4343: "#FF4343",
		EBFAF5: "#EBFAF5",
		FFF0D3: "#FFF0D3",
		"35CD96": "#35CD96",
		"6BCBEF": "#6BCBEF",
		CBCBCB: "#CBCBCB",
		"47BDFF": "#47BDFF",
		FF8AA7: "#FF8AA7",
		"94FF5E": "#94FF5E",
		e1e4e8: "#e1e4e8",
		dfe2e5: "#dfe2e5",
		ced4da: "#ced4da",
		79797900: "#79797900",
		d9d9d9: "#d9d9d9",
		EBEDEC: "#EBEDEC",
		"00000024": "#00000024",
		"02c3b0": "#02c3b0",
		"00ccff": "#00ccff",
		D60D22: "#D60D22",
		CACACA: "#CACACA",
		F1C34E: "#F1C34E",
		F1C34E00: "#F1C34E00",
		D6574559: "#D6574559",
		"8A8A8A": "#8A8A8A",
		E6EBF1: "#E6EBF1",
		FCF9EE: "#FCF9EE",
		"4950571A": "#4950571A",
		"2C2C2C80": "#2C2C2C80",
		"686D73": "#686D73",
		565656: "#565656",
		F2E6FB: "#f2e6fb",
		"7E0DD633": "#7E0DD633",
		E6D6F5: "#E6D6F5",
		D92A29: "#D92A29",
		F9F6FE: "#F9F6FE",
		ECCA71: "#ECCA71",
		66666633: "#66666633",
		"0000000D": "#0000000D",
		E0E0E0: "#E0E0E0"
	}
}

export default colors
