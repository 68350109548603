import { useGlobalStateStore } from "@/store/GlobalState"
import { Grid, Tooltip } from "@material-ui/core"
import React from "react"
import { InfoOutlined as InfoIcon } from "@material-ui/icons"
import colors from "@/styles/colors"
import useStyles from "@/components/DisableComponentByChannel/styles"
import HardCoded from "@/services/HardCoded"

type DisableComponentByChannelVariants = "hover" | "with-icon"

type DisableComponentByChannelProps = {
	children: React.ReactNode,
	otherRulesToDisabled?: boolean,
	tooltipConfig?: {
		tooltipText?: string
		tooltipDisableRules?: boolean
	}
	variant: DisableComponentByChannelVariants
}

const DisableComponentByChannel: React.FC<DisableComponentByChannelProps> = (props) => {
	const {
		children,
		otherRulesToDisabled,
		tooltipConfig,
		variant
	} = props

	const classes = useStyles()

	const { instance } = useGlobalStateStore()
	const canImportBots = HardCoded.checkFeatureFlag("canImportBotsOnWaba")
	const isWabaChannel = instance.current_channel_type === "waba" && !canImportBots

	const tooltipMessage = isWabaChannel
		? "Essa funcionalidade está indisponível na WABA."
		: tooltipConfig?.tooltipText ?? ""

	let isDisabled = false

	if (isWabaChannel) {
		if (otherRulesToDisabled !== undefined) {
			isDisabled = otherRulesToDisabled
		} else {
			isDisabled = true
		}
	}

	const getComponentVariant = (variant: DisableComponentByChannelVariants) => {
		const componentVariants: Record<DisableComponentByChannelVariants, React.ReactElement> = {
			hover: (
				<Tooltip
					title={tooltipMessage}
					disableHoverListener={isWabaChannel ? false : tooltipConfig?.tooltipDisableRules}
				>
					<span style={{ flex: 1 }}>
						{React.cloneElement(children as React.ReactElement, {
							disabled: isWabaChannel || otherRulesToDisabled,
							isDisabled: isWabaChannel || otherRulesToDisabled,
							disable: isWabaChannel || otherRulesToDisabled
						})}
					</span>
				</Tooltip>
			),
			"with-icon": (
				<Grid
					container
					className={classes.container}
					justifyContent="space-between"
					alignItems="center"
				>
					<span style={{ flex: 1 }}>
						{React.cloneElement(children as React.ReactElement, {
							isDisabled: isDisabled,
							disabled: isDisabled,
							disable: isDisabled
						})}
					</span>

					{isDisabled && (
						<Tooltip
							title={tooltipMessage}
							disableHoverListener={!isDisabled || tooltipConfig?.tooltipDisableRules}
						>
							<InfoIcon className={classes.infoIconSize} htmlColor={colors.grayScale[3]} />
						</Tooltip>
					)}
				</Grid>
			)
		}

		return componentVariants[variant]
	}

	return (
		<>
			{getComponentVariant(variant)}
		</>
	)
}

export default DisableComponentByChannel
