import React from "react"
import {
	Grid,
	Divider as MuiDivider
} from "@material-ui/core"

import { Divider } from "@/components"

import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleValidationSlug,
	ChatBotFlowBlockRuleValidation,
	ChatBotFlowBlockRuleValidationType
} from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

import {
	VALIDATION_TYPE_NAME
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/genericConditionConstants"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/styles"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { getValidationValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRuleValidation"
import { isLastItem } from "@/utils/array"
import colors from "@/styles/colors"

type CustomValidationRenderParamProps<Value> = {
	value: Value
	validation: ChatBotFlowBlockRuleValidation
	constructionResources: ConstructionResources
}

type ValidationRenderItem<Value> = {
	render: React.FC<CustomValidationRenderParamProps<Value>>
}

export type CustomValidationRenderValue = {
	firstItem: ValidationRenderItem<string | number>
	comparisonItem?: ValidationRenderItem<ChatBotFlowBlockRuleValidationType>
	secondItem?: ValidationRenderItem<string | number>
}

type CustomValidationRender = {
	[key in ChatBotFlowBlockRuleValidationSlug]?: CustomValidationRenderValue
}

type GenericConditionSectionContainerProps = {
	customValidationRender?: CustomValidationRender
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	chatBotFlowBlockId?: number
}

export const CUSTOM_VALIDATION_TYPE_NAME: Record<ChatBotFlowBlockRuleValidationType, string> = {
	...VALIDATION_TYPE_NAME,
	equals: "Igual a"
}

const GenericConditionSectionContainer: React.FC<GenericConditionSectionContainerProps> = (props) => {
	const {
		customValidationRender,
		chatBotFlowBlockRule,
		chatBotFlowBlockId
	} = props

	const classes = useStyles()
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	return (
		<BlockBodySectionContainer
			title="Se uma das condições for verdadeira"
			backgroundColor={colors.unrelated.F6FAFD}
			chatBotFlowBlockId={chatBotFlowBlockId}
			errorType="condition_block_missing_option"
			text={(
				<Grid
					container
					className={classes.container}
				>
					{chatBotFlowBlockRule.validations.map((validation, index) => {
						const validationRender = customValidationRender?.[validation.slug as ChatBotFlowBlockRuleValidationSlug]

						const validationTypeName = CUSTOM_VALIDATION_TYPE_NAME[validation.type]
						const validationValue = getValidationValue(validation)

						return (
							<>
								{
									validationRender?.firstItem?.render?.({
										constructionResources: chatBotFlowConstructorStore.constructionResources,
										value: validation.first_param,
										validation
									})
								}

								{(
									validationRender?.comparisonItem?.render?.({
										constructionResources: chatBotFlowConstructorStore.constructionResources,
										value: validation.type,
										validation
									})
								) || (
									<ContrastChip
										backgroundColor={colors.unrelated.E3E6E8}
										title={String(validationTypeName)}
									/>
								)}

								<Divider orientation="horizontal" size={0.5} />

								{(
									validationRender?.secondItem?.render?.({
										constructionResources: chatBotFlowConstructorStore.constructionResources,
										value: validation.second_param,
										validation
									})
								) || (
									<ContrastChip
										backgroundColor={colors.unrelated.B0E3FF}
										title={String(validationValue)}
									/>
								)}

								{!isLastItem(index, chatBotFlowBlockRule.validations.length) && (
									<>
										<Divider orientation="horizontal" size={2} />

										<MuiDivider
											className={classes.divider}
											variant="fullWidth"
										/>

										<Divider orientation="horizontal" size={2} />
									</>
								)}
							</>
						)
					})}
				</Grid>
			)}
		/>
	)
}

export default GenericConditionSectionContainer
