import React, { useState } from "react"

import PortletWithSplitContent from "@/components/PortletWithSplitContent"

import newColors from "@/styles/newColors"
import useStyles from "@/pages/AccessBlockedPage/styles"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { Divider, Navbar } from "@/components"

import { celcashLinks } from "@/utils/link"
import { useGlobalStateStore } from "@/store/GlobalState"

import UserInInstanceService from "@/services/UserInInstance"

const AccessBlockedTestPage: React.FC = () => {
	const [loading, setLoading] = useState(false)
	const classes = useStyles()

	const globalStateStore = useGlobalStateStore()
	const isUserAdmin = globalStateStore.instance.user_in_instance_role.code === "admin"

	async function handleRedirectToRegularizedAccount () {
		setLoading(true)
		await UserInInstanceService.requestAccountRegularization()
		window.open(celcashLinks.paymentDataPage, "_blank")
		setLoading(false)
	}

	return (
		<Grid
			container
			className={classes.container}
		>
			<Navbar
				title="Dados da conta"
				hiddenComponents={{
					menuUserData: true,
					indicationMemberButton: true
				}}
			></Navbar>

			<Grid
				item
				className={classes.portlet}
			>
				<PortletWithSplitContent
					title={{
						value: "Acesso bloqueado",
						logo: logoImg,
						backgroundColor: newColors.red[500],
						textColor: newColors.grey[0]
					}}
					body={{
						backgroundColor: newColors.grey[0]
					}}
				>
					{isUserAdmin ? (
						<Typography
							align="center"
							variant="subtitle1"
						>
							Clique no botão abaixo e regularize sua conta para continuar acessando a Letalk. Mas, atenção, é obrigatório usar o mesmo <strong>CNPJ</strong> e <strong>E-mail</strong> utilizados no momento da compra.
						</Typography>
					) : (
						<Typography
							align="center"
							variant="subtitle1"
						>
							Seu acesso ao sistema está temporariamente bloqueado. Para mais informações e para regularizar a situação, pedimos que entre em contato diretamente com o seu gestor. Agradecemos pela compreensão e estamos à disposição para auxiliar no que for necessário.
						</Typography>
					)}

					<Divider
						orientation="horizontal"
						size={3}
					/>

					{isUserAdmin && (
						<>
							<Divider
								orientation="horizontal"
								size={3}
							/>
							<Button
								color="primary"
								variant="contained"
								type='submit'
								onClick={handleRedirectToRegularizedAccount}
								endIcon={loading && <CircularProgress size={20} color={"inherit"} />}
								disabled={loading}
								fullWidth
							>
								REGULARIZAR MINHA CONTA
							</Button>
						</>
					)}
				</PortletWithSplitContent>
			</Grid>
		</Grid>
	)
}

export default AccessBlockedTestPage
