import React from "react"

import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const ConfirmMessageBlastScheduleOutsideChannelSettingsHoursSkeleton = () => (
	<Grid
		container
		spacing={2}
		style={{ padding: "0px 10px 10px" }}
		xs={12}
	>
		<Grid item>
			<Skeleton width={500} height={70} />
		</Grid>

		<Grid container direction="column" style={{ padding: "0px 10px" }}>
			<Grid item>
				<Skeleton width={420} height={25} />
			</Grid>
			<Grid item>
				<Skeleton width={450} height={25} />
			</Grid>
			<Grid item>
				<Skeleton width={350} height={25} />
			</Grid>
		</Grid>

		<Grid container direction="column" style={{ padding: "0px 10px", marginTop: 12 }}>
			<Grid item>
				<Skeleton width={410} height={25} />
			</Grid>
			<Grid item>
				<Skeleton width={380} height={25} />
			</Grid>
			<Grid item>
				<Skeleton width={490} height={25} />
			</Grid>
		</Grid>

		<Grid container direction="column" style={{ padding: "0px 10px", marginTop: 24 }}>
			<Grid item>
				<Skeleton width={300} height={25} />
			</Grid>
			<Grid item>
				<Skeleton width={400} height={25} />
			</Grid>
		</Grid>
	</Grid>
)
export default ConfirmMessageBlastScheduleOutsideChannelSettingsHoursSkeleton
