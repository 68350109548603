import { IChat, IMessage } from "@/protocols/channel"

import { isSameTimestamp } from "@/utils/time"

import StorageService from "@/services/Storage"
import { Chat, Message } from "@/protocols/chatGlobalStateProtocol"

export const getChatInfo = (chat: Chat) => {
	const lastMessage = chat.lastMessage

	const snoozeTime = chat.schedule?.time ? new Date(chat.schedule?.time) : null

	return {
		lastMessage,
		lastMessageDate: lastMessage?.createdAt ? new Date(lastMessage?.createdAt) : null,
		snoozeTime: chat.extraData?.awakeningSnooze ? snoozeTime : null
	}
}

const mostRecentDateBetweenSnoozeAndMessage = (snoozeTime: Date | null, lastMessageDate: Date | null) => {
	if (snoozeTime && lastMessageDate) {
		const formatLastMessageDate = +lastMessageDate
		const formatSnoozeDate = +snoozeTime

		return Math.max(formatLastMessageDate, formatSnoozeDate)
	} else if (snoozeTime) {
		return +snoozeTime
	} else if (lastMessageDate) {
		return +lastMessageDate
	}

	return 0
}

export const sortChatByLastSnoozeDateAndLastMessageDate = (chatA: Chat, chatB: Chat) => {
	const chatAInfo = getChatInfo(chatA)
	const chatBInfo = getChatInfo(chatB)

	/**
	 * Keep chats with no last message in the end of the list.
	 */
	if (!chatAInfo.lastMessageDate && !chatAInfo.snoozeTime) {
		return 1
	} else if (!chatBInfo.lastMessageDate && !chatBInfo.snoozeTime) {
		return -1
	}

	const lastDateBetweenSnoozeAndMessageA = mostRecentDateBetweenSnoozeAndMessage(chatAInfo.snoozeTime, chatAInfo.lastMessageDate)
	const lastDateBetweenSnoozeAndMessageB = mostRecentDateBetweenSnoozeAndMessage(chatBInfo.snoozeTime, chatBInfo.lastMessageDate)

	const descendingOrder = lastDateBetweenSnoozeAndMessageB - lastDateBetweenSnoozeAndMessageA

	return descendingOrder
}

export const sortChatByLastMessageDate = (chatA: Chat, chatB: Chat) => {
	const chatAInfo = getChatInfo(chatA)
	const chatBInfo = getChatInfo(chatB)

	/**
	 * Keep chats with no last message in the end of the list.
	 */
	if (!chatAInfo.lastMessageDate) {
		return 1
	} else if (!chatBInfo.lastMessageDate) {
		return -1
	}

	const lastMessageDateA = +chatAInfo.lastMessageDate
	const lastMessageDateB = +chatBInfo.lastMessageDate

	const descendingOrder = lastMessageDateB - lastMessageDateA

	return descendingOrder
}

export const sortChatByLastAttendanceCreationDate = (chatA: IChat, chatB: IChat) => {
	const lastAttendanceCreationDateA = +new Date(chatA.attendance.createdAt as string)
	const lastAttendanceCreationDateB = +new Date(chatB.attendance.createdAt as string)

	const ascendingOrder = lastAttendanceCreationDateA - lastAttendanceCreationDateB

	return ascendingOrder
}

export const sortChats = (
	// attendanceType: AttendanceType
) => (chatA: Chat, chatB: Chat) => {
	/**
	 * The filter below maybe needed sometime in case we start
	 * filtering queue chats in another way again. So we kept
	 * it commented.
	 */
	// const isNewChatFilterSelected = attendanceType === "queue"

	// if (isNewChatFilterSelected) {
	// 	return sortChatByLastAttendanceCreationDate(chatA, chatB)
	// } else {
	// 	return sortChatByLastMessageDate(chatA, chatB)
	// }

	if (chatA.extraData?.awakeningSnooze || chatB.extraData?.awakeningSnooze) {
		return sortChatByLastSnoozeDateAndLastMessageDate(chatA, chatB)
	}

	return sortChatByLastMessageDate(chatA, chatB)
}

export const sortMessagesInAscendingOrder = (messageA: IMessage, messageB: IMessage) => {
	const messageADate = new Date(messageA.createdAt)
	const messageBDate = new Date(messageB.createdAt)

	const messageATimestamp = +messageADate
	const messageBTimestamp = +messageBDate

	const messageASequentialId = messageA.sequentialId
	const messageBSequentialId = messageB.sequentialId

	const isSameMessageTimestamp = isSameTimestamp(messageADate, messageBDate)

	if (isSameMessageTimestamp) {
		return messageASequentialId - messageBSequentialId
	}

	return messageATimestamp - messageBTimestamp
}

export const getLastChatMessage = (chatId: number, messages: Record<string, Message>): Message => {
	const messagesInAscendingOrder = Object.values(messages || {})
		.filter(message => message.inboxChannelChatId === chatId)
		.filter(message => !message.sentBySystem)
		.sort(sortMessagesInAscendingOrder)

	const lastMessage = messagesInAscendingOrder.pop()

	return lastMessage as Message
}

/**
 * This hash is usually used to know when to re-render components
 * that need to deal with chat listing. Being minded about, we need to add
 * params to the hash that when changed, must force the chat item to change either.
 */
export const buildChatHash = (chat: Chat): string => {
	return `${chat?.id}@${chat?.status}@${chat?.attendance?.assignTeamId}@${chat?.attendance?.assignUserId}`
}

/**
 * To save emojis to localStorage,
 * you need to encode the data to Uint8Array before encoding it to Base64.
 * This ensures that special characters are preserved correctly.
 */
const decodedMessageByBase64 = (message: string): string => {
	const binaryString = atob(message)
	const uint8Array = new Uint8Array(binaryString.length)

	for (let i = 0; i < binaryString.length; i++) {
		uint8Array[i] = binaryString.charCodeAt(i)
	}

	const decodedMessage = new TextDecoder().decode(uint8Array)

	return decodedMessage
}

const encodedMessageToBase64 = (message: string): string => {
	const encodedMessage = new TextEncoder().encode(message)
	const binaryString = String.fromCodePoint(...encodedMessage)

	return btoa(binaryString)
}

export const saveChatMessageSketch = (message: string, chatId: number): void => {
	const encodedMessage = encodedMessageToBase64(message)

	StorageService.set<string>(StorageService.mountKey(StorageService.reservedKeys.INBOX_CHAT_MESSAGE_SKETCH, String(chatId)), encodedMessage)
}

export const getChatMessageSketch = (chatId: number): string | null => {
	const messageSketchEncoded = StorageService.get<string>(StorageService.mountKey(StorageService.reservedKeys.INBOX_CHAT_MESSAGE_SKETCH, String(chatId)))

	if (messageSketchEncoded) {
		const decodedMessage = decodedMessageByBase64(messageSketchEncoded)

		return decodedMessage
	}

	return null
}

export const deleteChatMessageSketch = (chatId: number): void => {
	StorageService.delete(StorageService.mountKey(StorageService.reservedKeys.INBOX_CHAT_MESSAGE_SKETCH, String(chatId)))
}
