import React, { useState } from "react"
import {
	Typography,
	Tooltip
} from "@material-ui/core"
import {
	FileCopy
} from "@material-ui/icons"

import { Notification } from "@/components"

import useStyles from "@/components/Messages/TextMessage/styles"
import useCustomStyles from "@/styles/custom"

import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import copy from "copy-to-clipboard"

import { IMessageExtraData } from "@/protocols/channel"

type TextMessageProps = {
	content: string
	copySpecial?: boolean
	translateVariables?: boolean
	extraData?: IMessageExtraData
}

export const TextMessageContent: React.FC<TextMessageProps> = (props) => {
	const {
		content,
		translateVariables = true,
		copySpecial = true,
		extraData
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [showCopyIcon, setShowCopyIcon] = useState(Boolean)

	const handleShowCopyIcon = () => {
		setShowCopyIcon(true)
	}

	const handleHideCopyIcon = () => {
		setShowCopyIcon(false)
	}

	const copyHandler = (content: string) => {
		copy(content)
		Notification.success({ message: "Copiado com sucesso!" })
	}

	return (
		<div
			onMouseEnter={() => handleShowCopyIcon()}
			onMouseLeave={() => handleHideCopyIcon()}
		>
			{
				showCopyIcon && Boolean(copySpecial) &&
				<Tooltip
					title="Copiar especial"
				>
					<FileCopy
						fontSize={"small"}
						onClick={ () => copyHandler(content) }
						className={ customClasses.copyIcon }
					/>
				</Tooltip>
			}

			<Typography
				variant="overline"
				className={classes.messageText}
				color="textPrimary"
			>
				{translateWhatsAppSyntaxToHTML(content, translateVariables)}
			</Typography>

			{extraData?.footer?.text && (
				<>
					<br />

					<Typography
						variant="caption"
						color="textSecondary"
						component="span"
					>
						{extraData?.footer?.text}
					</Typography>
				</>
			)}
		</div>
	)
}
