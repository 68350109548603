import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	chatTabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	chatTab: {
		color: `${colors.grayScale[4]} !important`,
		fontSize: 12
	},
	chatTabIndicator: {
		backgroundColor: colors.grayScale[4]
	},
	chatSubTabs: {
		padding: theme.spacing(2),
		width: "100%",
		backgroundColor: colors.grayScale[11]
	},
	chatSubTab: {
		textTransform: "none",
		backgroundColor: colors.unrelated.F0F2F5,
		borderRadius: theme.spacing(0.5),
		"&:not(:last-child)": {
			marginRight: theme.spacing(0.5)
		}
	},
	chatSubTabIndicator: {
		backgroundColor: "transparent"
	},
	disableTab: {
		color: colors.grayScale[4],
		boxShadow: "none",
		opacity: 0.7,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 2
	},
	infoIcon: {
		width: 16,
		height: 16
	}
}))

export default useStyles
