import React from "react"
import { Button } from "@material-ui/core"
import { Add as AddConditionIcon } from "@material-ui/icons"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton/styles"
import { ChatBotFlowError } from "@/protocols/chatBotFlow"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

type CreateConditionButtonProps = {
	color?: string
	onClick?: () => void
	disabled?: boolean
	chatBotFlowBlockId?: number
	errorType?: ChatBotFlowError
}

const CreateConditionButton: React.FC<CreateConditionButtonProps> = (props) => {
	const {
		color,
		disabled,
		onClick,
		children,
		chatBotFlowBlockId,
		errorType
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const classes = useStyles()

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			variant="text"
			className={`${classes.button} ${chatBotFlowConstructorStore.hasBlockError(String(chatBotFlowBlockId), errorType) ? classes.animation : ""}`}
			startIcon={<AddConditionIcon />}
			style={{
				...(!disabled && { color })
			}}
		>
			{children}
		</Button>
	)
}

export default CreateConditionButton
