import {
	ChatBotCategory,
	ChatBotFlow,
	ChatBotFlowCreatedByFeature,
	ChatBotFlowExtraData,
	ChatBotFlowType
} from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"
import { ShortChatBotTrigger, ShortFlow, TagOption } from "@/protocols/chatBotConstructor"

import ApiService from "@/services/Api"

export type ShortChatBotFlow = {
	id: number
	name: string
	active: boolean
	updated_at: Date
	extra_data: ChatBotFlowExtraData
	consolidated_data: ChatBotFlowConsolidatedData
	tag?: TagOption
}

export type ChatBotFlowConsolidatedData = {
	chat_bot_flow_creator_user_email: string
	amount_of_blocks: number
	how_many_times_was_triggered: number
	first_use_date: Date
	last_use_date: Date
	has_pending_validation: boolean
	last_user_who_edited: string
	last_flow_update: Date
}

export type ChatBotFlowInChatBotTriggerFormattedForList = {
	id: number
	name: string
	active: boolean
	trigger_type?: ChatBotCategory
	updated_at: Date
	webhook_id?: number
	webhook_template?: {
		display_title?: string
		type?: WebhookTemplateType | "custom"
	}
	type: ChatBotFlowType
	consolidated_data: ChatBotFlowConsolidatedData
	tag?: TagOption
}

export type TriggerWithChatBotFlows = {
	type: ChatBotCategory
	description: string
	chat_bot_flow_in_chat_bot_triggers: Array<ChatBotFlowInChatBotTriggerFormattedForList>
}

export type CategorizedChatBotFlows = {
	[key in ChatBotCategory]: TriggerWithChatBotFlows
}

class ChatBotFlowService {
	async create (
		chatBotFlowType: ChatBotFlowType,
		data: Pick<ChatBotFlow, "name">,
		createdByFeature: ChatBotFlowCreatedByFeature
	): Promise<Pick<ChatBotFlow, "id">> {
		const response = await ApiService.post("/chat-bot/flow", {
			name: data.name,
			type: chatBotFlowType,
			createdByFeature
		})

		return response.data
	}

	async createWithTrigger (
		chatBotFlowType: ChatBotFlowType,
		data: Pick<ChatBotFlow, "name">,
		webhookTemplate: WebhookTemplateType | "custom",
		createdByFeature: ChatBotFlowCreatedByFeature
	): Promise<Pick<ChatBotFlow, "id">> {
		const response = await ApiService.post("/chat-bot/flow-with-trigger", {
			name: data.name,
			type: chatBotFlowType,
			webhookTemplateType: webhookTemplate,
			createdByFeature
		})

		return response.data
	}

	async getAllCategorized (chatBotFlowType?: ChatBotFlowType): Promise<CategorizedChatBotFlows> {
		const response = await ApiService.get("/chat-bot/flows", {
			params: {
				type: chatBotFlowType
			}
		})

		return response.data
	}

	async toggleActivation (chatBotFlowId: number, active: boolean, oldChatBotTriggers: ShortChatBotTrigger[]): Promise<void> {
		await ApiService.put(`/chat-bot/flow/${chatBotFlowId}`, {
			chatBotFlow: {
				active
			},
			chatBotFlowTriggers: oldChatBotTriggers
		})
	}

	async update (chatBotFlowId: number, chatBotFlow: Pick<ShortFlow, "name">): Promise<void> {
		await ApiService.put(`/chat-bot/flow/${chatBotFlowId}`, { chatBotFlow })
	}

	async delete (chatBotFlowId: number): Promise<void> {
		await ApiService.delete(`/chat-bot/flow/${chatBotFlowId}`)
	}

	async duplicate (chatBotFlowId: number): Promise<Pick<ChatBotFlow, "id" | "type">> {
		const response = await ApiService.post(`/chat-bot/flow-manager/${chatBotFlowId}/duplicate`)

		return response.data
	}

	async export (chatBotFlowId: number): Promise<ShortFlow> {
		const response = await ApiService.get(`/chat-bot/flow-manager/${chatBotFlowId}/export`)

		return response.data
	}

	async import (chatBotFlow: ShortFlow): Promise<Pick<ChatBotFlow, "id" | "type">> {
		const response = await ApiService.post("/chat-bot/flow-manager/import", { flow: chatBotFlow })

		return response.data
	}
}

export default new ChatBotFlowService()
