import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

export const BUTTON_MESSAGE_WIDTH = 370

const useStyles = makeStyles({
	descriptionContainer: {
		flex: 1
	},
	button: {
		pointerEvents: "none",
		boxShadow: "0px 1px 1px rgb(0 0 0 / 25%)",
		borderRadius: "8px",
		fontWeight: "normal",
		color: colors.palette.link,
		fontSize: 14,
		textTransform: "none"
	},
	buttonText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	}
})

export default useStyles
