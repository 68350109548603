import React, { useState } from "react"
import TemplateCard from "@/pages/Admin/Templates/components/TemplateCard"
import {
	DetailedWABAChannelMessageTemplate,
	WABATemplateQuality,
	WABATemplateStatus
} from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import {
	Grid,
	Typography,
	Button,
	TextField,
	InputAdornment,
	IconButton,
	TablePagination,
	Select, MenuItem,
	Divider as MuiDivider,
	Tooltip,
	Container,
	Link,
	Chip
} from "@material-ui/core"
import {
	Add,
	Sync,
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"
import { Breadcrumb, Divider, Loading, Notification, Portlet } from "@/components"
import { getRowsLabel } from "@/utils/table"

import WABAChannelMessageTemplateService from "@/@integrations/WABA/services/WABAChannelMessageTemplate"

import { ErrorType } from "@/hooks/useValidation"
import ErrorHandlerService from "@/services/ErrorHandler"

import { useGlobalStateStore } from "@/store/GlobalState"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Templates/styles"
import useDebounce from "@/hooks/useDebounce"
import undrawFileSearching from "@/assets/images/waba/undraw_file_searching.svg"
import TemplatePageSkeleton from "@/skeletons/Admin/TemplatePageSkeleton"
import { useHistory } from "react-router-dom"
import newColors from "@/styles/newColors"

export type TemplatesWhereData = {
	page: number
	rowsPerPage: number
	count: number
}

export type TemplatesFiltersData = {
	search?: string
	status?: WABATemplateStatus
	quality?: WABATemplateQuality
}

export const TEMPLATE_STATUS_TO_READABLE_STATUS: Record<WABATemplateStatus, { label: string, description?: string, color: string }> = {
	approved: {
		label: "Aprovado",
		color: newColors.green[400]
	},
	deactivated: {
		label: "Desativado",
		color: newColors.grey[500]
	},
	failed: {
		label: "Erro",
		color: newColors.red[500]
	},
	paused: {
		label: "Pausado",
		description: "Pausado devido à baixa qualidade. Pode ser editado para melhorias. Recomenda-se seguir as políticas de criação de templates para evitar penalidades.",
		color: newColors.grey[500]
	},
	rejected: {
		label: "Rejeitado",
		color: newColors.red[500]
	},
	pending: {
		label: "Em análise",
		description: "O processo de análise de templates pode levar até 24 horas.",
		color: newColors.yellow[400]
	}
}

export const TEMPLATE_QUALITY_TO_READABLE_QUALITY: Record<WABATemplateQuality, { label: string, description?: string, color: string }> = {
	high: {
		label: "Qualidade alta",
		color: newColors.green[500]
	},
	medium: {
		label: "Qualidade média",
		color: newColors.yellow[300]
	},
	low: {
		label: "Qualidade baixa",
		description: "O uso contínuo de templates de baixa qualidade pode levar à pausa, desabilitação e impactar a qualidade do seu número. Recomenda-se seguir as políticas de criação de templates para evitar penalidades.",
		color: newColors.red[500]
	},
	unknown: {
		label: "Não qualificado",
		description: "A qualificação do template é ajustada com base no uso e no feedback dos seus clientes.",
		color: newColors.grey[300]
	}
}

const Templates: React.FC = () => {
	const [templates, setTemplates] = useState<DetailedWABAChannelMessageTemplate[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [whereData, setWhereData] = useState<TemplatesWhereData>({
		page: 0,
		rowsPerPage: 6,
		count: 0
	} as TemplatesWhereData)
	const [filters, setFilters] = useState<TemplatesFiltersData>({} as TemplatesFiltersData)

	const isSelectedFilterNotEmpty = Object.values(filters).some(value => value !== "")

	const history = useHistory()
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const inboxChannelId = globalStateStore.currentChannel?.id

	const syncAllTemplates = async (): Promise<void> => {
		try {
			await WABAChannelMessageTemplateService.syncAllTemplates(inboxChannelId)
		} catch (error) {
			Notification.error({
				message: "Não foi possivel sincronizar os templates com a Gupshup, por favor verifique sua conexão"
			})
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const getWABATemplates = async (newWhereData?: Partial<TemplatesWhereData>): Promise<void> => {
		try {
			setLoading(true)
			await syncAllTemplates()

			const searchConfig = { ...whereData, ...newWhereData, ...filters }
			const response = await WABAChannelMessageTemplateService.retrieveAllDetailedByInboxChannelId(searchConfig, inboxChannelId)

			setTemplates(response.rows)

			setWhereData(lastState => ({
				...lastState,
				count: response.count
			}))
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	const handleWhereDataChange = async (key: keyof Omit<TemplatesWhereData, "count">, value: number | string) => {
		setWhereData(lastState => ({
			...lastState,
			[key]: value
		}))
		await getWABATemplates({ [key]: value })
	}

	const handleFiltersChange = (key: keyof TemplatesFiltersData, value: string) => {
		setFilters(prevFilters => ({
			...prevFilters,
			[key]: value
		}))
	}

	const handleRemoveDeleteTemplate = (templateId: number): void => {
		setTemplates(templates.filter((template) => template.id !== templateId))
	}

	const handleClearFilters = () => {
		setFilters({})
	}

	const handleAddTemplate = () => {
		history.push("/admin/template/manage")
	}

	useDidMount(() => {
		getWABATemplates()
	})

	useDebounce(
		async () => getWABATemplates(),
		filters,
		2000)

	return (
		<>
			{templates.length > 0 || filters
				? <>
					<Breadcrumb data={[
						{ name: "Templates", pathname: "/admin/templates" }
					]} />
					<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
						<Grid item xs={12}>
							<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
								<Grid item>
									<Typography variant="h5" color="textPrimary" className={classes.titleText}>
										Templates
									</Typography>
								</Grid>
								<Grid item>
									<Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddTemplate}>
										Adicionar Template
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Portlet elevation={1}>
						<Grid container direction="row" justifyContent="space-between" style={{ paddingRight: 10 }}>
							<Grid item style={{ width: 340 }}>
								<TextField
									style={{ width: "100%" }}
									value={filters.search}
									placeholder="Pesquisar por nome ou conteúdo"
									variant="outlined"
									className={classes.search}
									onChange={({ target }) => handleFiltersChange("search", target.value)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: filters.search && (
											<IconButton size="small" onClick={() => handleFiltersChange("search", "")}>
												<CloseIcon fontSize="small" />
											</IconButton>
										)
									}}
									size="small"
								/>
							</Grid>
							<Grid item>
								<Grid container spacing={2}>
									<Grid item>
										<Select
											value={filters.status}
											onChange={(e) => handleFiltersChange("status", e.target.value as string)}
											variant="outlined"
											displayEmpty
											renderValue={() => filters.status ? TEMPLATE_STATUS_TO_READABLE_STATUS[filters.status].label : "Selecione o status"}
											className={classes.select}
										>
											<MenuItem value="">Selecione o status</MenuItem>
											{Object.keys(TEMPLATE_STATUS_TO_READABLE_STATUS).map((key) => (
												<MenuItem key={key} value={key}>
													{TEMPLATE_STATUS_TO_READABLE_STATUS[key as WABATemplateStatus].label}
												</MenuItem>
											))}
										</Select>
									</Grid>
									<Grid item>
										<Select
											value={filters.quality}
											onChange={(e) => handleFiltersChange("quality", e.target.value as string)}
											variant="outlined"
											displayEmpty
											renderValue={() => filters.quality ? TEMPLATE_QUALITY_TO_READABLE_QUALITY[filters.quality].label : "Selecione a qualidade"}
											className={classes.select}
										>
											<MenuItem value="">Selecione a qualidade</MenuItem>
											{Object.keys(TEMPLATE_QUALITY_TO_READABLE_QUALITY).map((key) => (
												<MenuItem key={key} value={key}>
													{TEMPLATE_QUALITY_TO_READABLE_QUALITY[key as WABATemplateQuality].label}
												</MenuItem>
											))}
										</Select>
									</Grid>
									<Grid item>
										<Tooltip title="Sincronizar com os templates da GupShup">
											<Button variant="contained" color="primary" onClick={async () => await getWABATemplates({ page: 0, rowsPerPage: 6 })} className={classes.syncButton}>
												<Sync />
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{isSelectedFilterNotEmpty ? <>
							<Divider size={2} orientation="horizontal" />
							<Grid container alignItems="center" spacing={1}>
								<Grid item>
									<b>Filtro selecionado:</b>
								</Grid>
								<Grid item xs>
									<Chip
										label={"Filtro temporario"}
										onDelete={handleClearFilters}
									/>
								</Grid>
							</Grid>
							<Divider size={2} orientation="horizontal" />
						</> : <Divider size={4} orientation="horizontal" />}

						<Grid container direction="row" wrap="wrap" xs={12}>
							<Loading
								loading={loading}
								customLoadingElement={<TemplatePageSkeleton />}
							>
								{templates.length > 0 ? templates.map((template) => (
									<TemplateCard
										onDelete={handleRemoveDeleteTemplate}
										key={template.name}
										{...template}
									/>
								)) : <Grid container justifyContent="center">
									<Typography>Não encontramos nenhum templates com os filtros selecionados</Typography>
									<Divider orientation="horizontal" size={4} />
								</Grid>}
							</Loading>
						</Grid>

						<MuiDivider />

						<Grid container justifyContent="space-between" alignItems="flex-end" style={{ marginTop: "16px" }}>
							<Grid item>
								<TablePagination
									rowsPerPageOptions={[6, 12, 24, 48]}
									component="div"
									count={whereData?.count}
									rowsPerPage={whereData.rowsPerPage}
									page={whereData.page}
									onPageChange={(_, page) => handleWhereDataChange("page", page)}
									onRowsPerPageChange={({ target }) => handleWhereDataChange("rowsPerPage", +target.value)}
									labelRowsPerPage={"Resultados por página:"}
									labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
								/>
							</Grid>
						</Grid>
					</Portlet>

					<Divider size={1} orientation="horizontal" />
				</>
				: <Container maxWidth="md">
					<Grid
						container
						alignItems="center"
						justifyContent="space-between"
						direction="column"
						spacing={8}
					>
						<Grid item>
							<Grid container justifyContent="center">
								<img
									src={undrawFileSearching}
									alt="Sem templates"
									className={classes.noTemplateImage}
								/>
							</Grid>
						</Grid>

						<Grid item xs={10}>
							<Typography
								variant="h5"
								className={classes.noTemplateTitle}
								align="center"
							>
								Crie seu primeiro template pela Letalk
							</Typography>
						</Grid>

						<Grid xs={8}>
							<Typography
								variant="body1"
								className={classes.noTemplateText}
								align="center"
							>
								Use templates para enviar mensagens personalizadas em escala para centenas de contatos! Inicie conversas diretas e automáticas com clientes no WhatsApp, aumentando as chances de visualizações e conversões.
							</Typography>
						</Grid>

						<Grid>
							<Divider orientation="horizontal" size={3} />
							<Link
								href={""}
								underline="always"
								target="_blank"
								color="inherit"
							>
								Como criar o seu primeiro template utilizando a Letalk
							</Link>
						</Grid>

						<Divider orientation="horizontal" size={2} />

						<Grid item>
							<Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddTemplate}>
								ADICIONAR TEMPLATE
							</Button>
						</Grid>
					</Grid>
				</Container>
			}
		</>
	)
}

export default Templates
