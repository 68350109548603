import React, { useEffect, useState } from "react"
import { Card, Grid, Typography, IconButton, InputBase, Box, Tooltip, Popover } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import {
	Close as CloseIcon,
	AddCircleOutline as AddIcon,
	Done as DoneIcon
} from "@material-ui/icons"
import close_icon from "@/assets/icons/close_icon.svg"
import {
	Divider,
	Notification,
	SvgIcon
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useStyles from "@/pages/Admin/ClientCatalog/ClientProfileInfo/Tags/styles"
import { Color } from "@/protocols/color"
import { ErrorType } from "@/hooks/useValidation"
import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"
import { ClientProfileInfoData, TagDataProps } from "@/protocols/clientCatalog"
import useCustomStyles from "@/styles/custom"
import { Tag } from "@/protocols/tag"

type ClientTagsProps = {
	clientId: number
	clientAssociatedTags?: TagDataProps[]
	availableTags: TagDataProps[]
	onDataChange: (id: number, data: Partial<ClientProfileInfoData>) => void
	onUpdateTags?: () => Promise<void>
}

type ChangedTagAction = "ADD" | "REMOVE"

const ClientTags: React.FC<ClientTagsProps> = (props) => {
	const {
		availableTags,
		clientId,
		onDataChange,
		clientAssociatedTags,
		onUpdateTags
	} = props

	const [selectedTags, setSelectedTags] = useState<TagDataProps[]>(clientAssociatedTags as TagDataProps[])
	const [popoverAnchorElement, setPopoverAnchorElement] = useState<null | HTMLElement>(null)
	const [changedTags, setChangedTags] = useState<Record<number, ChangedTagAction>>({})

	const changeTag = (tagId: number, action: ChangedTagAction) => {
		setChangedTags(lastState => {
			lastState[tagId] = action

			return lastState
		})
	}

	const getClientTags = async () => {
		if (!clientId) {
			return
		}

		try {
			const { data } = await ApiService.get(`/tag-association/client/${clientId}/tags`)

			const clientTags = data.tags as Tag[]

			setSelectedTags(clientTags)

			onUpdateTags?.()
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const removeTagsAssociations = async (tagIds: number[]) => {
		try {
			await ApiService.delete(`/tag-association/client/${clientId}`, {
				params: {
					tagIds: tagIds.join(",")
				}
			})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)

			Notification.error({ message: `Houver um erro ao remover ${tagIds.length === 1 ? "a tag" : "as tags"}` })
		}
	}

	const addTagsAssociations = async (tagIds: number[]) => {
		try {
			await ApiService.post(`/tag-association/client/${clientId}`, {
				tagIds
			})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)

			Notification.error({ message: `Houver um erro ao adicionar ${tagIds.length === 1 ? "a tag" : "as tags"}` })
		}
	}

	const handleOpenTagPopover = async (event: React.MouseEvent<HTMLElement>) => {
		setPopoverAnchorElement(event.currentTarget)

		await onUpdateTags?.()
	}

	const handleTagPopoverChange = async () => {
		try {
			setPopoverAnchorElement(null)

			const isThereAnyChange = Object.keys(changedTags).length > 0

			if (!isThereAnyChange) {
				return
			}

			const addedTagIds: number[] = []
			const removedTagIds: number[] = []

			for (const changedTagId in changedTags) {
				const action = changedTags[changedTagId]
				const tagId = Number(changedTagId)

				if (action === "ADD") {
					addedTagIds.push(tagId)
				} else if (action === "REMOVE") {
					removedTagIds.push(tagId)
				}
			}

			if (removedTagIds.length) {
				await removeTagsAssociations(removedTagIds)
			}

			if (addedTagIds.length) {
				await addTagsAssociations(addedTagIds)
			}

			await getClientTags()

			setChangedTags({})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Algo deu errado ao atualizar as tags" })
		}
	}

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	const handleRemoveTag = async (tagId: number) => {
		await removeTagsAssociations([tagId])
		await getClientTags()
	}

	const classes = useStyles()
	const customClasses = useCustomStyles()

	useEffect(() => {
		onDataChange(clientId, { tags: selectedTags })
		// eslint-disable-next-line
	}, [selectedTags])

	return (
		<Grid item style={{ paddingRight: 16, paddingLeft: 32 }}>
			<Card elevation={0}>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item style={{ paddingRight: 17, width: "100%" }}>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: 16, fontWeight: 600 }}
								>
									Tags
								</Typography>
							</Grid>
							<Grid item aria-describedby="popper">
								<IconButton onClick={(event) => handleOpenTagPopover(event)} style={{ padding: 1 }} >
									<AddIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={0.5} />
					{selectedTags.length > 0 ? <Grid item style={{ width: "100%", height: 167 }} >
						<div className={classes.scrollBar} >
							{selectedTags && <Grid item>
								{
									selectedTags.map(tag =>
										<Grid container direction="row" xs={12} justifyContent="space-between" key={tag.id} className={classes.tagCard} style={{ background: colorToRGB(tag.color) }}>

											<Grid item xs={10}>
												<Grid container xs={12} direction="row">
													{tag.activeCampaignAssociationIsEnabled && <Grid item xs={1}>
														<Tooltip title={"Essa tag foi adicionada ao contato no Active Campaign."}>
															<Grid item
																className={classes.pluginFeatureIconCircle}
																style={{ marginLeft: 6 }}
															>
																<SvgIcon
																	className={classes.pluginFeatureIcon}
																	icon={ActiveCampaignSVGIcon}
																/>
															</Grid>
														</Tooltip>
													</Grid>}
													<Grid item xs={tag.activeCampaignAssociationIsEnabled ? 11 : 12}>
														<Grid container justifyContent="space-between">
															<Grid item style={{ paddingLeft: 8 }}>
																<Tooltip title={tag.name}>
																	<span className={classes.tagName}>{tag.name}</span>
																</Tooltip>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={2} style={{ paddingRight: 6 }}>
												<IconButton onClick={() => handleRemoveTag(tag.id)}
													className={classes.closeIcon}
													size="small"
												>
													<img src={close_icon} alt="close icon" />
												</IconButton>
											</Grid>
										</Grid>
									)
								}
							</Grid>}
						</div>
					</Grid>
						: <Grid item justifyContent="center" alignItems="center" style={{ display: "flex", width: "100%", height: 139 }}>
							<Typography
								color="textPrimary"
								className={customClasses.boldText}
							>
								Não ha tags adicionadas
							</Typography>
						</Grid>}
				</Grid>
			</Card>
			<Popover
				id="popper"
				open={Boolean(popoverAnchorElement)}
				anchorEl={popoverAnchorElement}
				onClose={handleTagPopoverChange}
			>
				<Autocomplete
					open
					multiple
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					value={selectedTags}
					onChange={(event, newValue, reason, details) => {
						const tagId = details?.option?.id

						const isAddReason = reason === "select-option"
						const isRemoveReason = reason === "remove-option"

						if (tagId) {
							if (isAddReason) {
								changeTag(tagId, "ADD")
							}

							if (isRemoveReason) {
								changeTag(tagId, "REMOVE")
							}
						}

						setSelectedTags(newValue)
					}}
					disableCloseOnSelect
					disablePortal
					renderTags={() => null}
					noOptionsText="Sem tags cadastradas"
					renderOption={(option, { selected }) => (
						<>
							<DoneIcon
								className={classes.iconSelected}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
							<Box className={classes.color} style={{ backgroundColor: colorToRGB(option.color) }} />
							<Typography className={classes.text}>
								{option.name}
							</Typography>
							<CloseIcon
								className={classes.close}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
						</>
					)}
					options={availableTags}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={classes.inputBase}
						/>
					)}
				/>
			</Popover>
		</Grid>
	)
}

export default ClientTags
