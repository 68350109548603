import React from "react"
import { Button, Grid, Typography } from "@material-ui/core"

import InputBlockMessage from "@/pages/Attendance/Chat/ConversationPanel/Input/InputBlockMessage"

import WABAMessageTemplateSelector, { WABAMessageTemplateSelectorProps } from "@/@integrations/WABA/components/Chat/WABAMessageTemplate/WABAMessageTemplateSelector"

import useStyles from "@/@integrations/WABA/components/Chat/WABAInputBlockMessage/styles"

export type WABAInputBlockMessageProps = {
	blocked: boolean
	WABAMessageTemplateSelectorProps: WABAMessageTemplateSelectorProps
}

const WABAInputBlockMessage: React.FC<WABAInputBlockMessageProps> = (props) => {
	const classes = useStyles()

	return (
		<InputBlockMessage
			blocked={props.blocked}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid	item>
					<Typography
						variant="body1"
						color="textPrimary"
						align="left"
					>
						Para iniciar uma conversa, você precisa enviar um template para o contato.
					</Typography>
				</Grid>

				<Grid	item>
					<WABAMessageTemplateSelector
						{...props.WABAMessageTemplateSelectorProps}
					>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
						>
							Enviar template
						</Button>
					</WABAMessageTemplateSelector>
				</Grid>
			</Grid>
		</InputBlockMessage>
	)
}

export default WABAInputBlockMessage
