import React, { useState } from "react"
import {
	TextField,
	Chip, CircularProgress
} from "@material-ui/core"
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput/styles"
import { timeout } from "@/utils/time"

type OptionValue<Option, Multiple> = Multiple extends true ? Option[] : Option

type SelectInputProps<Option, Multiple> = {
	defaultOption?: OptionValue<Partial<Option>, Multiple>
	options: Option[]
	onChange: (option: OptionValue<Option, Multiple> | null) => void
	placeholder?: string
	multiple?: Multiple
	renderTag?: (option: Option) => React.ReactNode
	getOptionSelected?: (option: Option, value: Option) => boolean
	getOptionLabel?: (option: Option) => string
	getOptionDisabled?: (option: Option) => boolean
	value?: OptionValue<Partial<Option>, Multiple>
	groupBy?: (option: Option) => string
	onUpdate?: () => Promise<void>
	noOptionsText?: string
	loadingText?: string
}

function SelectInput<Option extends { name: string }, Multiple extends boolean = false> (props: SelectInputProps<Option, Multiple>) {
	const {
		defaultOption,
		options,
		onChange,
		placeholder,
		multiple,
		renderTag,
		getOptionSelected,
		getOptionLabel,
		getOptionDisabled,
		value,
		groupBy,
		onUpdate,
		noOptionsText,
		loadingText
	} = props

	const [loading, setLoading] = useState<boolean>(false)

	const classes = useStyles()

	const handleUpdate = async () => {
		if (onUpdate) {
			setLoading(true)

			await Promise.all([
				await onUpdate(),
				await timeout(1000)
			])

			setLoading(false)
		}
	}

	// eslint-disable-next-line
	const overrideDefaultValue = (): any => {
		return defaultOption
	}

	const overrideGetOptionsSelected: AutocompleteProps<Option, Multiple, false, false>["getOptionSelected"] = (option, value) => {
		if (getOptionSelected) {
			return getOptionSelected?.(option, value)
		} else {
			return option.name === value.name
		}
	}

	const overrideGetOptionLabel: AutocompleteProps<Option, Multiple, false, false>["getOptionLabel"] = (option) => {
		if (getOptionLabel) {
			return getOptionLabel(option)
		} else {
			return option.name
		}
	}

	const overrideOnChange: AutocompleteProps<Option, Multiple, false, false>["onChange"] = (_, value) => {
		onChange(value as OptionValue<Option, Multiple>)
	}

	const overrideRenderInput: AutocompleteProps<Option, Multiple, false, false>["renderInput"] = (params) => {
		return (
			<TextField
				{...params}
				className={classes.filterInput}
				variant="outlined"
				placeholder={placeholder}
				InputProps={{
					...params.InputProps,
					endAdornment: (
						<>
							{ loading ? <CircularProgress color="inherit" size={20} /> : null }
							{params.InputProps.endAdornment}
						</>
					)
				}}
			/>
		)
	}

	const overrideRenderTags: AutocompleteProps<Option, Multiple, false, false>["renderTags"] = (value, getTagProps) => {
		return value.map((option, index) => {
			const tagProps = getTagProps({ index })

			const defaultTag = (
				<Chip
					key={index}
					label={option.name}
					size="small"
					{...tagProps}
				/>
			)

			if (renderTag) {
				const renderedTag = renderTag(option)

				return React.cloneElement(renderedTag as React.ReactElement, {
					key: index,
					...tagProps
				})
			} else {
				return defaultTag
			}
		})
	}

	return (
		<Autocomplete
			multiple={multiple}
			defaultValue={overrideDefaultValue()}
			options={options}
			getOptionSelected={overrideGetOptionsSelected}
			getOptionLabel={overrideGetOptionLabel}
			getOptionDisabled={getOptionDisabled}
			fullWidth
			filterSelectedOptions
			onChange={overrideOnChange}
			renderInput={overrideRenderInput}
			renderTags={overrideRenderTags}
			value={value}
			loadingText={loadingText}
			groupBy={groupBy}
			onOpen={handleUpdate}
			loading={loading}
			noOptionsText={noOptionsText}
		/>
	)
}

export default SelectInput
