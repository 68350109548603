import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"
import GenericConditionSectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import ConditionSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionBlock/ConditionSectionEditor"

import ClientTagContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/ClientTagContainer"
import ClientCustomFieldContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/ClientCustomFieldContainer"

import { getContinueConditionRuleFormattedForCreation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"
import MessageReceivedContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/MessageReceivedContainer"
import { Grid, Typography } from "@material-ui/core"
import { InfoOutlined as InfoIcon } from "@material-ui/icons"
import { Divider } from "@/components"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionBlock/styles"

const ConditionBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const classes = useStyles()

	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySectionGroup>
				{conditionRules?.map(rule => (
					<BlockBodySection
						key={rule.id}
						fullWidth
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<ConditionSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<GenericConditionSectionContainer
									chatBotFlowBlockRule={rule}
									customValidationRender={{
										"client-tag": ClientTagContainer,
										"client-custom-field": ClientCustomFieldContainer,
										"client-response-value": MessageReceivedContainer
									}}
									chatBotFlowBlockId={node?.data?.chatBotFlowBlockId}
								/>
							</ConditionSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<ConditionSectionEditor
					hideChildSelection
					onSave={(chatBotFlowBlockRule) => (
						chatBotFlowBlockDetails.changeRule("CREATE", {
							...chatBotFlowBlockRule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null,
							validations: chatBotFlowBlockRule?.validations || []
						})
					)}
					chatBotFlowBlockRule={(
						getContinueConditionRuleFormattedForCreation(
							chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules,
							"client-tag"
						)
					)}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
						chatBotFlowBlockId={node?.data?.chatBotFlowBlockId}
						errorType="condition_block_missing_option"
					>
						Adicionar condição
					</CreateConditionButton>
				</ConditionSectionEditor>
			</BlockBodySection>

			<BlockBodySection
				fullWidth
			>
				<Grid container>
					<InfoIcon
						className={classes.infoIcon}
					/>

					<Divider orientation="vertical" size={0.5} />

					<Typography
						variant="caption"
						className={classes.infoText}
					>
						Ao criar uma condição, você não precisa se preocupar com letras maiúsculas e minúsculas, este bloco ignora a diferença entre elas.
					</Typography>
				</Grid>
			</BlockBodySection>

			<ElseConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
				text="Se não entrar em nenhuma condição"
			/>
		</BaseBlock>
	)
}

export default ConditionBlock
