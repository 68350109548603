import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import EditMessageSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditMessageSection"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import InvalidResponseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"
import GenericConditionSectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import ResponseSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalTextBlock/ResponseSectionEditor"
import MessageReceivedContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer/MessageReceivedContainer"

import { getContinueConditionRuleFormattedForCreation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"

const ConditionalTextBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	return (
		<BaseBlock
			node={node}
		>
			<EditMessageSection
				constructionResources={chatBotFlowConstructorStore.constructionResources}
				onSave={(buildedMessages) => chatBotFlowBlockDetails.changeContent({ messagesToSend: buildedMessages })}
				messages={chatBotFlowBlockDetails.content.messagesToSend}
				chatBotFlowBlockId={node.data.chatBotFlowBlockId}
			/>

			<BlockBodySectionGroup>
				{conditionRules?.map(rule => (
					<BlockBodySection
						key={rule.id}
						fullWidth
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<ResponseSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<GenericConditionSectionContainer
									chatBotFlowBlockRule={rule}
									customValidationRender={{ "message-received": MessageReceivedContainer }}
								/>
							</ResponseSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<ResponseSectionEditor
					hideChildSelection
					onSave={(chatBotFlowBlockRule) => (
						chatBotFlowBlockDetails.changeRule("CREATE", {
							...chatBotFlowBlockRule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null,
							validations: chatBotFlowBlockRule?.validations || []
						})
					)}
					chatBotFlowBlockRule={
						getContinueConditionRuleFormattedForCreation(
							chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules,
							"message-received"
						)
					}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
						chatBotFlowBlockId={node.data.chatBotFlowBlockId}
						errorType="multiple_condition_block_without_answer"
					>
						Adicionar resposta
					</CreateConditionButton>
				</ResponseSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<InvalidResponseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Se nenhuma resposta for válida"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default ConditionalTextBlock
