import React from "react"
import { Typography, Grid } from "@material-ui/core"
import {
	ArrowForwardIos as RArrowIcon,
	PlayCircleOutline as ActuationIcon,
	AccessTime as ClockIcon
} from "@material-ui/icons"

import { IntegrationType } from "@/protocols/integration"

import { Portlet, Divider } from "@/components"

import useStyles from "@/pages/Admin/Integration/IntegrationListItem/styles"

import { integrationInfo } from "@/utils/integration"

import { Link } from "react-router-dom"
import HardCoded from "@/services/HardCoded"
import { fullDatetime } from "@/utils/time"
import { isSmallScreen } from "@/utils/checkDevice"

type IntegrationListItemProps = {
	title: string
	type: IntegrationType
	integrationId: number
	lastIntegrationWebhookProcessedDate?: Date
	lastIntegrationWebhookReceivedDate?: Date
}

const IntegrationListItem: React.FC<IntegrationListItemProps> = (props) => {
	const {
		title,
		type,
		integrationId,
		lastIntegrationWebhookProcessedDate,
		lastIntegrationWebhookReceivedDate
	} = props

	const classes = useStyles()

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
				style={{
					padding: "8px"
				}}
			>
				<Grid item>
					{integrationInfo[type].icon}
				</Grid>

				<Grid item xs>
					<Link to={`/admin/integration/${integrationId}`} style={{ textDecoration: "none" }}>
						<Typography
							variant="body1"
							className={classes.listItemDescription}
						>
							{title}
						</Typography>
					</Link>

					<Divider orientation="horizontal" size={0.5} />
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{integrationInfo[type]?.title}
					</Typography>
				</Grid>

				<Grid
					item
					xs={5}
				>
					{(HardCoded.checkFeatureFlag("lastUseDateIntegration") && !isSmallScreen) && (
						<Grid
							container
							alignItems="center"
							justifyContent="flex-start"
						>
							<Grid
								item
							>
								<Grid
									container
									alignItems="center"
								>
									<ActuationIcon
										className={classes.clockIcon}
									/>
								</Grid>
							</Grid>

							<Grid
								item
								xs
							>
								<Typography
									variant="body1"
									className={classes.listItemData}
								>
									Último webhook processado: {lastIntegrationWebhookProcessedDate ? fullDatetime(new Date(lastIntegrationWebhookProcessedDate)) : "Ainda não houve"}
								</Typography>
							</Grid>
						</Grid>
					)}
					<Grid
						container
						alignItems="center"
						justifyContent="flex-start"
					>
						<Grid
							item
						>
							<Grid
								container
								alignItems="center"
							>
								<ClockIcon className={classes.clockIcon} />
							</Grid>
						</Grid>

						<Grid
							item
							xs
						>
							<Typography
								variant="body1"
								className={classes.listItemData}
							>
							Último webhook recebido: {lastIntegrationWebhookReceivedDate ? fullDatetime(new Date(lastIntegrationWebhookReceivedDate)) : "Ainda não houve"}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item>
							<Link to={`/admin/integration/${integrationId}`} style={{ textDecoration: "none" }}>
								<Grid container className={classes.listItemIconButton} justifyContent="center" alignItems="center">
									<RArrowIcon className={classes.listItemIcon} />
								</Grid>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default IntegrationListItem
