import { Edge, EdgeTypes, Node, NodeTypes, Position } from "reactflow"
import dagre from "dagre"

import { ConstructionResources, FlowBlock, ShortChatBotFlowBlock, ShortFlow } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockRule, ChatBotFlowBlockType, ListOfBlockedBLocks } from "@/protocols/chatBot"

import DefaultEdge from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Edges/DefaultEdge"
import DefaultNode from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/DefaultNode"
import TriggerNode from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode"
import EmptyNode from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/EmptyNode"

import { blockComponentConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockComponent"
import { flowResourcesConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

import { generateIntegerID, generateUUID } from "@/utils/id"

import { buildDefaultEdge } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowEdge"
import { buildBlockNode, buildTriggerNode } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowNode"
import { DeepPartial } from "@/protocols/utility"

export const getFlowResources = () => {
	const edgeTypes: EdgeTypes = {
		[flowResourcesConfig.defaultName.edge]: DefaultEdge
	}

	const nodeTypes: NodeTypes = {
		[flowResourcesConfig.defaultName.node]: DefaultNode,
		[flowResourcesConfig.defaultName.trigger]: TriggerNode,
		[flowResourcesConfig.defaultName.emptyNode]: EmptyNode
	}

	Object.values(blockComponentConfig).forEach(config => {
		nodeTypes[config.nodeName] = config.Component.Block
	})

	return {
		edgeTypes,
		nodeTypes
	}
}

export const buildNewChatBotFlowBlock = (flowBlock: FlowBlock): ShortChatBotFlowBlock => {
	const allConditionRules = flowBlock.default_next_block_flow_rules.map(rule => ({
		...rule,
		id: generateUUID(),
		next_chat_bot_flow_block_id: null
	}))

	const chatBotFlowBlock: ShortChatBotFlowBlock = {
		id: generateIntegerID(),
		content: flowBlock.default_content,
		position: { x: 0, y: 0 },
		next_chat_bot_flow_block_rules: allConditionRules,
		chat_bot_block_id: flowBlock.id,
		chat_bot_block_type: flowBlock.type
	}

	return chatBotFlowBlock
}

export const parseFlowData = (flowData: ShortFlow, constructionResources: ConstructionResources) => {
	const initialNodes: Node[] = []
	const initialEdges: Edge[] = []

	const triggerNode = buildTriggerNode(flowData)
	initialNodes.push(triggerNode)

	if (flowData.initial_chat_bot_flow_block_id) {
		const triggerEdge = buildDefaultEdge({
			source: triggerNode.id,
			target: String(flowData.initial_chat_bot_flow_block_id),
			sourceHandle: triggerNode.id,
			targetHandle: String(flowData.initial_chat_bot_flow_block_id)
		})

		initialEdges.push(triggerEdge)
	}

	flowData.chat_bot_flow_blocks.forEach(chatBotFlowBlock => {
		const flowBlock = constructionResources.blocks.find(({ type }) => type === chatBotFlowBlock.chat_bot_block_type) as FlowBlock
		const nextChatBotFlowBlockRules = chatBotFlowBlock.next_chat_bot_flow_block_rules
		const nodeId = String(chatBotFlowBlock.id)

		const node = buildBlockNode({
			flowBlock,
			chatBotFlowBlock,
			inboxChannelType: flowData.inbox_channel_type
		})

		initialNodes.push(node)

		nextChatBotFlowBlockRules.forEach((nextChatBotFlowBlockRule) => {
			const nextChatBotFlowBlockId = nextChatBotFlowBlockRule.next_chat_bot_flow_block_id
			const nextNodeId = String(nextChatBotFlowBlockId)
			const fromHandleId = nextChatBotFlowBlockRule.id

			if (nextChatBotFlowBlockId) {
				const edge = buildDefaultEdge({
					source: nodeId,
					target: nextNodeId,
					sourceHandle: fromHandleId,
					targetHandle: nextNodeId
				})

				initialEdges.push(edge)
			}
		})
	})

	return {
		initialNodes,
		initialEdges
	}
}

export const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "LR") => {
	const dagreGraph = new dagre.graphlib.Graph()
	dagreGraph.setDefaultEdgeLabel(() => ({}))

	const isHorizontal = direction === "LR"
	dagreGraph.setGraph({ rankdir: direction })

	nodes.forEach((node) => {
		dagreGraph.setNode(node.id, { width: Number(node.width), height: Number(node.height) })
	})

	edges.forEach((edge) => {
		dagreGraph.setEdge(edge.source, edge.target)
	})

	dagre.layout(dagreGraph)

	nodes.forEach((node) => {
		const nodeWithPosition = dagreGraph.node(node.id)

		node.targetPosition = isHorizontal ? Position.Left : Position.Top
		node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

		node.position = {
			x: nodeWithPosition.x - Number(node.width) / 2,
			y: nodeWithPosition.y - Number(node.height) / 2
		}
	})

	return {
		nodes,
		edges
	}
}

export const getValidBlocks = (chatBotFlowBlocks: ShortChatBotFlowBlock[]) => {
	return chatBotFlowBlocks.filter(block => block.status !== "DELETED")
}

export const isBlockConnectedFromOutside = (chatBotFlowBlockId: number, flowData: ShortFlow) => {
	const validBlocks = getValidBlocks(flowData?.chat_bot_flow_blocks)

	const allNextBlockRules = validBlocks.reduce((rules: ChatBotFlowBlockRule[], block) => [...rules, ...block.next_chat_bot_flow_block_rules], [])

	const connectedFromOutsideChatBotFlowBlockIds = allNextBlockRules.map(rule => rule.next_chat_bot_flow_block_id)

	const isConnectedFromOutside = [
		...connectedFromOutsideChatBotFlowBlockIds,
		flowData.initial_chat_bot_flow_block_id
	].includes(chatBotFlowBlockId)

	return isConnectedFromOutside
}

export const isBlockedBlock = (chatBotFlowBlockType: ChatBotFlowBlockType): boolean => {
	const listOfBlockedBlocks: DeepPartial<ListOfBlockedBLocks> = {
		"save-input": true,
		"validate-and-save": true
	}

	return listOfBlockedBlocks[chatBotFlowBlockType] ?? false
}

export const MIN_TIME_IN_MINUTES_TO_WAIT_FLOW = 1
export const MAX_TIME_IN_MINUTES_TO_WAIT_IN_WABA_FLOW = 30
