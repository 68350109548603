import { IMessageExtraData } from "@/protocols/channel"
import React from "react"
import { Grid, IconButton, Typography } from "@material-ui/core"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import {
	WABATemplateHeaderBody
} from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import { Divider, FancyBox } from "@/components"
import Image from "@/components/Messages/MediaMessage/Image"
import clsx from "clsx"
import VideoPlayer from "@/components/Messages/MediaMessage/VideoPlayer"
import MediaUploadProgressStatusBar from "@/components/MediaUploadProgressStatusBar"
import { MESSAGE_MEDIA_MAX_WIDTH } from "@/components/Messages/MediaMessage/styles"
import { Description as FileIcon, GetApp as DownloadIcon } from "@material-ui/icons"
import useStyles from "@/@integrations/WABA/components/Chat/WABAMessages/WABAMessageHeader/styles"
import useCustomStyles from "@/styles/custom"

type WABAMessageHeaderProps = {
	header: IMessageExtraData["header"]
	translateVariables?: boolean
	uploadingMedia?: boolean
	fancyBoxSlug?: string
	mediaUploadProgress?: number
	messageErrorCode?: string
	fileName?: string
}

const WABAMessageHeader: React.FC<WABAMessageHeaderProps> = (props) => {
	const {
		header,
		translateVariables,
		uploadingMedia,
		fancyBoxSlug,
		mediaUploadProgress,
		messageErrorCode
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	if (header?.type === "text") {
		const headerContent = header.content as WABATemplateHeaderBody<"text">

		return (
			<>
				<Typography
					variant="overline"
					color="textPrimary"
					component="span"
				>
					<strong>{translateWhatsAppSyntaxToHTML(headerContent.text, translateVariables)}</strong>
				</Typography>

				<br />
			</>
		)
	}

	if (header?.type === "picture") {
		const headerContent = header.content as WABATemplateHeaderBody<"picture">

		return (
			<Grid
				container
				direction="column"
				className={classes.pictureVideoContainer}
			>
				<FancyBox
					options={{
						slug: fancyBoxSlug,
						enableHashChange: true,
						backFocus: false
					}}
					caption={""}
					src={headerContent.media.url}
					id={String(Date.now())}
				>
					<Image
						src={headerContent.media.url}
						classes={{
							afterLoad: clsx({
								[classes.messageImage]: true,
								[classes.loadingMessageImage]: uploadingMedia
							}),
							beforeLoad: classes.unloadedMessageImage
						}}
						alt="mensagem"
					/>
				</FancyBox>
				<MediaUploadProgressStatusBar
					mediaUploadProgress={Number(mediaUploadProgress)}
					uploadingMedia={Boolean(uploadingMedia)}
					messageErrorCode={String(messageErrorCode)}
				/>

				<Typography
					variant="overline"
					className={classes.messageCaption}
					color="textPrimary"
					style={{
						maxWidth: MESSAGE_MEDIA_MAX_WIDTH
					}}
				>
					{""}
				</Typography>
			</Grid>
		)
	}

	if (header?.type === "video") {
		const headerContent = header.content as WABATemplateHeaderBody<"video">

		return (
			<Grid
				container
				direction="column"
				className={classes.pictureVideoContainer}
			>
				<FancyBox
					options={{
						slug: fancyBoxSlug,
						enableHashChange: true,
						backFocus: false
					}}
					caption={""}
					src={headerContent.media.url}
					id={String(Date.now())}
				>
					<VideoPlayer
						src={headerContent.media.url}
						classes={{
							afterLoad: clsx({
								[classes.messageVideo]: true,
								[classes.loadingMessageVideo]: uploadingMedia
							}),
							beforeLoad: classes.unloadedMessageVideo
						}}
					/>
				</FancyBox>
				<MediaUploadProgressStatusBar
					mediaUploadProgress={Number(mediaUploadProgress)}
					uploadingMedia={Boolean(uploadingMedia)}
					messageErrorCode={String(messageErrorCode)}
				/>

				<Typography
					variant="overline"
					className={classes.messageCaption}
					color="textPrimary"
					style={{
						maxWidth: MESSAGE_MEDIA_MAX_WIDTH
					}}
				>
					{""}
				</Typography>
			</Grid>
		)
	}

	if (header?.type === "file") {
		const headerContent = header.content as WABATemplateHeaderBody<"file">

		return (
			<Grid
				container
				direction="column"
			>
				<Grid
					container
					alignItems="center"
					justify="space-between"
					className={classes.fileContainer}
				>
					<Grid
						container
						alignItems="center"
						className={classes.fileLeftSideContainer}
					>
						<FileIcon />

						<Divider orientation="vertical" size={1} />

						<Typography
							color="textPrimary"
							className={`${classes.fileName} ${customClasses.inlineText}`}
						>
							{headerContent.media.name}
						</Typography>
					</Grid>

					<IconButton
						component="a"
						download={headerContent.media.name}
						target="_blank"
						href={headerContent.media.url}
					>
						<DownloadIcon />
					</IconButton>
				</Grid>

				<Typography
					variant="overline"
					className={classes.messageCaption}
					color="textPrimary"
					style={{
						maxWidth: MESSAGE_MEDIA_MAX_WIDTH
					}}
				>
					{""}
				</Typography>

				<MediaUploadProgressStatusBar
					mediaUploadProgress={Number(mediaUploadProgress)}
					uploadingMedia={Boolean(uploadingMedia)}
					messageErrorCode={String(messageErrorCode)}
				/>
			</Grid>
		)
	}

	return null
}

export default WABAMessageHeader
