/* eslint-disable */
import { useEffect, useState } from "react"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { InboxLimits } from "@/protocols/instance"

import UpsellDialog from "@/components/UpsellDialog"
import { useGlobalStateStore } from "@/store/GlobalState"

export type BlockCode = "user-limit-reached" |
	"chatbot-limit-reached" |
	"message-limit-reached" |
	"group-permission-denied" |
	"chatbot-permission-denied" |
	"integration-permission-denied" |
	"message-blast-permission-denied" |
	"inbox-message-group-permission-denied" |
	"inbox-bulk-action-denied" |
	"active-campaign-chat-plugin-denied" |
	"ai-text-enhancement-limit-reached-attendant" |
	"ai-text-enhancement-limit-reached-admin"

export type LimitValidation = {
	mustBlock: boolean
	blockCode: BlockCode
	extraData: Record<string, unknown>
}

type QuantityLimitsBlockCodes = Record<keyof InboxLimits["quantity"], {
	blockCode: BlockCode
	onErrorShouldBlock: boolean
}>
type PermissionLimitsBlockCodes = Record<keyof InboxLimits["permission"], {
	blockCode: BlockCode
	onErrorShouldBlock: boolean
}>

type SubscriptionLimitsOptions = {
	modalLimitsOptions?: {
		openModalWhenHasNoPermission?: boolean;
		onSaveReturnToLastPage?: boolean;
	}
}

const useSubscriptionLimits = <Type extends keyof InboxLimits>(type: Type, context: keyof InboxLimits[Type], options?: SubscriptionLimitsOptions) => {
	const patternOnErrorShouldBlock = false
	const genericPermissionRoute = "/subscription/permission"

	const globalStateStore = useGlobalStateStore()
	const isUserAdmin = globalStateStore.instance.user_in_instance_role.name === "Gestor"

	const [limitValidation, setLimitValidation] = useState<LimitValidation>({
		mustBlock: false,
		blockCode: "" as BlockCode,
		extraData: {}
	})

	const quantityLimitsBlockCodes: QuantityLimitsBlockCodes = {
		user: {
			blockCode: "user-limit-reached",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		chatbot: {
			blockCode: "chatbot-limit-reached",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		active_chatbot: {
			blockCode: "chatbot-limit-reached",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		message: {
			blockCode: "message-limit-reached",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		ai_text_enhancement: {
			blockCode: isUserAdmin ? "ai-text-enhancement-limit-reached-admin" : "ai-text-enhancement-limit-reached-attendant",
			onErrorShouldBlock: patternOnErrorShouldBlock
		}
	}

	const permissionLimitsBlockCodes: PermissionLimitsBlockCodes = {
		group: {
			blockCode: "group-permission-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		chatbot: {
			blockCode: "chatbot-permission-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		integration: {
			blockCode: "integration-permission-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		messageBlast: {
			blockCode: "message-blast-permission-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		inbox_message_group: {
			blockCode: "inbox-message-group-permission-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		bulk_contact_action: {
			blockCode: "inbox-bulk-action-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		},
		active_campaign_chat_plugin: {
			blockCode: "active-campaign-chat-plugin-denied",
			onErrorShouldBlock: patternOnErrorShouldBlock
		}
	}

	const blockCodesWithNewBlockScreenEnabled: BlockCode[] = [
		"message-blast-permission-denied",
		"group-permission-denied",
		"inbox-message-group-permission-denied"
	]

	const checkPermissionLimit = async (context: keyof InboxLimits["permission"]): Promise<LimitValidation> => {
		const limitCheckerInfo = permissionLimitsBlockCodes[context as keyof InboxLimits["permission"]]
		try {
			const permission = await ApiService.get(genericPermissionRoute, {
				params: {
					type: "permission",
					context
				}
			})

			return {
				mustBlock: !(permission?.data?.hasPermission),
				blockCode: limitCheckerInfo.blockCode,
				extraData: permission?.data?.extraData
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return {
				mustBlock: limitCheckerInfo.onErrorShouldBlock,
				blockCode: limitCheckerInfo.blockCode,
				extraData: {}
			}
		}
	}

	const checkQuantityLimit = async (context: keyof InboxLimits["quantity"]): Promise<LimitValidation> => {
		const limitCheckerInfo = quantityLimitsBlockCodes[context as keyof InboxLimits["quantity"]]

		try {
			const permission = await ApiService.get(genericPermissionRoute, {
				params: {
					type: "quantity",
					context
				}
			})

			return {
				mustBlock: !(permission?.data?.hasPermission),
				blockCode: limitCheckerInfo.blockCode,
				extraData: permission?.data?.extraData
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return {
				mustBlock: limitCheckerInfo.onErrorShouldBlock,
				blockCode: limitCheckerInfo.blockCode,
				extraData: {}
			}
		}
	}
	const setLimit = () => {
		// Semicolon needed due JS' error with declaration followed by auto called function
		let limit = limitValidation;
		(async () => {
			if (type === "permission") {
				limit = await checkPermissionLimit(context as keyof InboxLimits["permission"])
			}

			if (type === "quantity") {
				limit = await checkQuantityLimit(context as keyof InboxLimits["quantity"])
			}

			setLimitValidation(limit)
		})()
	}

	const handleOpenModal = () => {
		if (limitValidation.mustBlock && options?.modalLimitsOptions?.openModalWhenHasNoPermission) {
			if (blockCodesWithNewBlockScreenEnabled.includes(limitValidation.blockCode)) {
				UpsellDialog.open({
					dialogCode: limitValidation.blockCode,
					onClose: () => {
						if (options?.modalLimitsOptions?.onSaveReturnToLastPage) {
							window.history.back()
						}
					}
				})
			} else {
				UpsellDialog.open({
					dialogCode: limitValidation.blockCode,
					onClose: () => {
						if (options?.modalLimitsOptions?.onSaveReturnToLastPage) {
							window.history.back()
						}
					}
				})
			}
		}
	}

	useEffect(() => {
		handleOpenModal()
		// eslint-disable-next-line
	}, [limitValidation.mustBlock])

	const resetLimit = () => {
		setLimit()
	}

	useDidMount(() => {
		resetLimit()
	})

	return {
		...limitValidation,
		resetLimit
	}
}

export default useSubscriptionLimits
